import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import './styles.scss';

import {
  tabs,
  solicitacaoTabs,
  passos,
  httpStatusCode,
} from '../ContentPedido/_constants';

import api from '../../../api';

import { Context } from '../../../Context/AuthContext';

import SubHeader from '../SubHeader';

import Entities from '../ContentPedido/entities';
import Solicitacao from '../ContentPedido/Solicitacoes/Solicitacao';
import Entrega from './Entrega';
import ResumoMaterial from '../ContentPedido/Resumo/ResumoMaterial';

const ContentAlterarSolicitacao = (props) => {
  const params = useParams();
  const history = useHistory();
  const { showLoader } = useContext(Context);

  const [initialLoader, setInitialLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [entities, setEntities] = useState({});

  const [model, setModel] = useState({});

  const [pedido, setPedido] = useState({});

  const [solicitacao, setSolicitacao] = useState({});

  const [solicitacaoAtualizada, setSolicitacaoAtualizada] = useState({});

  const [activeTab, setActiveTab] = useState(params.tab || tabs.tabEntrega);

  const [rascunho, setRascunho] = useState({});

  let urlSolicitacao = `/alterar-solicitacao/${solicitacao.solicitacaoId || 0}`;

  let material = []
    .concat(
      ...(entities.tipoMateriais || []).map(
        (tipoMaterial) => tipoMaterial.materiaisComunicacao,
      ),
    )
    .find((m) => m.materialComunicacaoId === solicitacao.materialComunicacaoId);

  let errors = (solicitacao.errors || []).filter(
    (error) => error.materialComunicacaoId === 0,
  );

  const updateSolicitacao = () => {
    setSolicitacao({ ...solicitacao });
  };

  const updateRascunho = () => {
    // (async () => {
    //   await api.post('/Rascunho/update-rascunho-2', model);
    // })();
  };

  const validatePedido = () => {
    // (async () => {
    //   showLoader(true);
    //   const result = await api.post('/Pedido/validate', model);
    //   if (result?.data?.value?.length) {
    //     var errors = result.data.value;
    //     setErrors(errors);
    //     redirectToError();
    //   }
    //   showLoader(false);
    // })();
  };

  const finalizar = () => {
    (async () => {
      showLoader(true);

      const response = await api.post(
        '/Solicitacao/update-solicitacao-reprovada-web',
        solicitacao,
      );
      if (response?.data?.code) {
        let code = response.data.code;

        //solicitacao atualizada
        if (code === httpStatusCode.ok) {
          setSolicitacaoAtualizada(response.data.value.solicitacao);
          changeTab(tabs.tabSucesso);
        }

        //solicitacao com erros
        else if (code === httpStatusCode.badRequest) {
          let result = response.data.value;
          if (result.errors && result.errors.length) {
            var errors = result.errors;

            setErrors(errors);

            redirectToError();
          }
        }
        //erro interno
        else {
        }
      }
      showLoader(false);
    })();
  };

  const setErrors = (errors) => {
    solicitacao.errors = errors;

    updateSolicitacao();
  };

  const redirectToError = () => {
    if (
      solicitacao.errors.findIndex(
        (error) => error.passo === passos.passoEntrega.nro,
      ) !== -1
    ) {
      history.push(`${urlSolicitacao}/${tabs.tabEntrega}`);
      return;
    }

    if ((solicitacao.errors || []).length) {
      if (
        solicitacao.errors.findIndex(
          (error) => error.passo === passos.passoLayout.nro,
        ) !== -1
      ) {
        history.push(`${urlSolicitacao}/${solicitacaoTabs.tabLayout}`);
        return;
      }

      if (
        solicitacao.errors.findIndex(
          (error) => error.passo === passos.passoProdutos.nro,
        ) !== -1
      ) {
        history.push(`${urlSolicitacao}/${solicitacaoTabs.tabProdutos}`);
        return;
      }

      if (
        solicitacao.errors.findIndex(
          (error) => error.passo === passos.passoDados.nro,
        ) !== -1
      ) {
        history.push(`${urlSolicitacao}/${solicitacaoTabs.tabDados}`);
        return;
      }
    }
  };

  const renderErrors = (errors) => {
    if (!errors || !errors.length) return null;

    return (
      <div className="alert alert-danger">
        {errors.map((error, index) => {
          return (
            <p key={index} className="m-0">
              {error.error}
            </p>
          );
        })}
      </div>
    );
  };

  const changeTab = (tab) => {
    //updateSolicitacao();
    history.push(`${urlSolicitacao}/${tab}`);
  };

  //redireciona para a tab de acordo com a mudança na url
  useEffect(() => {
    return history.listen((location) => {
      let tab = location.pathname.split('/')[3] || tabs.tabEntrega;
      setActiveTab(tab);
    });
  }, [history]);

  // loader
  useEffect(() => {
    if (initialLoader || loader) showLoader(true);
    else showLoader(false);
  }, [initialLoader, loader]);

  //busca a solicitacao
  useEffect(() => {
    //redirecionar para home
    if (!params.solicitacaoId) {
      history.push('/');
      return;
    }

    (async () => {
      setInitialLoader(true);

      Promise.all([getSolicitacao(), getPedido()]).then(() => {
        setInitialLoader(false);
      });
    })();
  }, []);

  async function getSolicitacao() {
    const result = await api.get('/Solicitacao/get-solicitacao', {
      params: { solicitacaoId: params.solicitacaoId },
    });

    if (result?.data?.value) {
      solicitacao.solicitacaoId = params.solicitacaoId;
      setSolicitacao(result.data.value);
    }
  }

  async function getPedido() {
    const result = await api.get('/Pedido/get-pedido-by-solicitacao', {
      params: { solicitacaoId: params.solicitacaoId },
    });

    if (result?.data?.value) {
      setPedido(result.data.value);
    }
  }

  const renderTab = () => {
    if (!solicitacao.solicitacaoId) return null;

    if (activeTab === tabs.tabResumo) return renderResumo();

    if (activeTab === tabs.tabSucesso) return renderSucesso();

    return renderSolicitacao();
  };

  const renderSolicitacao = () => {
    return (
      <div className="row">
        <div className="col-lg-3 mb-3">{renderSolicitacaoSideBar()}</div>

        <div className="col-lg-9">
          <div className="solicitacao-panel">
            <div className="solicitacao-panel-header">
              SOLICITAÇÃO {solicitacao.solicitacaoId} - {material?.nome}
            </div>

            <div className="solicitacao-panel-body">
              {renderSolicitacaoTab()}

              {/* <fieldset>
                <div className="form-card">
                    <h2 className="fs-title text-center">Success !</h2> <br /><br />
                    <div className="row justify-content-center">
                        <div className="col-lg-3"> <img src="https://img.icons8.com/color/96/000000/ok--v2.png" className="fit-image"/> </div>
                    </div> <br/><br/>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 text-center">
                            <h5>You Have Successfully Signed Up</h5>
                        </div>
                    </div>
                </div>
            </fieldset> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSolicitacaoSideBar = () => {
    if (!solicitacao.solicitacaoId) {
      return null;
    }

    return (
      <div className="solicitacao-panel">
        <div className="solicitacao-panel-header">ENTREGA</div>

        <div className="solicitacao-panel-body">
          <button
            className={`btn btn-material ${
              activeTab === tabs.tabEntrega ? 'bg-primary' : 'bg-secondary'
            }`}
            onClick={() => {
              changeTab(tabs.tabEntrega);
            }}
          >
            ENTREGA
          </button>

          <button
            className={`btn btn-material ${
              activeTab !== tabs.tabEntrega ? 'bg-primary' : 'bg-secondary'
            }`}
            onClick={() => {
              changeTab(solicitacaoTabs.tabLayout);
            }}
          >
            DADOS
          </button>
        </div>
      </div>
    );
  };

  const renderSolicitacaoTab = () => {
    if (!solicitacao.solicitacaoId) return null;

    if (activeTab === tabs.tabEntrega)
      return (
        <Entrega
          solicitacao={solicitacao}
          loja={pedido.loja}
          entities={entities}
          urlSolicitacao={urlSolicitacao}
          renderErrors={renderErrors}
          changeTab={changeTab}
        />
      );

    return (
      <Solicitacao
        solicitacao={solicitacao}
        pedidoId={pedido.pedidoId}
        loja={pedido.loja}
        entities={entities}
        entregaCentroDistribuicao={pedido.loja?.enderecoFilial}
        entregaLojasParticipantes={solicitacao.entregaLojasParticipantes}
        entregaOutro={solicitacao.entregaOutro}
        proximoMaterial={() => changeTab(tabs.tabResumo)}
        renderErrors={renderErrors}
        solicitacaoIndex={0}
        urlSolicitacao={urlSolicitacao}
        locationIndexSolicitacaoTab={3}
        updateRascunho={updateRascunho}
      />
    );
  };

  const renderResumo = () => {
    return (
      <div className="wrapper-content-pedido-resumo">
        <div className="content-pedido-resumo">
          <div className="row">
            <div className="col-lg-12">
              <Accordion defaultActiveKey="0">
                <ResumoMaterial
                  pedido={pedido}
                  solicitacao={solicitacao}
                  loja={pedido.loja}
                  entregaDigital={solicitacao.entregaDigital}
                  entregaCentroDistribuicao={
                    solicitacao.entregaCentroDistribuicao
                  }
                  entregaLojasParticipantes={
                    solicitacao.entregaLojasParticipantes
                  }
                  entregaOutro={solicitacao.entregaOutro}
                  entities={entities}
                  index={0}
                />
              </Accordion>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 text-left">
              <button
                className="btn btn-primary btn-navigation"
                onClick={() => changeTab(tabs.tabEntrega)}
              >
                <span>
                  <FaAngleLeft size={26} />
                </span>
                <span>VOLTAR</span>
              </button>
            </div>

            <div className="col-lg-6 d-flex flex-row-reverse">
              <button
                className="btn btn-primary btn-navigation btn-navigation-right"
                onClick={() => finalizar()}
              >
                <span>FINALIZAR</span>
                <span>
                  <FaAngleRight size={26} />
                </span>
              </button>
            </div>

            {/* <pre className="text-left">{JSON.stringify(tipoMateriais, null, 2)}</pre> */}
          </div>
        </div>
      </div>
    );
  };

  const renderSucesso = () => {
    return (
      <div className="wrapper-content-pedido-resumo">
        <div className="content-pedido-resumo">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="text-center mb-5">
                A solicitação foi alterada com sucesso.
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <Accordion>
                <ResumoMaterial
                  solicitacao={solicitacao}
                  loja={pedido.loja}
                  entregaDigital={solicitacao.entregaDigital}
                  entregaCentroDistribuicao={
                    solicitacao.entregaCentroDistribuicao
                  }
                  entregaLojasParticipantes={
                    solicitacao.entregaLojasParticipantes
                  }
                  entregaOutro={solicitacao.entregaOutro}
                  entities={entities}
                  index={0}
                />
              </Accordion>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 d-flex flex-row-reverse">
              {/* <button className="btn btn-primary btn-navigation btn-navigation-right" onClick={() => { }}>
              <span>Pagina Inicial</span>
            </button> */}

              <Link to="/">
                <button className="btn btn-primary btn-navigation btn-navigation-right">
                  <span>Pagina Inicial</span>
                </button>
              </Link>
            </div>

            {/* <pre className="text-left">{JSON.stringify(tipoMateriais, null, 2)}</pre> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Entities
        setEntities={setEntities}
        pedido={pedido}
        solicitacao={solicitacao}
        loader={loader}
        setLoader={setLoader}
      />

      <div className="wrapper-content-pedido mb-5">
        <SubHeader title={pedido.loja?.razaoSocial} />

        <div className="wrapper-content-alterar-solicitacao p-3">
          {renderErrors(errors)}
          {renderTab()}
        </div>
      </div>
    </>
  );
};

export default ContentAlterarSolicitacao;
