import React, { useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import './styles.scss';
import TitlePages from '../../../../atoms/TitlePages';
import SubHeader from '../../../SubHeader';
import { tabs, entregaTabs, entregaTipoNomes } from '../../_constants';

import LojaLoja from './LojaLoja';
import CentroDistribuicao from './CentroDistribuicao';
import Outro from './Outro';

const EntregaFisica = (props) => {
  let model = props.model;
  let entities = props.entities;
  let estados = props.estados;
  let setModel = props.setModel;
  let errors = props.errors;
  let renderErrors = props.renderErrors;
  let avancarEntregaDigital = props.avancarEntregaDigital;
  let avancarSolicitacoes = props.avancarSolicitacoes;
  let voltar = props.voltar;
  
  model.solicitacoes = model.solicitacoes || [];
  const [solicitacoes, setSolicitacoes] = useState(model.solicitacoes);
  const [entregaFisicaId, setEntregaFisicaId] = useState(model.entregaFisicaId || 0);

  let tipoEntregas = entities.tipoEntregas;
  
  let tipoMateriais = entities.tipoMateriais || [];
  let materiais = [].concat(...tipoMateriais.map((tipoMaterial) => tipoMaterial.materiaisComunicacao));

  useEffect(() => {
    setEntregaFisicaId(model.entregaFisicaId || 0);
    setSolicitacoes(model.solicitacoes || [])
  }, [model]);

  let solicitacoesComProducao =
    (model.solicitacoes || []).filter((solicitacao) => {
      solicitacao.materialComunicacao = materiais.find((material) => material.materialComunicacaoId === solicitacao.materialComunicacaoId) || {};
      return solicitacao.materialComunicacao.possuiProducao
    });

  const entregaTipoDigital =
    (tipoEntregas || []).find((tipoEntrega) => {
      return tipoEntrega.entregaDigital;
    });

  const avancarComEntregaFisica = () => {
    model.solicitacoes.forEach((solicitacao) => {
      solicitacao.entregaTipoId = !solicitacao.imprimir
        ? entregaTipoDigital.entregaTipoId
        : model.entregaFisicaId
    })

    setSolicitacoes([...model.solicitacoes]);

    if (model.solicitacoes.findIndex((solicitacao) => !solicitacao.imprimir) === -1) {
      avancarSolicitacoes();
    } else {
      avancarEntregaDigital();
    }
  }

  const avancarSemEntregaFisica = () => {
    model.solicitacoes.forEach((solicitacao) => {
      solicitacao.entregaTipoId = entregaTipoDigital.entregaTipoId;
    })

    setSolicitacoes([...model.solicitacoes]);

    avancarEntregaDigital();
  }

  const renderMateriais = () => {
    if (!tipoEntregas.length)
      return null;

    return (
      <div className=" row mb-5">
      <div className='texto'>   
        <span> Você selecionou materiais que podem ser impressos pela Multimaxi e entregues 
          no endereço do cliente. Escolha abaixo quais materiais você quer que sejam impressos:
        </span> 
      </div>
        {
          solicitacoesComProducao.map((solicitacao, index) => {
            return (
              <div key={index} className=" mb-3">
                <button type="button" className={`btn ${(solicitacao.imprimir ? "btn-primary" : "btn-secondary")}`}
                  onClick={() => {
                    solicitacao.imprimir = !solicitacao.imprimir;
                    //setModel({ ...model });
                    setSolicitacoes([...model.solicitacoes]);
                  }}
                >
                  {solicitacao.materialComunicacao.nome}
                </button>
              </div>
            )
          })
        }
      </div>
    )
  };

  const renderButtons = () => {
    if (!tipoEntregas.length)
      return null;

    if (!model.solicitacoes.length)
      return null;

    //nenhum selecionado
    if (model.solicitacoes.findIndex((solicitacao) => solicitacao.imprimir) === -1)
      return (
        <div className="row mb-5">
          <div className="col-lg-12 text-center">
            <button className="btn btn-danger" onClick={() => avancarSemEntregaFisica()}>NÃO QUERO IMPRIMIR NENHUM MATERIAL</button>
          </div>
        </div>
      )

    let tipoEntregasFisica = tipoEntregas.filter((entregaTipo) => entregaTipo.entregaFisica);
    if (!tipoEntregasFisica.length)
      return null;

    return (
      <div className=" forma-entrega row mb-5">
        <div className="col-lg-12 text-center mb-2">
          Escolha a forma de entrega dos materiais selecionados
        </div>
        {
          tipoEntregasFisica.map((entregaTipo, index) => {
            return (
              <div key={index} className="forma-entrega-opcao">
                <button className={`btn ${model.entregaFisicaId === entregaTipo.entregaTipoId ? "btn-primary" : "btn-secondary"}`}
                  onClick={() => {
                    model.entregaFisicaId = entregaTipo.entregaTipoId;
                    setEntregaFisicaId(model.entregaFisicaId);
                  }}
                >
                  {entregaTipo.descricao}
                </button>
              </div>
            )
          })
        }
      </div>
    )
  }

  const renderEntregaFisica = () => {
    if (model.solicitacoes.findIndex((solicitacao) => solicitacao.imprimir) === -1)
      return null;

    if (!entregaFisicaId || entregaFisicaId === 0)
      return null;

    let entregaTipoFisica =
      (tipoEntregas || []).find((tipoEntrega) => {
        return tipoEntrega.entregaFisica && tipoEntrega.entregaTipoId === model.entregaFisicaId;
      });

    if (!entregaTipoFisica)
      return null;

    if (entregaTipoFisica.nome === entregaTipoNomes.lojaLoja)
      return (<LojaLoja model={model} setModel={setModel} />)

    if (entregaTipoFisica.nome === entregaTipoNomes.centroDistribuicao)
      return (<CentroDistribuicao model={model} setModel={setModel} />)

    if (entregaTipoFisica.nome === entregaTipoNomes.outro)
      return (<Outro model={model} setModel={setModel} entities={entities} estados={estados} />)
  }

  const renderButtonAvancar = () => {
    if (model.solicitacoes.findIndex((solicitacao) => solicitacao.imprimir) === -1)
      return null;

    if (!model.entregaFisicaId)
      return null;

    if (!entregaFisicaId || entregaFisicaId === 0)
      return null;

    return (
      <div className="col-lg-6  flex-row-reverse">
        <button className="btn btn-primary btn-navigation btn-navigation-right" onClick={() => avancarComEntregaFisica()}>
          <span>AVANÇAR</span>
          <span><FaAngleRight size={26} /></span>
        </button>
      </div>
    )
  }

  return (
    <div className="wrapper-content-pedido-entrega-fisica">

      <SubHeader title={model.loja?.razaoSocial} />

      <TitlePages title="Entrega de Materiais Gráficos" />

      <div className="content-pedido-entrega-fisica">

        {renderErrors(errors)}

        {/* <input type="text" value={model.loja.razaoSocial} onChange={(event) => {
        model.loja.razaoSocial = event.target.value;
        setModel(model);
      }} /> */}

        {renderMateriais()}

        {renderButtons()}

        {renderEntregaFisica()}

        <div className=" row-buttons ">

          <div className="col-lg-6 text-left">
            <button className="btn btn-primary btn-navigation" onClick={() => voltar()} >
              <span><FaAngleLeft size={26} /></span>
              <span>VOLTAR</span>
            </button>
          </div>

          {renderButtonAvancar()}

          {/* <pre className="text-left">{JSON.stringify(tipoEntregas, null, 2)}</pre> */}

        </div>
      </div>
    </div>

  );
}

export default EntregaFisica;
