import React from 'react';

import { cadastroItemKeys } from '../../_constants';

const ButtonMaterial = (props) => {
  let solicitacao = props.solicitacao;
  let pedidoDeCampanha = props.pedidoDeCampanha;
  let entities = props.entities;
  let material = props.material;
  let active = props.active;
  let onClick = props.onClick;

  let solicitacaoDados = solicitacao.solicitacaoDados || {};
  let motivos = entities.motivos || [];
  //let clienteLayouts = entities.clienteLayouts || {};
  let regionalLayouts = entities.regionalLayouts || {};

  //filtrar cadastro itens dependendo da entrega digital ou fisica
  let cadastroItensTodos =
    entities.materiaisCadastroItens.find(
      (x) => x.materialComunicacaoId === solicitacao.materialComunicacaoId,
    )?.cadastroItens || [];
  let cadastroItens = (cadastroItensTodos || []).filter(
    (cadastroItem) =>
      (solicitacao.imprimir && cadastroItem.entregaFisica) ||
      (!solicitacao.imprimir && cadastroItem.entregaDigital),
  );

  solicitacao.isValid = () => {
    if (!pedidoDeCampanha) {
      if (!solicitacao.isValidLayout || !solicitacao.isValidLayout()) {
        return false;
      }
    }

    var cadastroItem = (cadastroItens || []).find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Produtos,
    );
    if (cadastroItem && cadastroItem.obrigatorio) {
      if (!solicitacao.isValidProdutos || !solicitacao.isValidProdutos())
        return false;
    }

    if (!solicitacao.isValidDados || !solicitacao.isValidDados()) {
      return false;
    }

    return true;
  };

  solicitacao.isValidLayout = () => {
    if ((solicitacaoDados.layoutArteId || 0) === 0) return false;

    return true;
  };

  solicitacao.isValidProdutos = () => {
    let layoutArte =
    //(clienteLayouts?.layoutArtes || []).find(
    (regionalLayouts?.layoutArtes || []).find(
        (layout) => layout.layoutArteId === solicitacaoDados.layoutArteId,
      ) || {};

    let minimoProdutos =
      layoutArte.minimoProdutos > 0
        ? layoutArte.minimoProdutos
        : material.minimoProdutos || 0;

    let maximoProdutos =
      layoutArte.maximoProdutos > 0
        ? layoutArte.maximoProdutos
        : material.maximoProdutos || 0;

    if ((solicitacao.solicitacaoProdutos || []).length === 0) return false;

    if (
      minimoProdutos > 0 &&
      solicitacao.solicitacaoProdutos.length < minimoProdutos
    )
      return false;

    if (
      maximoProdutos > 0 &&
      solicitacao.solicitacaoProdutos.length > maximoProdutos
    )
      return false;

    //preco
    if (material.precoObrigatorio) {
      for (var i = 0; i < solicitacao.solicitacaoProdutos.length; i++) {
        let produto = solicitacao.solicitacaoProdutos[i];
        if (
          !produto.preco ||
          !produto.preco.toString().length ||
          parseFloat(produto.preco) === 0
        )
          return false;
      }
    }

    return true;
  };

  solicitacao.isValidDados = () => {
    var cadastroItem;

    cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Veiculacao,
    );
    if (
      cadastroItem?.obrigatorio &&
      ((solicitacaoDados.dataVeiculacaoInicio?.toString() || '').length === 0 ||
        (solicitacaoDados.dataVeiculacaoFim?.toString() || '').length === 0)
    )
      return false;

    cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_DataEnvioArte,
    );
    if (
      cadastroItem?.obrigatorio &&
      (solicitacaoDados.dataEnvioArte?.toString() || '').length === 0
    )
      return false;

    cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Motivo,
    );
    if (cadastroItem?.obrigatorio) {
      if ((solicitacaoDados.motivoAcaoId || '').length === 0) return false;
      else {
        let motivoAcao = motivos.find(
          (item) => item.motivoAcaoId === solicitacaoDados.motivoAcaoId,
        );
        if (
          motivoAcao.habilitaDescricao &&
          (solicitacaoDados.outroMotivo || '').length === 0
        )
          return false;
      }
    }

    cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_NomeFantasia,
    );
    if (
      cadastroItem?.obrigatorio &&
      (solicitacaoDados.nomeFantasia || '').length === 0
    )
      return false;

    cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key ===
        cadastroItemKeys.Dados_QuantidadeLojasParticipantes,
    );
    if (
      cadastroItem?.obrigatorio &&
      (solicitacaoDados.quantidadeLojasParticipantes || 0) === 0
    )
      return false;

    cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_QuantidadeImpressosLoja,
    );
    if (
      cadastroItem?.obrigatorio &&
      (solicitacaoDados.quantidadeImpressosLoja || 0) === 0
    )
      return false;

    cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_Tamanho_Unico,
    );
    if (cadastroItem?.obrigatorio) {
      if ((solicitacao.solicitacaoTamanhos || []).length === 0) return false;
      else {
        let solicitacaoTamanho = solicitacao.solicitacaoTamanhos[0];
        if (
          (solicitacaoTamanho.largura || '').length === 0 ||
          (solicitacaoTamanho.altura || '').length === 0
        )
          return false;
      }
    }

    cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_Tamanhos_Multi,
    );
    if (cadastroItem?.obrigatorio) {
      if ((solicitacao.solicitacaoTamanhos || []).length === 0) return false;
      else {
        for (var i = 0; i < solicitacao.solicitacaoTamanhos.length; i++) {
          let solicitacaoTamanho = solicitacao.solicitacaoTamanhos[i];
          if (
            (solicitacaoTamanho.largura || '').length === 0 ||
            (solicitacaoTamanho.altura || '').length === 0
          )
            return false;
        }
      }
    }

    cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_ExtensaoArquivo,
    );
    if (
      cadastroItem?.obrigatorio &&
      (solicitacaoDados.extensaoArquivo || '').length === 0
    )
      return false;

    cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Tempo,
    );
    if (
      cadastroItem?.obrigatorio &&
      (solicitacaoDados.tempo || '').length === 0
    )
      return false;

    cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Site,
    );
    if (cadastroItem?.obrigatorio && (solicitacaoDados.site || '').length === 0)
      return false;

    cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Facebook,
    );
    if (
      cadastroItem?.obrigatorio &&
      (solicitacaoDados.facebook || '').length === 0
    )
      return false;

    cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Instagram,
    );
    if (
      cadastroItem?.obrigatorio &&
      (solicitacaoDados.instagram || '').length === 0
    )
      return false;

    cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_TelefoneContato,
    );
    if (
      cadastroItem?.obrigatorio &&
      (solicitacaoDados.telefoneContato || '').length === 0
    )
      return false;

    cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_TelefoneDelivery,
    );
    if (
      cadastroItem?.obrigatorio &&
      (solicitacaoDados.telefoneDelivery || '').length === 0
    )
      return false;

    //todo: validar rodape
    return true;
  };

  return (
    <button
      className={`btn btn-material btn-material-${
        solicitacao.isValid && solicitacao.isValid() ? 'success' : 'danger'
      } ${active ? 'bg-primary' : ''}`}
      onClick={onClick}
    >
      {material.nome}
    </button>
  );
};

export default ButtonMaterial;
