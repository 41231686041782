import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
//import { useParams, Link } from 'react-router-dom';
//import { useDispatch } from 'react-redux';
import { Button, Row, Col, Alert, Tabs, Tab, Sonnet, FormGroup } from 'react-bootstrap';
import {
  FaHome,
  FaAngleRight,
  FaSearch,
  FaPeopleCarry,
  FaUserCheck,
  FaFileUpload,
  FaChevronRight,
  FaChevronLeft,
  FaPlusCircle,
  FaCalendarCheck,
  FaFileDownload,
  FaTelegramPlane,
  FaImages
} from 'react-icons/fa';

import './styles.scss';
//import history from '../../../../Routes/history';
//import api from '../../../../api';
//import TitlePages from '../../../atoms/TitlePages';
//import LoaderAction from '../../../../store/modules/loader/actions';
//import InputFileCustom from "../../../atoms/InputFile";
//import { faCaretRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from '../../../../../../Context/AuthContext';
import SingleSelect from '../../../../../atoms/SelectSingle';

const FiltroExtratoBrasil = (props) => {

  const { showLoader } = useContext(Context);

  const [regionalList, setRegionalList] = useState([]);
  const [anosList, setAnosList] = useState([]);
  const [mesList, setMesList] = useState([]);
  const [dadosFiltro, setDadosFiltro] = useState({});

  const regionais = props.regionais;
  //setRegionalList(regionais);

  useEffect(() => {
    (async () => {
      showLoader(true);

      const anos = getYears();
      setAnosList(anos);

      const meses = getMonths();
      setMesList(meses);

      //const regionais = props.regionais;
      setRegionalList(regionais);

      props.onSelect(dadosFiltro);

      showLoader(false);
    })()
  }, [dadosFiltro]);

  useEffect(() => {
    (async () => {
      let dtHoje = new Date();
      dtHoje.setMonth(dtHoje.getMonth() -1);

      let mes = dtHoje.getMonth() + 1;
      let ano = dtHoje.getFullYear();

      setDadosFiltro({
        ...dadosFiltro,
        mes: mes,
        ano: ano,
      });
    })()
  }, []);

  async function onChangeWithName(value, name) {
    setDadosFiltro({
      ...dadosFiltro,
      [name]: value,
    });
  }

  function getYears(){
    var date = new Date();
    var inicio = 2021;
    var fim = date.getFullYear();
    var options = [];

    for(var i=inicio; i<=fim; i++){
      options.push({value: i, label: i});
    }
    return options;
  }

  function getMonths(){
    var date = new Date();
    var inicio = 1;
    var fim = 12;
    var options = [];

    if(dadosFiltro.ano == date.getFullYear()){
      fim = date.getMonth() + 1;
    }

    var mesNomes = ["", "janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"]

    for(var i=inicio; i<=fim; i++){
      options.push({value: i, label: mesNomes[i]});
    }
    return options;
  }

  return (
    <div className="filtro-extrato">
      <FormGroup className="row">
        <label className="col-md-1 col-form-label text-md-right">Ano</label>
        <div className="col-md-2">
          <SingleSelect
            value={dadosFiltro.ano}
            name="ano"
            onChange={onChangeWithName}
            options={anosList}
          />
        </div>
        <label className="col-md-1 col-form-label text-md-right">Mês</label>
        <div className="col-md-2">
          <SingleSelect
            value={dadosFiltro.mes}
            name="mes"
            onChange={onChangeWithName}
            options={mesList}
          />
        </div>
        <label className="col-md-1 col-form-label text-md-right">Regional</label>
        <div className="col-md-4">
          <SingleSelect
            isClearable={true}
            value={dadosFiltro.regionalId}
            name="regionalId"
            onChange={onChangeWithName}
            options={regionais}
          />
        </div>
      </FormGroup>

    </div>
  );
}

export default FiltroExtratoBrasil;
