import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import './styles.scss';
import api from '../../../api';
import { Context } from '../../../Context/AuthContext';
import actions from '../../../store/modules/bancoImagens/actions';

import TitlePages from '../../atoms/TitlePages';
import Filter from '../../molecules/Filter';
import SingleSelect from '../../atoms/SelectSingle';
import CardImage from '../../molecules/CardImage';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import TablePagination from '../../molecules/TablePagination';
const qs = require('qs');

const ContentBancoImagens = (props) => {
  const { showLoader } = useContext(Context);
  const state = useSelector((state) => state.bancoImagens);
  const [page, setPage] = useState(1);
  const [dataCardImages, setDataCardImage] = useState({});
  const [filterParams, setFilterParams] = useState({
    CategoriaImagemIds: [],
    MarcaIds: [],
    CategoriaProdutoIds: [],
    FamiliaIds: [],
    Texto: '',
  });

  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();

  const downloadProdutosSelecionados = async () => {
    showLoader(true);
    const { data } = await api({
      url: '/Download/get-image-zip', //your url
      method: 'GET',
      responseType: 'blob',
      params: {
        ids: state.produtosSelecionados.join(','),
      },
    });

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'imagens.zip');
    document.body.appendChild(link);
    link.click();
    showLoader(false);
  };

  async function changePage(page) {
    showLoader(true);
    setPage(page);
    const {
      data: { value },
    } = await api.get('/BancoImagem/list-paged', {
      params: {
        PageNumber: page,
        PageSize: 20,
        ...filterParams,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    history.push(
      `?pagina=${page}${
        filterParams.Texto ? '&nome=' + filterParams.Texto : ''
      }${
        filterParams.CategoriaImagemIds.length !== 0
          ? '&categoria=' + filterParams.CategoriaImagemIds
          : ''
      }${
        filterParams.MarcaIds.length !== 0
          ? '&marca=' + filterParams.MarcaIds
          : ''
      }${
        filterParams.CategoriaProdutoIds.length !== 0
          ? '&categoriaProduto=' + filterParams.CategoriaProdutoIds
          : ''
      }${
        filterParams.FamiliaIds.length !== 0
          ? '&familia=' + filterParams.FamiliaIds
          : ''
      }`,
    );
    setDataCardImage(value);
    showLoader(false);
  }

  const changeUrlParams = () => {};

  const selecionarTodas = () => {
    var items = state.produtosSelecionados;

    dataCardImages.data.forEach(function (imagem, i) {
      if (!items.includes(imagem.bancoImagemId)) {
        items.push(imagem.bancoImagemId);
      }
    });

    const action = actions.setProdutosSelecionados(items);
    dispatch(action);
    const action2 = actions.mostrarBotaoDownload(
      state.produtosSelecionados.length > 0 ? true : false,
    );
    dispatch(action2);
  };

  const removerTodasSelecionadas = () => {
    console.log(state.produtosSelecionados);
    const action = actions.setProdutosSelecionados([]);
    dispatch(action);
    const action2 = actions.mostrarBotaoDownload(false);
    dispatch(action2);
  };

  const verificarTodasSelecionadas = () => {
    var items = state.produtosSelecionados;

    var todasSelecionadas = true;

    if (
      dataCardImages &&
      dataCardImages.data &&
      dataCardImages.data.length > 0
    ) {
      dataCardImages.data.forEach(function (imagem, i) {
        if (!items.includes(imagem.bancoImagemId)) {
          todasSelecionadas = false;
          return;
        }
      });
    }

    return todasSelecionadas;
  };

  const filterImages = async (
    texto,
    marcasSelecionadas,
    categoriasProdutosSelecionados,
    familiasSelecionadas,
    categoriasImagensSelecionadas,
  ) => {
    setFilterParams({
      Texto: texto,
      CategoriaImagemIds: categoriasImagensSelecionadas,
      MarcaIds: marcasSelecionadas,
      CategoriaProdutoIds: categoriasProdutosSelecionados,
      FamiliaIds: familiasSelecionadas,
    });

    let params = {
      PageNumber: 1,
      PageSize: 20,
      CategoriaImagemIds: categoriasImagensSelecionadas,
      MarcaIds: marcasSelecionadas,
      CategoriaProdutoIds: categoriasProdutosSelecionados,
      FamiliaIds: familiasSelecionadas,
      Texto: texto,
    };
    showLoader(true);

    history.push(
      `?pagina=1${texto ? '&nome=' + texto : ''}${
        categoriasImagensSelecionadas.length !== 0
          ? '&categoria=' + categoriasImagensSelecionadas
          : ''
      }${marcasSelecionadas !== 0 ? '&marca=' + marcasSelecionadas : ''}${
        categoriasProdutosSelecionados.length !== 0
          ? '&categoriaProduto=' + categoriasProdutosSelecionados
          : ''
      }${
        familiasSelecionadas.length !== 0
          ? '&familia=' + familiasSelecionadas
          : ''
      }`,
    );

    const {
      data: { value },
    } = await api.get('/BancoImagem/list-paged', {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    setDataCardImage(value);
    showLoader(false);
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    (async () => {
      showLoader(true);

      let paginaAtual = query.get('pagina')
        ? parseInt(query.get('pagina'))
        : page;
      let nomeAtual = query.get('nome')
        ? query.get('nome')
        : filterParams.Texto;
      let categoriaAtual = query.get('categoria')
        ? query.get('categoria').split(',').map(Number)
        : filterParams.CategoriaImagemIds;
      let marcaAtual = query.get('marca')
        ? query.get('marca').split(',').map(Number)
        : filterParams.MarcaIds;
      let categoriaProdutoAtual = query.get('categoriaProduto')
        ? query.get('categoriaProduto').split(',')
        : filterParams.CategoriaProdutoIds;
      let familiaAtual = query.get('familia')
        ? query.get('familia').split(',')
        : filterParams.FamiliaIds;

      let params = {
        PageNumber: paginaAtual,
        PageSize: 20,
        CategoriaImagemIds: categoriaAtual,
        MarcaIds: marcaAtual,
        CategoriaProdutoIds: categoriaProdutoAtual,
        FamiliaIds: familiaAtual,
        Texto: nomeAtual,
      };

      const {
        data: { value },
      } = await api.get('/BancoImagem/list-paged', {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      setFilterParams({
        ...filterParams,
        CategoriaImagemIds: categoriaAtual.length
          ? categoriaAtual
          : filterParams.CategoriaImagemIds,
        MarcaIds: marcaAtual.length ? marcaAtual : filterParams.MarcaIds,
        CategoriaProdutoIds: categoriaProdutoAtual.length
          ? categoriaProdutoAtual
          : filterParams.CategoriaProdutoIds,
        FamiliaIds: familiaAtual.length
          ? familiaAtual
          : filterParams.FamiliaIds,
        Texto: nomeAtual ? nomeAtual : filterParams.Texto,
      });
      setPage(paginaAtual ? parseInt(paginaAtual) : page);
      setDataCardImage(value);
      showLoader(false);
    })();
  }, []);

  return (
    <div className="wrapper-banco-imagens">
      <TitlePages title="Banco de Imagens" />
      <div className="content">
        <div className="column1 col-lg-3">
          <Filter filtrar={filterImages} />
        </div>
        <div
          className="column2 float-left col-lg-9"
          style={{ position: 'static' }}
        >
          <div
            className="order"
            style={{
              justifyContent: state.mostrarBotaoDownload
                ? 'space-between'
                : 'flex-end',
            }}
          >
            <div
              className="download"
              style={{ display: state.mostrarBotaoDownload ? 'flex' : 'none' }}
            >
              <Button
                style={{
                  maxWidth: '340px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={downloadProdutosSelecionados}
              >
                <FontAwesomeIcon className="icon" icon={faDownload} />
                <div style={{ width: '100%' }}>BAIXAR SELECIONADAS</div>
                <div className="total">{state.produtosSelecionados.length}</div>
              </Button>
            </div>

            <div className="todas">
              <Button onClick={selecionarTodas}>SELECIONAR TODAS</Button>
            </div>
            <div className="ml-3 todas">
              <Button
                disabled={!state.produtosSelecionados.length}
                onClick={removerTodasSelecionadas}
              >
                EXCLUIR TODAS SELEÇÕES
              </Button>
            </div>
          </div>
          <div className="cards">
            <div
              className=" col-lg-12 cards-align "
              style={{ position: 'static' }}
            >
              <CardImage
                {...dataCardImages}
                changePage={changePage}
                produtosSelecionados={state.produtosSelecionados}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentBancoImagens;
