import React, { useEffect, useState, useContext } from 'react';

import api from '../../../api';

import { Context } from '../../../Context/AuthContext';

import './styles.scss';
import HeaderApp from '../../organisms/Header/HeaderApp';
import Bottom from '../../organisms/Bottom';
import ContentPedido from '../../organisms/ContentPedido';

const PedidoMateriais = (props) => {
  const { showLoader } = useContext(Context);

  const [pedidoTipo, setPedidoTipo] = useState({});

  useEffect(() => {
    (async () => {
      showLoader(true);

      const result = await api.get('/Pedido/get-pedidotipo-materiais');

      if (result?.data?.value)
        setPedidoTipo(result.data.value);

      showLoader(false);
    })();
  }, [])

  if (!pedidoTipo.pedidoTipoId)
    return (<></>);
  
  return (
    <>
      <HeaderApp />
        <ContentPedido pedidoTipo={pedidoTipo} pedidoDeCampanha={false} baseUrl={"/pedido-materiais"}/>
      <Bottom/>
    </>
  )
};

export default PedidoMateriais;
