import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

import './styles.scss';

const ModalDiscard = (props) => {
    let rascunhoId = props.rascunhoId;

    const handleDiscard = async () => {
        props.onDiscard();
    }

    const handleClose = () => {
        props.onClose();
    }

    const show = () => {
        return rascunhoId && rascunhoId > 0;
    }

    return (
        <Modal show={show() || false} onHide={handleClose} className="modal-discard-draft">
            <Modal.Header closeButton className="header">
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>Tem certeza que deseja descartar esse rascunho?</h3>
                <p>
                    Ao clicar em descartar, seu rascunho será apagado e você não poderá continuá-lo ou acessar as informações. Deseja continuar?
                </p>
                <Row className="buttons">
                    <Col className="text-center">
                        <Button variant="secondary" onClick={handleClose} className="button">
                            CANCELAR
                        </Button>
                    </Col>
                    <Col className="text-center">
                        <Button variant="danger" onClick={handleDiscard} className="button">
                            DESCARTAR
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default ModalDiscard;