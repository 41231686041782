import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
//import { useParams, Link } from 'react-router-dom';
//import { useDispatch } from 'react-redux';
//import { Button, Row, Col, Alert, Tabs, Tab, Sonnet, FormGroup } from 'react-bootstrap';
import {
  FaHome,
  FaAngleRight,
  FaSearch,
  FaPeopleCarry,
  FaUserCheck,
  FaFileUpload,
  FaChevronRight,
  FaChevronLeft,
  FaPlusCircle,
  FaCalendarCheck,
  FaFileDownload,
  FaTelegramPlane,
  FaImages
} from 'react-icons/fa';

import './styles.scss';
//import history from '../../../../Routes/history';
import api from '../../../../../../api';
//import TitlePages from '../../../atoms/TitlePages';
//import LoaderAction from '../../../../store/modules/loader/actions';
//import InputFileCustom from "../../../atoms/InputFile";
//import { faCaretRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from '../../../../../../Context/AuthContext';
//import SingleSelect from '../../../atoms/SelectSingle';
import FiltroExtratoLatam from '../FiltroLatam';
import ExtratoDownloadsLatam from '../DownloadsLatam'

const ExtratoLatam = (props) => {
  const {showLoader} = useContext(Context);

  const [filtro, setFiltro] = useState({});
  const [regionais, setRegionais] = useState([]);
  var regs = [{label: "", options: [{label: "LATAM", value: 2000}]}];
  useEffect(() => {
    (async () => {
      showLoader(true);

      var resultRegionais = await api.get('/regional/select-list');
      //regs;// = resultRegionais?.data?.value;
      if(resultRegionais?.data?.value != null && resultRegionais?.data?.value != [] && regs.length==1){
        regs[0].options = regs[0].options.concat(resultRegionais?.data?.value[1]?.options);
      }
      setRegionais(regs || []);

      showLoader(false);
    })()
  }, []);

  function onSelect(filtro){
    setFiltro(filtro);
  }

  return (
    <div className="brf-extrato">
      <FiltroExtratoLatam onSelect={onSelect} regionais={regionais}/>
      <ExtratoDownloadsLatam regional={regionais}  filtro={filtro}/>
    </div>
  );
}

export default ExtratoLatam;
