import React, { useState, useEffect } from 'react';
import './styles.scss';
import { UncontrolledCollapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api';

const Doubts = (pros) => {
  const [duvidas, setDuvidas] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { value },
      } = await api.get('/PerguntaFrequente/get-list', {
        ...duvidas,
      });

      setDuvidas(value);
    })();
  }, []);

  return (
    <div className="duvidasFrequentes">
      <div className="title">Dúvidas Frequentes</div>
      <div className="info">
        {duvidas.map((duvidas) => (
          <div className="rowDoubts">
            <label
              className=" col-md-8"
              id={'duvida' + duvidas.perguntaFrequenteId}
            >
              {duvidas.perguntaFrequenteId} . {duvidas.pergunta}
              <FontAwesomeIcon
                className="icone"
                icon={faAngleDown}
                size="lg"
              ></FontAwesomeIcon>
            </label>
            <UncontrolledCollapse
              id="respostas"
              toggler={'#duvida' + duvidas.perguntaFrequenteId}
            >
              <span className="col-md-8 ">
                {duvidas.resposta} <hr></hr>
              </span>
            </UncontrolledCollapse>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Doubts;
