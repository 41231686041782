import React from 'react';

import './styles.scss';

import HeaderApp from '../../../organisms/Header/HeaderApp';
import Bottom from '../../../organisms/Bottom';
import ContentSolicitacaoFluxoStatus from '../../../organisms/ContentSolicitacaoFluxoStatus';

const SolicitacaoFluxoStatus = () => {
  return (
    <>
      <HeaderApp/>
        <ContentSolicitacaoFluxoStatus/>
      <Bottom />
    </>
  );
};

export default SolicitacaoFluxoStatus;
