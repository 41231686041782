import React from 'react';

import PageWithoutHeader from '../../molecules/PageWithoutHeader';
import ContentRecoverPassword from '../../organisms/ContentRecoverPassword';

const RecoverPassword = () => {
  return (
    <PageWithoutHeader title="ESQUECI MINHA SENHA">
      <ContentRecoverPassword></ContentRecoverPassword>
    </PageWithoutHeader>
  );
}

export default RecoverPassword;