import React, {useState, useContext, useEffect} from 'react';
import {useParams, useLocation, useHistory} from 'react-router-dom';

import { Context } from '../../../Context/AuthContext';



const AdminLogar = () => {
  const { adminLoginAs } = useContext(Context);
  const history = useHistory();
  const params = useParams();

  let search = new URLSearchParams(useLocation().search);
  const [redirect, setRedirect] = useState(search.get('urlReturn') ? search.get('urlReturn') : '/');
  const [usuarioId, setUsuarioId] = useState(params.usuarioId || 0)

  useEffect(() => {
    if (usuarioId > 0)
      (async () => {
        await adminLoginAs(params.usuarioId, redirect)
      })();
    else
      history.push("/");
    
  }, [])

  return <></>;
}

export default AdminLogar;