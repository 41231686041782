import React, { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';

import { Context } from '../../../Context/AuthContext';
import api from '../../../api';
import { dateFormat, getDate } from "../../../helpers/Date.Helper";

import './styles.scss';

const qs = require('qs');

const ContentHistoricoPedidos = (props) => {

    const { showLoader } = useContext(Context);
    const [model, setModel] = useState([]);
    const [refresh, setRefresh] = useState([]);

    useEffect(() => {
        (async () => {
            showLoader(true);
            const { data: { value } } = await api.get('/pedido/get-pedidos');
            setModel(value);
            showLoader(false);
        })();
    }, []);

    return (
        <>
            <div className="table-historico-pedidos">
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>Cliente</th>
                            <th>Materiais</th>
                            <th>Criado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(model || []).map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{item.pedidoId}</td>
                                    <td>{item.loja?.razaoSocial}</td>
                                    <td>{(item.solicitacoes || []).map((solicitacao) => solicitacao.materialComunicacao?.nome).join(",")}</td>
                                    <td>{dateFormat(item.dataCriacao, true)}</td>
                                    <td>
                                        <Link to={`/resumo-pedido/${item.pedidoId}`}>
                                            <Button className="table-button" variant="primary">
                                                Visualizar
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
            </div>
        </div>
    
        </>
    );
}

export default ContentHistoricoPedidos;