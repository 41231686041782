import React, { useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import './styles.scss';
import TitlePages from '../../../../atoms/TitlePages';
import SubHeader from '../../../SubHeader';
import {
  tabs,
  entregaTabs,
  entregaTipoNomes,
} from '../../../ContentPedido/_constants';

import LojaLoja from './LojaLoja';
import CentroDistribuicao from './CentroDistribuicao';
import Outro from './Outro';

const EntregaFisica = (props) => {
  let solicitacao = props.solicitacao;
  let loja = props.loja;
  let entities = props.entities;

  const [entregaTipoId, setEntregaTipoId] = useState(0);

  let tipoEntregas = entities.tipoEntregas;

  useEffect(() => {
    setEntregaTipoId(solicitacao.entregaTipoId || 0);
  }, [solicitacao]);

  const entregaTipoDigital = (tipoEntregas || []).find((tipoEntrega) => {
    return tipoEntrega.entregaDigital;
  });

  const renderButtons = () => {
    if (!tipoEntregas.length) return null;

    if (!solicitacao.solicitacaoId) return null;

    let tipoEntregasFisica = tipoEntregas.filter(
      (entregaTipo) => entregaTipo.entregaFisica,
    );
    if (!tipoEntregasFisica.length) return null;

    return (
      <div className="row m-5">
        <div className="col-lg-12 text-center mb-2">
          <h5>Escolha a forma de entrega dos materiais selecionados</h5>
        </div>
        <div className="forma-entrega">
          {tipoEntregasFisica.map((entregaTipo, index) => {
            return (
              <div key={index} className="forma-entrega-opcao">
                <button
                  className={`btn btn-primary ${
                    solicitacao.entregaTipoId !== entregaTipo.entregaTipoId
                      ? 'bg-secondary'
                      : ''
                  }`}
                  onClick={() => {
                    solicitacao.entregaTipoId = entregaTipo.entregaTipoId;
                    setEntregaTipoId(solicitacao.entregaTipoId);
                  }}
                >
                  {entregaTipo.descricao}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderEntregaFisica = () => {
    if (!solicitacao.solicitacaoId) return null;

    if (!entregaTipoId || entregaTipoId === 0) return null;

    let entregaTipoFisica = (tipoEntregas || []).find((tipoEntrega) => {
      return (
        tipoEntrega.entregaFisica &&
        tipoEntrega.entregaTipoId === solicitacao.entregaTipoId
      );
    });

    if (!entregaTipoFisica) return null;

    if (entregaTipoFisica.nome === entregaTipoNomes.lojaLoja)
      return <LojaLoja solicitacao={solicitacao} entities={entities} />;

    if (entregaTipoFisica.nome === entregaTipoNomes.centroDistribuicao)
      return (
        <CentroDistribuicao
          solicitacao={solicitacao}
          loja={loja}
          entities={entities}
        />
      );

    if (entregaTipoFisica.nome === entregaTipoNomes.outro)
      return <Outro solicitacao={solicitacao} entities={entities} />;
  };

  // const renderButtonAvancar = () => {
  //   if (model.solicitacoes.findIndex((solicitacao) => solicitacao.imprimir) === -1)
  //     return null;

  //   if (!model.entregaTipoId)
  //     return null;

  //   if (!entregaTipoId || entregaTipoId === 0)
  //     return null;

  //   return (
  //     <div className="col-lg-6 d-flex flex-row-reverse">
  //       <button className="btn btn-primary btn-navigation btn-navigation-right" onClick={() => {}}>
  //         <span>AVANÇAR</span>
  //         <span><FaAngleRight size={26} /></span>
  //       </button>
  //     </div>
  //   )
  // }

  return (
    <div className="wrapper-content-alterar-solicitacao-entrega">
      {/* <TitlePages title="Entrega de Materiais Graficos" /> */}

      <div className="content-alterar-solicitacao-entrega">
        {/* <input type="text" value={model.loja.razaoSocial} onChange={(event) => {
        model.loja.razaoSocial = event.target.value;
        setModel(model);
      }} /> */}

        {/* {renderMateriais()} */}

        {renderButtons()}

        {renderEntregaFisica()}

        <div className="row">
          {/* <div className="col-sm-6 text-left">
            <button className="btn btn-primary btn-navigation" onClick={() => voltar()} >
              <span><FaAngleLeft size={26} /></span>
              <span>VOLTAR</span>
            </button>
          </div>

          {renderButtonAvancar()} */}

          {/* <pre className="text-left">{JSON.stringify(tipoEntregas, null, 2)}</pre> */}
        </div>
      </div>
    </div>
  );
};

export default EntregaFisica;
