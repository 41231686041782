import React from 'react';

import './styles.scss';

import HeaderApp from '../../organisms/Header/HeaderApp';
import ContentSelectMaterials from '../../organisms/ContentSelectMaterials';
import Bottom from '../../organisms/Bottom';

const SelectMaterials = () => {
  return (
    <>
      <HeaderApp/>
      <ContentSelectMaterials />
      <Bottom />
    </>
  );
};

export default SelectMaterials;
