import React from 'react';

import PageWithoutHeader from '../../../molecules/PageWithoutHeader';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle} from '@fortawesome/free-solid-svg-icons';

const PasswordChanged = () => {
  return (
    <PageWithoutHeader>
      <div className="page-content password-changed">
            <div className="icon-check">
                <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
            </div>
            <label>Senha alterada com sucesso!</label>
            <p>Agora você pode utilizar suas novas informações de segurança para acessar sua conta.</p>
            <a className="btn btn-link" href="/login"><FontAwesomeIcon icon={faArrowLeft} size="1x" /> Voltar para o login</a>
        </div>
    </PageWithoutHeader>
  );
}

export default PasswordChanged;

 