import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TablePagination from "../../molecules/TablePagination";
import { Card, CardBody, Input, Button, Row, Col, Table, FormGroup } from "reactstrap";
import SingleSelect from '../../atoms/SelectSingle';

import api from '../../../api';
import './styles.scss';
import { Context } from '../../../Context/AuthContext';

const ContentAdminUsuarios = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { showLoader } = useContext(Context);

  var searchParams = new URLSearchParams(location.search);

  //select lists
  const [selectListUsuarioTipo, setSelectListUsuarioTipo] = useState([]);

  //filters
  const [nome, setNome] = useState(searchParams.get("nome") ?? "");
  const [pageSize, setPageSize] = useState(searchParams.get("pageSize") ?? "20");
  const [pageNumber, setPageNumber] = useState(searchParams.get("pageNumber") ?? "1");
  const [usuarioTipoId, setUsuarioTipoId] = useState(searchParams.get("usuarioTipoId") ?? "");

  const [lista, setLista] = useState([]);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    (async () => {
      var result = await api.get("admin/select-list-usuariotipo");
      console.log(result);
      setSelectListUsuarioTipo(result?.data?.value || []);

      getListPaged();
    })();
  }, []);

  useEffect(() => {
    getListPaged();
  }, [pageNumber])


  return (
    <div className="content-admin-usuarios">

      <div className="row">
        <div className="col-md-12">
          <Card className="card-default">
            <CardBody>

              <form>
                <FormGroup row>
                  <label className="col-md-2 col-form-label text-md-right">Nome</label>
                  <div className="col-md-6">
                    <Input
                      className="from-control"
                      type="text"
                      placeholder=""
                      onChange={(e) => setNome(e.target.value)}
                      value={nome}
                    />
                  </div>
                </FormGroup>

                <FormGroup row>
                  <label className="col-md-2 col-form-label text-md-right">Tipo Usuário</label>
                  <div className="col-md-6">
                    <SingleSelect
                      options={selectListUsuarioTipo}
                      value={usuarioTipoId}
                      onChange={(value) => { setUsuarioTipoId(value) }}
                    />
                  </div>
                </FormGroup>

                <FormGroup row>
                  <div className="offset-md-2 col-md-8">
                    <Button
                      className="btn btn-default"
                      onClick={() => getListPaged()}
                    // disabled={loading}
                    >
                      <i className={"fa fa-filter"}></i>&nbsp; Filtrar
                        </Button>
                        &nbsp;
                        <Button
                      className="btn btn-default"
                      onClick={() => clearFilter()}
                    // disabled={loading}
                    >
                      <i className={"fa fa-eraser"}></i>&nbsp; Limpar
                        </Button>
                  </div>
                </FormGroup>
              </form>

              {renderTable()}

            </CardBody>
          </Card>
        </div>
      </div >
    </div >
  );

  function renderTable() {
    if (!pageData)
      return null;

    return (
      <div className="row">
        <div className="col-md-12">
          <hr />

          {pageData.totalRows > 0 &&

            <Table hover responsive>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nome</th>
                  <th>Tipo Usuário</th>
                  <th>Superior</th>
                  <th>Regional</th>
                  <th>Situação</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {lista.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{item.usuarioId}</td>
                      <td>{item.nome}</td>
                      <td>{item.usuarioTipo?.nome}</td>
                      <td>{item.usuarioSuperior?.nome}</td>
                      <td>{item.regional?.nome}</td>
                      <td>
                        {item.ativo == true
                          ? "Ativo"
                          : "Inativo"}
                      </td>
                      <td>
                        <Button
                          className="btn btn-custom"
                          onClick={() => logar(item)}
                          title="Logar"
                        >
                          Logar
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          }

          <TablePagination pageData={pageData} handlePageChange={handlePageChange} />

        </div>
      </div>
    )
  }

  function getListPaged() {
    (async () => {
      showLoader(true)
      var filter = getFilter();

      var query = new URLSearchParams(filter).toString();

      history.push('admin' + "?" + query);

      var result = await api.get("admin/list-paged-usuarios", {
        params: filter
      });

      if (result.data?.value !== null) {
        setLista(result.data.value.data);
        setPageData(result.data.value);
      }
      
      showLoader(false);
    })();
  }

  function getFilter() {
    return {
      nome: nome,
      usuarioTipoId: usuarioTipoId,
      pageNumber: pageNumber,
      pageSize: pageSize
    }
  }

  function clearFilter() {
    window.location = "/admin";
  }

  function logar(entity) {
    showLoader(true);
    history.push("admin/logar/" + entity.usuarioId);
  };

  function handlePageChange(pageNumber) {
    setPageNumber(pageNumber);
  };
}

export default ContentAdminUsuarios;
