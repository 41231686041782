import React from "react";
import './styles.scss';

export default props => {
    return (
        <React.Fragment>
            <div className='input-file-custom'>
                <label htmlFor={props.name}>Faça upload do arquivo</label>
                <input type="file" className="input-file" id={props.name} name={props.name} onChange={(e) => props.onChange(e, props.name)} />
                {props.file && <span id='file-name'>{props.file ? props.file.name : ""}</span>}
            </div>
        </React.Fragment>
    );
}