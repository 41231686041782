import React, { useState, useEffect } from 'react';
import actionLoader from '../../../store/modules/loader/actions';
import { useDispatch } from 'react-redux';

import api from '../../../api';

import './SummarySolicitacao';
import SummarySolicitacao from './SummarySolicitacao';

const Summary = (props) => {
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));

  let solicitacaoId = props.solicitacaoId;
  let updateIsOwner = props.updateIsOwner;

  const dispatch = useDispatch();

  const [model, setModel] = useState({});

  useEffect(() => {
    (async () => {
      if (solicitacaoId && solicitacaoId > 0) {
        dispatch(actionLoader.showLoader(true));

        const {
          data: { value },
        } = await api.get('/consulta/get-resumo-model', {
          params: { solicitacaoId: props.solicitacaoId },
        });
        setModel(value);

        if (updateIsOwner)
          updateIsOwner(value.pedido?.usuarioId === userAuth.userId);

        dispatch(actionLoader.showLoader(false));
      } else {
        setModel({});
      }
    })();
  }, [solicitacaoId]);

  if (!model.pedido) return null;

  return (
    <SummarySolicitacao pedido={model.pedido} solicitacao={model.solicitacao} />
  );
};

export default Summary;
