import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import CurrencyInput from 'react-currency-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import api from '../../../api';
import { Context } from '../../../Context/AuthContext';
import TitlePages from '../../atoms/TitlePages';
import ModalSummary from '../../molecules/ModalSummary';
import Historico from '../ContentSolicitacaoFluxoStatus/Historico';

const ContentSolicitacaoFluxoDefinirOrcamento = (props) => {
  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0,
  );
  const [model, setModel] = useState({});
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [resultModel, setResultModel] = useState({});

  useEffect(() => {
    (async () => {
      showLoader(true);

      const {
        data: { value: responseModel },
      } = await api.get(
        '/SolicitacaoFluxo/get-definir-orcamento-model?solicitacaoId=' +
        solicitacaoId,
      );

      setModel(responseModel);

      showLoader(false);
    })();
  }, []);

  function salvar() {
    showLoader(true);

    (async () => {
      const { data: response } = await api.post(
        '/SolicitacaoFluxo/definir-orcamento',
        model,
      );

      //value == true => alterado com sucesso
      if (response.value) {
        setModel({});
      }

      setResultModel(response);

      showLoader(false);
    })();
  }

  function renderResumoButton() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <div className="text-left">
        <button
          className="btn btn-primary"
          onClick={() => {
            setResumoSolicitacaoId(solicitacaoId);
          }}
        >
          Ver Solicitação
        </button>
      </div>
    );
  }

  function renderHistory() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    );
  }

  function renderButtons() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <button className="btn btn-primary" onClick={salvar}>
          <FontAwesomeIcon icon={faSave} /> Salvar
        </button>
      </>
    );
  }

  function renderForm() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <div className="row mb-3">
          <div className="col-lg-3">
            <label className="d-block mb-0">Valor</label>
            <CurrencyInput
              decimalSeparator=","
              thousandSeparator=""
              className="form-control"
              value={model.valor}
              onChangeEvent={(e) =>
                setModel({ ...model, valor: e.target.value })
              }
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg-9">
          <label className="d-block mb-0">Observação</label>
            <textarea
              rows="7"
              className="form-control"
              value={model.observacao || ""}
              onChange={(e) =>
                setModel({ ...model, observacao: e.target.value })
              }
            ></textarea>
          </div>
        </div>
      </>
    );
  }

  function renderModalSummary() {
    if (resumoSolicitacaoId === 0) return null;

    return (
      <ModalSummary
        solicitacaoId={resumoSolicitacaoId}
        onClose={() => {
          setResumoSolicitacaoId(0);
        }}
      />
    );
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <span key={index}>{error}</span>;
        })}
      </div>
    );
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  return (
    <div className="wrapper-content-solicitacaofluxo-aprovar-arte">
      <TitlePages title={`Solicitação  ${solicitacaoId} - Aguardando Orçamento`} />

      <div className="content-solicitacaofluxo">
        {/* {<pre>{JSON.stringify(resultModel, null, 2)}</pre>} */}

        {renderErrors()}

        {renderSuccess()}

        {renderResumoButton()}

        {renderForm()}

        {renderButtons()}

        {renderHistory()}

        {renderModalSummary()}

        {/* {<pre className="text-left">{JSON.stringify(model, null, 2)}</pre>} */}
      </div>
    </div>
  );
};

export default ContentSolicitacaoFluxoDefinirOrcamento;
