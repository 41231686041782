import React from 'react';

import './styles.scss';

import TitlePages from '../../atoms/TitlePages';
import  Contact  from '../../molecules/Contact';
import  OpenCalled  from '../../molecules/OpenCalled';
import  Doubts  from '../../molecules/Doubts';



const ContentAttendance = (props) => {
  return (
    <div className="wrapper-atendimento">
      <TitlePages title="ATENDIMENTO" />
      <div className="content">
          <div className='contato col-lg-10'>
            <Contact></Contact>
          </div>
          <div className=' abrir-Chamado col-lg-10'>
            <OpenCalled></OpenCalled>
          </div>
          <div className=' duvidas col-lg-10'>
            <Doubts></Doubts>
          </div>
      </div>
    </div>
  );
}

export default ContentAttendance;
