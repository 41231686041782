import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'qs';
import { Pagination } from 'react-bootstrap';
// import { FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { Context } from '../../../Context/AuthContext';
import '../../molecules/CardArtes/styles.scss';
import actions from '../../../store/modules/bancoImagens/actions';
import CardItemArtes from './CardItemArtes';
import TablePagination from '../TablePagination';

import api from '../../../api';

const CardArtes = (props) => {
  const [artes, setArtes] = useState([]);
  let itemsPagination = [];

  useEffect(() => {
    if (props.data) {
      setArtes(props.data);
    }
  }, [props.data]);



  return (
    <>
      <div className="card-deck-artes">
        {artes.map((arte, index) => (
          <CardItemArtes
            key={`banco-artes-${index}`}
            arte={arte}
            onClickResumo={props.onClickResumo} // <------ Faltou declarar aqui.
            handleSolicitarNovamente={props.handleSolicitarNovamente} // <------ Faltou declarar aqui.
          />
        ))}
      </div>
      <div>
      {props.totalPages > 1 && (
        <TablePagination
          pageData={props}
          handlePageChange={props.changePage}
        />
      )}
      </div>
    </>
  );
};

export default CardArtes;
