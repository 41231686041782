import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

import TitlePages from '../../atoms/TitlePages';


const ContentDeliveryMaterials = () => {
  return (
    <div className="content content-column">
      <TitlePages title="Entrega de Materiais Gráficos"/>

      <div className="info-message">
        Você selecionou materiais que podem ser impressos pela Multimaxi e entregues no endereço do cliente.
        Escolha abaixo quais materiais você quer que sejam impressos:
      </div>

      <div className="box-buttons">
        <button>Adesivos</button>
        <button>Banner Impresso</button>
        <button>Cubos</button>
        <button>Display de Mesa</button>
      </div>

      <div className="box-button-none-print">
        <button>Não quero imprimir nenhum material, avançar</button>
      </div>

      <div className="buttons-navigation">
        <button  className="btn btn-primary">
          <span><FontAwesomeIcon icon={ faAngleLeft }/></span>
          <span>VOLTAR</span>
        </button>
        {/* <button className="btn btn-primary">
          <span>AVANÇAR</span>
          <span><FontAwesomeIcon icon={ faAngleRight }/></span>
        </button> */}
      </div>
    </div>
    
  );
}

export default ContentDeliveryMaterials;