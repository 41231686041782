import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

const ContentRecoveryEmailSent = () => {

    return (
        <div className="page-content content-recovery-email-sent">
            <div className="icon-check">
                <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
            </div>
            <label> Email de redefinição de senha enviado com sucesso.</label>
            <p>Enviamos um email com as instruções de recuperação de conta para o seu email. Siga as instruções para redefinir sua senha.</p>

            <a className="btn btn-link" href="/login"><FontAwesomeIcon icon={faArrowLeft} size="1x" /> Voltar para o login</a>
        </div>
    );
}

export default ContentRecoveryEmailSent;