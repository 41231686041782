import React from 'react';

import Header from '../../organisms/Header/HeaderApp';
import ContentCalledFinished from '../../organisms/ContentCalledFinished';
import Bottom from '../../organisms/Bottom';

const CalledSent = () => {
  return (
    <>
      <Header/>
      <ContentCalledFinished />
      <Bottom/>
    </>
  );
}

export default CalledSent;