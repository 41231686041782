import React from 'react';
import Select, { components } from 'react-select';

const groupStyles = {
  fontWeight: 'bold',
  fontSize: '23px',
  color: '#bbb',
  display: 'flex',
  marginLeft: '-7px',
  padding: '5px 0 2px 0',
};

const GroupHeading = (props) => (
  <div style={groupStyles}>
    <components.GroupHeading {...props} />
  </div>
);

export const SingleSelect = (props) => (
  <Select
    isClearable={props.isClearable}
    options={props.options}
    value={setSelectedValue(props.options, props.value)}
    components={{ GroupHeading }}
    styles={{
      groupHeading: (base) => ({
        ...base,
      }),
    }}
    onChange={(value) =>
      props.onChange(value == null ? '' : value.value, props.name)
    }
    placeholder="Selecione..."
    isDisabled={props.disabled}
    className = {props.className}
  />
);

const setSelectedValue = function (selectList, value) {
  if (!selectList || selectList.length === 0) return false;

  if (!value) return false;

  for (let i = 0; i < selectList.length; i++) {
    var el = selectList[i];

    if (el.value && el.value == value) {
      return el;
    }

    if (el.options && el.options.length > 0) {
      var obj = setSelectedValue(el.options, value);

      if (obj) return obj;
    }
  }

  return false;
};

export default SingleSelect;
