import React from 'react';

import './styles.scss';

import Drawer from '../../../molecules/Drawer';
import Brand from '../../../atoms/Brand';
import LoggedUser from '../../../molecules/LoggedUser';
import Notifications from '../../../molecules/Notifications';
import NotificacaoChamados from '../../../molecules/NotificacaoChamado';

const HeaderApp = () => {
  return (
    <header className="header-app">
      <Drawer />
      <div className="brand-app">
        <div>
          <a href="/"><Brand white /></a>
        </div>
      </div>
      <LoggedUser/>
      <NotificacaoChamados/>
      <Notifications/>
    </header>
  );
}

export default HeaderApp;
