import React from 'react';
import './styles.scss';
import { FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdBadge } from '@fortawesome/free-solid-svg-icons';
import { dateFormat } from '../../../helpers/Date.Helper';

const Response = (props) => {
  return (
    <div className="content-response">
      <div className="resposta">
        <div className="nome">
          <FontAwesomeIcon icon={faIdBadge}></FontAwesomeIcon>
          <strong>{props.resposta.usuario.nome}</strong>
        </div>
        <div className="texto">
          {props.resposta.urlArquivo != null ? (
            <div>
              <p>{props.resposta.resposta}</p>
              <img src={props.resposta.urlArquivo}></img>
            </div>
          ) : (
            <p>{props.resposta.resposta}</p>
          )}
        </div>
        <div className="data">
          <output>{dateFormat(props.resposta.data)}</output>
        </div>
      </div>
    </div>
  );
};

export default Response;
