import React from 'react';

import Header from '../../organisms/Header/HeaderApp';
import ContentBancoImagens from '../../organisms/ContentBancoImagens';
import Bottom from '../../organisms/Bottom';

const BancoImagens = () => {
  return (
    <>
      <Header />
      <ContentBancoImagens path={`banco-de-imagens`} />
      <Bottom />
    </>
  );
};

export default BancoImagens;
