import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation, } from 'react-router-dom';

import './styles.scss';

import { tabs, entregaTabs, passos } from './../_constants';

import EntregaFisica from './EntregaFisica';
import EntregaDigital from './EntregaDigital'

const Entrega = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { model } = props;
  const { setModel } = props;
  const { updateRascunho } = props;
  const { entities } = props;
  const { baseUrl } = props;
  const { renderErrors } = props;
  const { setActiveTab } = props;

  let errors = (model.errors || []).filter((error) => error.passo === passos.passoEntrega.nro);
  let tipoMateriais = entities.tipoMateriais || [];
  let solicitacoes = model.solicitacoes || [];

  const [existeMaterialComProducao, setExisteMaterialComProducao] = useState(null);
  const [entregaActiveTab, setEntregaActiveTab] = useState("");

  const verificaSeExisteMaterialComProducao = () => {
    if (!tipoMateriais.length)
      return null;

    let materiais = [].concat(...tipoMateriais.map((tipoMaterial) => tipoMaterial.materiaisComunicacao));

    let selecionados = solicitacoes.map((solicitacao) => materiais.find((material) => material.materialComunicacaoId === solicitacao.materialComunicacaoId));

    return selecionados.findIndex((material) => material.possuiProducao) !== -1;
  }

  const changeTab = (entregaTab) => {
    history.push(`${baseUrl}/${model.rascunhoId}/${tabs.tabEntrega}/${entregaTab}`);
  };

  useEffect(() => {
    setExisteMaterialComProducao(verificaSeExisteMaterialComProducao());
  }, [model, entities])

  useEffect(() => {
    console.log('existeMaterialComProducao  ', existeMaterialComProducao)

    if (!model.rascunhoId || !solicitacoes.length)
      return;

    let initialTab = existeMaterialComProducao
      ? entregaTabs.tabFisica
      : entregaTabs.tabDigital;

    var pathnameSplitted = location.pathname.split("/");

    if (pathnameSplitted.length === 4) {
      history.push(`${baseUrl}/${model.rascunhoId}/${tabs.tabEntrega}/${initialTab}`);
    } else {
      setEntregaActiveTab(pathnameSplitted[4] || initialTab)
    }

  }, [existeMaterialComProducao])

  useEffect(() => {
    return history.listen((location) => {
      let tab = location.pathname.split("/")[4] || "";
      setEntregaActiveTab(tab);
    })
  }, [history]);

  const renderTabs = () => {
    if (existeMaterialComProducao) {

      if (entregaActiveTab === entregaTabs.tabFisica)
        return (
          <EntregaFisica
            model={model}
            entities={entities}
            setModel={setModel}
            avancarEntregaDigital={() => { updateRascunho(); changeTab(entregaTabs.tabDigital); }}
            avancarSolicitacoes={() => { updateRascunho(); setActiveTab(tabs.tabSolicitacoes) }}
            voltar={() => { updateRascunho(); setActiveTab(tabs.tabMateriais) }}
            errors={errors}
            renderErrors={renderErrors}
          />
        );

      if (entregaActiveTab === entregaTabs.tabDigital)
        return (
          <EntregaDigital
            model={model}
            entities={entities}
            setModel={setModel}
            avancar={() => { updateRascunho(); setActiveTab(tabs.tabSolicitacoes) }}
            voltar={() => { updateRascunho(); changeTab(entregaTabs.tabFisica) }}
            errors={errors}
            renderErrors={renderErrors}
          />
        );

    } else {

      if (entregaActiveTab === entregaTabs.tabDigital)
        return (
          <EntregaDigital
            model={model}
            entities={entities}
            setModel={setModel}
            avancar={() => { updateRascunho(); setActiveTab(tabs.tabSolicitacoes) }}
            voltar={() => { updateRascunho(); setActiveTab(tabs.tabMateriais) }}
            errors={errors}
            renderErrors={renderErrors}
          />
        );

    }

    return null;
  }

  return (
    <div className="wrapper-content-entrega">
      {renderTabs()}
    </div>
  );
}

export default Entrega;
