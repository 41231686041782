import React from 'react';

import './styles.scss';

import HeaderApp from '../../../organisms/Header/HeaderApp';
import Bottom from '../../../organisms/Bottom';
import ContentSolicitacaoFluxoEnviarArte from '../../../organisms/ContentSolicitacaoFluxoEnviarArte';

const SolicitacaoFluxoEnviarArte = () => {
  return (
    <>
      <HeaderApp/>
        <ContentSolicitacaoFluxoEnviarArte/>
      <Bottom />
    </>
  );
};

export default SolicitacaoFluxoEnviarArte;
