import React from 'react';
import Routes from './Routes';
import { useSelector } from 'react-redux';
import Loader from './components/atoms/Loader';

const App = (props) => {
  document.documentElement.lang = "pt-BR";

  const loader = useSelector(state => state.loader)
  return (
    <>
      {loader.loading===true? <Loader/> : null}
      <Routes />
    </>
  );
}

export default App;
