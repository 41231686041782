import React from 'react';

import Header from '../../organisms/Header/HeaderApp';
import ContentChatCalled from '../../organisms/ContentChatCalled';
import Bottom from '../../organisms/Bottom';

const ChatCalled = () => {
  return (
    <>
      <Header/>
      <ContentChatCalled />
      <Bottom/>
    </>
  );
}

export default ChatCalled;