import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
import history from '../../../Routes/history';
import api from '../../../api';
import { Context } from '../../../Context/AuthContext';

const initialState = {
    email: ''
};

const ContentRecoverPassword = (props) => {

    const { showLoader } = useContext(Context);
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState([]);

    function onChange(event) {
        const { value, name } = event.target;

        setValues({
            ...values,
            [name]: value,
        });
    }

    async function onSubmit(event) {
        event.preventDefault();

        setErrors([]);
        showLoader(true);
        
        const result = await api.post('/Usuario/recuperar-senha', {
            email: values["email"]
        });

        showLoader(false);
        if (result && result.data && result.data.errors && result.data.errors.length > 0) {
            setErrors(result.data.errors);
        }
        else {
            history.push('/email-recuperacao-enviado');
        }
    }

    return (
        <div className="content-recover-password">
            <label>Digite seu email cadastrado no campo abaixo que enviaremos as instruções para recuperação da sua senha:</label>
            
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">@</span>
                </div>
                <input type="text" name="email" className="form-control" aria-describedby="basic-addon1" onChange={onChange} />
            </div>
            {errors.length > 0 ?
                <div className="error-message">
                    {errors.map((e) => {
                        return (
                            <label>{e}</label>
                        );
                    }
                    )}
                </div>
                : ""}
            <div className="row">
                <div className="col-6">
                    <a className="btn btn-link" href="/login"><FontAwesomeIcon icon={faArrowLeft} size="1x" /> Voltar</a>
                </div>
                <div className="col-6 text-right">
                    <Button
                        onClick={onSubmit}
                        variant="primary"
                        type="button">
                        <FontAwesomeIcon icon={faPaperPlane} size="1x" /> Enviar
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ContentRecoverPassword;