import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import {
  FaHome,
  FaAngleRight,
  FaSearch,
  FaPeopleCarry,
  FaUserCheck,
  FaFileUpload,
  FaChevronRight,
  FaChevronLeft,
  FaPlusCircle,
  FaCalendarCheck,
  FaFileDownload,
  FaTelegramPlane,
  FaImages
} from 'react-icons/fa';

import './styles.scss';
import history from '../../../../Routes/history';
import api from '../../../../api';
import RequestMaterialsActions from '../../../../store/modules/request-materials/actions';
import TitlePages from '../../../atoms/TitlePages';
import LoaderAction from '../../../../store/modules/loader/actions';
import InputFileCustom from "../../../atoms/InputFile";
import { faCaretRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from '../../../../Context/AuthContext';
import SingleSelect from '../../../atoms/SelectSingle';
import { getDataAtualOnOff } from '../../../../util/onoff_data_atual';

import {
  faHome,
  faFile,
  faFileAlt,
  faExclamationTriangle,
  faSearch,
  faImage,
  faFileImage,
  faFileExcel,
  faAt,
  faUser,
  faSignOutAlt,
  faQuestionCircle,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';


const qs = require('qs');

const OnOffRelatorios = (props) => {
  const { showLoader } = useContext(Context);

  const [errorMessage, setErrorMessage] = useState('');
  const [dadosFiltro, setDadosFiltro] = useState({});
  const [regionalList, setRegionalList] = useState([]);
  const [canalList, setCanalList] = useState([]);
  const [mesList, setMesList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [mesAtual, setMesAtual] = useState('');
  const [planoFinalizado, setPlanoFinalizado] = useState(true);

  useEffect(() => {
    (async () => {
      showLoader(true);
      setMesAtual(getDataAtualOnOff());
      const { data } = await api.get('/OnOff/get-plano-atual');

      if (200 === data.code && (data.value == null || data.value.dataFechamento === null)) {
        setPlanoFinalizado(false);
      }

      const resultRegionais = await api.get('/OnOff/get-regionais-select-list');
      setRegionalList(resultRegionais.data.value || []);

      const resultCanais = await api.get('/OnOff/get-canais-select-list');
      setCanalList(resultCanais.data.value || []);

      const resultMes = await api.get('/OnOff/get-mes-select-list');
      setMesList(resultMes.data.value || []);

      await setMaterialListAsync("");

      showLoader(false);
    })()
  }, []);

  async function setMaterialListAsync(plano) {
    const resultMaterial = await api.get('/OnOff/get-material-select-list?plano=' + plano);
    setMaterialList(resultMaterial.data.value || []);
  }

  async function onChangeWithName(value, name) {
    setDadosFiltro({
      ...dadosFiltro,
      [name]: value,
    });
  }

  async function onPlanoChange(value, name) {
    showLoader(true);

    setDadosFiltro({
      ...dadosFiltro,
      [name]: value,
    });

    await setMaterialListAsync(value == null ? "" : value);

    showLoader(false);
  }

  function onChangeInput(event) {
    const { value, name } = event.target;

    setDadosFiltro({
      ...dadosFiltro,
      [name]: value,
    });
  }

  function goToUploads(route) {
    history.push(`/adicionar-midia-on-off/`);
  }

  function goToEnviadas(route) {
    history.push(`/on-off-midias-enviadas/`);
  }

  async function downloadPPTX() {
    if (!dadosFiltro || !dadosFiltro.mesReferencia) {
      setErrorMessage('Selecione o Mês de Veiculação.');
      return;
    }

    showLoader(true);

    var result = await api.get('/OnOff/get-pptx', {
      params: dadosFiltro,
    });

    if (result.data && result.data.value) {
      var today = new Date();
      var date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
      var file = _base64ToByteArray(result.data.value);
      var blob = new Blob([file], { type: "arraybuffer" });

      var fileName = "book_plano_on_of_" + date + ".pptx";
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      var fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();

      showLoader(false);
    }
    else {
      showLoader(false);
    }
  }
  async function downloadExcel() {
    // if (!dadosFiltro || !dadosFiltro.mesReferencia) {
    //   setErrorMessage('Selecione o Mês de Veiculação.');
    //   return;
    // }

    showLoader(true);
    var result = await api.get('/OnOff/get-relatorio-envio-as-bytes', {
      params: dadosFiltro,
    });

    if (result.data && result.data.value) {
      var today = new Date();
      var date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
      var file = _base64ToByteArray(result.data.value);
      var blob = new Blob([file], { type: "arraybuffer" });

      var fileName = "book_plano_on_of_" + date + ".xlsx";
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      var fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();

      showLoader(false);
    }
    else {
      showLoader(false);
    }
  }

  function _base64ToByteArray(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes;
  }

  return (
    <div>
      <h1 className="onoff-title">RELATÓRIO PLANO ON&OFF</h1>
      {!planoFinalizado &&
        <div className="text-center">
          <Button className="onoff-button" disabled={""} onClick={goToUploads}><FaFileUpload size="28" />&nbsp;&nbsp; FAZER UPLOAD DE MÍDIAS</Button>
          <Button className="onoff-button" disabled={""} onClick={goToEnviadas}><FaImages size="28" />&nbsp;&nbsp; MÍDIAS ENVIADAS EM {mesAtual}</Button>
        </div>
      }
      {planoFinalizado &&
        <div className="text-center">
          <label>Você já concluiu o envio de mídias deste mês.</label>
        </div>
      }
      <div className="d-flex justify-content-center">
        <div className="onoff-content">
          {errorMessage && <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>{errorMessage}</Alert>}
          <Row>
            <Col lg-6="true">

              <label>Regional</label>
              <div>
                <SingleSelect
                  isClearable={true}
                  value={dadosFiltro.regionalId}
                  name="regionalId"
                  onChange={onChangeWithName}
                  options={regionalList}
                />
              </div>

              <label>Canal</label>
              <div>
                <SingleSelect
                  isClearable={true}
                  value={dadosFiltro.canalId}
                  name="canalId"
                  onChange={onChangeWithName}
                  options={canalList}
                />
              </div>

              <label>Rede</label>
              <div>
                <input type="text" className="form-control" name="rede" onChange={onChangeInput} value={dadosFiltro.rede}></input>
              </div>

            </Col>

            <Col lg-6="true">
              <label>Mês de Veiculação</label>
              <div>
                <SingleSelect
                  isClearable={true}
                  value={dadosFiltro.mesReferencia}
                  name="mesReferencia"
                  onChange={onChangeWithName}
                  options={mesList}
                />
              </div>
              <label>Plano</label>
              <div>
                <SingleSelect
                  isClearable={true}
                  value={dadosFiltro.plano}
                  name="plano"
                  onChange={onPlanoChange}
                  options={[
                    { value: "ON", label: "ON" },
                    { value: "OFF", label: "OFF" }
                  ]}
                />
              </div>

              <label>Tipo de Material</label>
              <div>
                <SingleSelect
                  isClearable={true}
                  value={dadosFiltro.materialId}
                  name="materialId"
                  onChange={onChangeWithName}
                  options={materialList}
                />
              </div>
            </Col>
          </Row>
          <NavButtons />
        </div>
      </div>
    </div>
  );

  function NavButtons() {
    return (
      <div className="on-off-nav-btn">
        <Row>
          <Col lg-6="true">
            <Button className="onoff-button" disabled={""} onClick={() => { downloadPPTX() }}><FaFileDownload size="28" />&nbsp;&nbsp; BAIXAR BOOK PPT</Button>
          </Col>
          {/*<Col lg-4="true">
            <Button className="onoff-button" disabled={""} onClick={() => { }}><FaFileDownload size="28" />&nbsp;&nbsp; BAIXAR BOOK PDF</Button>
          </Col>*/}
          <Col lg-6="true">
            <Button className="onoff-button" disabled={""} onClick={() => { downloadExcel() }}> <FaFileDownload size="28" />&nbsp;&nbsp; BAIXAR RELATÓRIO DE UPLOAD</Button>
          </Col>
        </Row>
      </div>
    );
  }

}

export default OnOffRelatorios;
