import React from 'react';
import './styles.scss';
import TitlePages from '../../atoms/TitlePages';
import FormDataUser from '../../molecules/FormDataUser';
import FormPassword from '../../molecules/FormPassword';



const ContentDadosUsuario = (props) => {
  return (
    <div className="wrapper-dados-usuario">
      <TitlePages title="MEUS DADOS" />
      <div className="content-meus-dados">
          <div className='dados col-lg-10'>
              <FormDataUser/>
          </div>
          <div className=' senha col-lg-10'>
            <FormPassword/>
          </div>
      </div>
    </div>
  );
}

export default ContentDadosUsuario;