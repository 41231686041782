import React from "react";
import VMasker from 'vanilla-masker';

export default props => {
  return (
    <input type="text" className={`form-control ${props.className}`} value={props.value} onChange={(event) => {
      let input = event.target.value;
      input = VMasker.toPattern(input, "99999999999999999999");

      input = VMasker.toPattern(input, "99999-999");

      props.onChange(input)
    }} />
  );
}