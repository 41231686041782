import React from "react";
import TablePagination from "../../../molecules/TablePagination";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { dateFormat } from "../../../../helpers/Date.Helper";
import './styles.scss';

const TableQuery = (props) => {
    console.log(props)
const model = props.model;
    return (
        <div className="table-query">
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>Material</th>
                            <th>Etapa Atual</th>
                            <th>Criado</th>
                            <th>Modificado</th>
                            <th>Início Ofertas</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.model.list.map((item, idx) => {
                            return (
                                <tr key={idx} className={item.situacao === "C"? "cancelado": ""}>
                                    <td>{item.solicitacaoId}</td>
                                    <td>{item.materialComunicacao}</td>
                                    <td>{item.situacao === "C"? "Cancelada" : item.etapa}</td>
                                    <td>{dateFormat(item.dataCriacao, true)}</td>
                                    <td>{dateFormat(item.dataEtapa, true)}</td>
                                    <td>{dateFormat(item.dataVeiculacaoInicio)}</td>
                                    <td className="text-center">
                                        <Button className="table-button" variant={item.situacao === "C"? "danger": "primary"} onClick={() => props.onClickResumo(item.solicitacaoId)}>
                                            <FontAwesomeIcon icon={faFileInvoice}></FontAwesomeIcon> Resumo
                                        </Button>
                                    </td>
                                    <td className="text-center">
                                        <Button className="table-button" variant={item.situacao === "C"? "danger": "primary"}  onClick={() => props.onClickHistorico(item.solicitacaoId)}>
                                            <FontAwesomeIcon icon={faHistory}></FontAwesomeIcon> Histórico
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
            </div>
            <TablePagination pageData={props.model.pageData} handlePageChange={props.handlePageChange} />
        </div>
    );
}

export default TableQuery;