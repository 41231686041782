import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import NavigationMenu from '../NavigationMenu';

const Drawer = () => {
  return (
    <div className="drawer">
      <input type="checkbox" id="check" />

      <label htmlFor="check">
        <div className="turn-off-the-light"></div>
      </label>

      <label htmlFor="check" className="cursor-pointer bars">
        <FontAwesomeIcon icon={faBars} size="2x" />
      </label>

      <NavigationMenu />
    </div>
  );
};

export default Drawer;
