import React from "react";
import VMasker from 'vanilla-masker';

export default props => {
  return (
    <input
      type="text"
      className={`form-control ${props.className}`}
      value={props.value}
      onChange={(event) => {
        let input = event.target.value;
        input = VMasker.toPattern(input, "99999999999999999999");

        if (input.length > 10) {
          input = VMasker.toPattern(input, "(99) 99999-9999");
        } else {
          input = VMasker.toPattern(input, "(99) 9999-9999");
        }

        props.onChange(input)
      }}
    />
  );
}
