import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api';
import './styles.scss';

const Notifications = () => {
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));

  const [pendencias, setPendencias] = useState([]);
  const [autoCadastros, setAutoCadastros] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { value },
      } = await api.get('/Pendencia/count-pendencia-usuario');
      setPendencias(value);

      const result = await api.get('/CadastroUsuario/get-usuarios-autocadastrados');
      const lista = result.data.value || [];
      const count = lista?.length;
      setAutoCadastros(count);
    })();
  }, []);

  return (
    <>
      {(userAuth?.aprovaUsuario && autoCadastros > 0) ?
        <a href="/usuario-aprovacao" className="notifications cursor-pointer">
          <FontAwesomeIcon icon={faUserCheck} size="2x" />
          {autoCadastros > 0 ? <span id="usuario-aprovacao">{autoCadastros}</span> : ''}
        </a>
        : null
      }
      {pendencias > 0 &&
        <a href="/pendencias" className="notifications cursor-pointer">
          <FontAwesomeIcon icon={faBell} size="2x" />
          {pendencias > 0 ? <span id="pendencias">{pendencias}</span> : ''}
        </a>
      }
    </>
  );
};

export default Notifications;
