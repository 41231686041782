import React, { useState } from 'react';
import Scroll from 'react-scrollbar';

import './styles.scss';

const Card = (props) => {
  const [ materials, setMaterials ] = useState([]);

  const addRemoveItem = (event) => {

    if(event.target.checked) {
      const item = props.items.find(obj => obj.materialComunicacaoId === parseInt(event.target.value));
      setMaterials([ ...materials,  item]);
      
    } else {
      let newListMaterials = materials;

      const indexItem = materials.findIndex(obj => obj.materialComunicacaoId === parseInt(event.target.value));
      newListMaterials.splice(indexItem);
      
      setMaterials([ ...newListMaterials ]);
    }
  };

  // useEffect(() => {
  //   localStorage.setItem(`${props.type}`, JSON.stringify(materials));
  //  }, [materials, props.type]);
  
  return (
    <div className="mm-card">
      <div className="header-card">
        { props.title }
      </div>
        <Scroll speed={0.5} className="body-card">
          <ul>
            { props.items.map((item, index) => (
              <>
                <div>
                  <input
                    onChange={ addRemoveItem }
                    type="checkbox"
                    name=""
                    value={ item.materialComunicacaoId }
                    id={ `check-${item.materialComunicacaoTipo.nome}-${index}` }/>
                    <label
                      htmlFor={ `check-${item.materialComunicacaoTipo.nome}-${index}` }
                      >
                    <li key={ index }>
                      { item.nome }
                    </li>
                    </label>
                </div>
              </>
              )
            ) }
          </ul>
        </Scroll>
    </div>
  );
};

export default Card;
