import React, { useEffect, useState } from 'react';
import actionLoader from '../../../../store/modules/loader/actions';
import { useDispatch } from 'react-redux';

import api from '../../../../api';
import './styles.scss';

const Historico = (props) => {
  let solicitacaoId = props.solicitacaoId;
  const [historico, setHistorico] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (solicitacaoId && solicitacaoId > 0) {
        dispatch(actionLoader.showLoader(true));

        const { data: { value } } = await api.get('/solicitacaofluxo/get-historico', {
          params: { solicitacaoId: props.solicitacaoId }
        });

        setHistorico(value);

        dispatch(actionLoader.showLoader(false));
      } else {
        setHistorico({})
      }
    })();
  }, [solicitacaoId]);

  if (!historico.solicitacaoId || !historico.itens || !historico.itens.length === 0)
    return null;

  return (
    <div className="content-historico">
      {
        historico.itens.map((item, index) => {
          return (
            <div key={index} className="historico-item">
              <label>{item.etapa}</label>
              <label className="item-data">{item.data} {item.hora} - {item.usuario}</label>
              {
                item.observacao && item.observacao.length > 0 && (
                  <pre>{item.observacao}</pre>
                )
              }

              {
                item.urlAnexoObservacao && item.urlAnexoObservacao.length > 0 && (
                  <a href={`${item.urlAnexoObservacao}`} target="_blank">Anexo</a>
                )
              }

              <hr />
            </div>
          )
        })
      }
    </div>
  );
};

export default Historico;
