import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faArrowDown,
  faArrowUp,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import TitlePages from '../../atoms/TitlePages';

import SummarySolicitacao from '../../molecules/Summary/SummarySolicitacao';

import { Context } from '../../../Context/AuthContext';

import api from '../../../api';

const ContentResumoPedido = (props) => {
  const params = useParams();
  const history = useHistory();
  const { showLoader } = useContext(Context);
  // const location = useLocation();

  const [pedido, setPedido] = useState({});

  // const initialIndex = 0;
  // const currentIndex = parseInt(location.pathname.split("/")[4] ?? "0") || initialIndex;
  // const [activeSolicitacaoIndex, setActiveSolicitacaoIndex] = useState(currentIndex);

  const solicitar = () => {
    (async () => {
      showLoader(true);
      //const response = await api.post(`/Pedido/criar-copia-pedido/${solicitacaoId}`);
      const response = await api.post(
        `/Pedido/criar-copia-pedido/${pedido.pedidoId}`,
      );

      console.log(response);

      if (response?.data?.value) {
        let rascunho = response.data.value;

        history.push(`/pedido-materiais/${rascunho.rascunhoId}`);
      }

      showLoader(false);
    })();
  };

  const [ico, setIcon] = useState(false);
  const mudarIcon = () => {
    if (ico === false) {
      setIcon(true);
    } else {
      setIcon(false);
    }
  };

  useEffect(() => {
    //redireciona para home
    if (!params.pedidoId) {
      history.push('/');
    }
    //get pedido
    else {
      (async () => {
        showLoader(true);

        let response = await api.get(`/Pedido/get-pedido/${params.pedidoId}`);

        if (response?.data?.value) setPedido(response.data.value);

        showLoader(false);
      })();
    }
  }, []);

  if (!pedido.pedidoId) return null;

  return (
    <div className="wrapper-content-pedido-resumo">
      <TitlePages title={`Pedido ${pedido.pedidoId}`} />

      <div className="content-pedido-resumo">
        <div className="row">
          <div className="col-lg-12">
            <Accordion>
              {(pedido.solicitacoes || []).map((solicitacao, index) => {
                return (
                  <div key={index} className="col-lg-12 resumo mb-4">
                    <div className="resumo-header">
                      <Accordion.Toggle
                        onClick={mudarIcon}
                        as={Card.Header}
                        eventKey={`${index}`}
                      >
                        Solicitação {solicitacao.solicitacaoId} -{' '}
                        {solicitacao.materialComunicacao.nome}
                        <div className="float-right">
                          <FontAwesomeIcon
                            id="icone"
                            icon={ico !== true ? faAngleDown : faAngleUp}
                          ></FontAwesomeIcon>
                        </div>
                      </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse eventKey={`${index}`}>
                      <div className="p-3">
                        <SummarySolicitacao
                          pedido={pedido}
                          solicitacao={solicitacao}
                        />
                      </div>
                    </Accordion.Collapse>
                  </div>
                );
              })}
            </Accordion>
          </div>
        </div>

        <div className="text-right">
          <button className="btn btn-primary" onClick={solicitar}>
            SOLICITAR NOVAMENTE
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContentResumoPedido;
