import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../../Context/AuthContext';
import api from '../../../api';

import './styles.scss';

const NotificacaoChamados = () => {
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));
  const [contadorChamado, setContadorChamado] = useState(0);
  const { showLoader } = useContext(Context);

  const get_chamado_userId = async (usuarioId) => {
    const { data } = await api.get(
      '/chamado/get-list-chamado-por-usuario/' + usuarioId
    );
    return data;
  };

  useEffect(() => {
    (async () => {
      showLoader(true);
      const data = await get_chamado_userId(userAuth.userId);
      // setChamados(data.value);

      // Numero de chamados que não foi finalizado
      const chamadosAbertos = data.value.filter(
        (item) => item.dataFinalizacao === null
      );
      setContadorChamado(chamadosAbertos.length);
      showLoader(false);
    })();
  }, []);

  return (
    <>
      {contadorChamado > 0 &&
        <a href="/acompanhar-chamado" className="notifications cursor-pointer">
          <FontAwesomeIcon icon={faBullhorn} size="2x" />
          {contadorChamado > 0 ? <span id="chamados">{contadorChamado}</span> : ''}
        </a>
      }
    </>
  );
};

export default NotificacaoChamados;
