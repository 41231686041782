import React from 'react';


import HeaderApp from '../../organisms/Header/HeaderApp';
import Bottom from '../../organisms/Bottom';
import ContentDeliveryMaterials from '../../organisms/ContentDeliveryMaterials/DigitalMaterials';

const DeliveryDigitalMaterials = () => {
  return (
    <>
      <HeaderApp />
        <ContentDeliveryMaterials />
      <Bottom />
    </>
  );
};

export default DeliveryDigitalMaterials;


