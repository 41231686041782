import React from 'react';

import './styles.scss';
import HeaderApp from '../../organisms/Header/HeaderApp';
import Bottom from '../../organisms/Bottom';
import ContentChooseTheCustomer from '../../organisms/ContentChooseTheCustomer';

const ChooseTheCustomer = () => {
  return (
    <>
      <HeaderApp />
      <ContentChooseTheCustomer/>
      <Bottom/>
    </>
  )
};

export default ChooseTheCustomer;
