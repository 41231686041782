import React from 'react';
import {FaArrowLeft } from 'react-icons/fa';
import PageWithoutHeader from '../../../molecules/PageWithoutHeader';

const ContentCadastroSucesso = () => {
  return (
    <div style={{height: "400px"}}>
      <div class="alert alert-info" role="alert">
        <p>Seu cadastrado foi realizado com sucesso!</p>
        <p>Por favor, aguarde a aprovação do seu cadastro.</p>
        <p>Você será avisado por email sobre sua aprovação.</p>
      </div>
      <a href='/'><button className='btn btn-primary'><FaArrowLeft/> Voltar</button></a>
    </div>
  );
}

const SucessoCadastro = () => {
  return (
    <PageWithoutHeader title="CADASTRO REALIZADO COM SUCESSO">
      <ContentCadastroSucesso />
    </PageWithoutHeader>
  );
}

export default SucessoCadastro;
