import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../../Context/AuthContext';
import './styles.scss';
import api from '../../../api';
import TitlePages from '../../atoms/TitlePages';
import Question from '../../molecules/Question';
import Response from '../../molecules/Response';
import { FormGroup, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationArrow,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';
import ModalDiscardCalled from '../ContentChatCalled/ModalDiscardCalled';
import actionLoader from '../../../store/modules/loader/actions';
import { useDispatch } from 'react-redux';
import InputFileCustom from '../../atoms/InputFile';
import history from '../../../Routes/history';

const ContentChatCalled = (props) => {
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));
  const paramsLocation = new URLSearchParams(useLocation().search);
  const [chamados, setChamados] = useState({});
  const [chamadoId, setChamadoId] = useState(0);
  const { showLoader } = useContext(Context);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const handleDiscard = async () => {
    dispatch(actionLoader.showLoader(true));

    const formData = new FormData();

    formData.append('id', chamados.chamadoId);

    const {
      data: { value },
    } = await api.post('/Chamado/close/', formData);

    dispatch(actionLoader.showLoader(false));

    handleClose();

    if (value === true) {
      history.push('/chamado-finalizado/');
    }
  };

  const handleClose = async () => {
    setChamadoId(0);
  };

  const getChamados = async () => {
    showLoader(true);
    const {
      data: { value },
    } = await api.get('/Chamado/get', {
      params: {
        id: paramsLocation.get('id'),
      },
    });
    if (value) {
      value.mensagem = '';
      value.arquivo = null;
      setChamados(value);
    }
    else{
      history.push('/acompanhar-chamado/');
    }
    showLoader(false);
  };

  useEffect(() => {
    (async () => {
      await getChamados();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      var element = document.getElementById('conteudo_conversa');
      element.scrollTop = element.scrollHeight;

      setTimeout(function () {
        var element = document.getElementById('conteudo_conversa');
        element.scrollTop = element.scrollHeight;
      }, 1000);
    })();
  }, [chamados]);

  const onSubmit = async () => {
    const formData = new FormData();

    formData.append('chamadoId', chamados.chamadoId);
    formData.append('mensagem', chamados.mensagem);
    formData.append('usuarioId', userAuth.userId);
    formData.append('arquivo', chamados.arquivo);

    if (
      chamados.mensagem === '' ||
      chamados.mensagem === chamados.chamadoRespostas[0].resposta
    ) {
      setErrorMessage('Escreva sua mensagem  no campo abaixo!');
    } else {
      showLoader(true);

      const data = await api.post('/Chamado/add-message', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'WWW-Authenticate': `Bearer ${localStorage.getItem('midias_alpargatas') == null
              ? undefined
              : localStorage.getItem('midias_alpargatas').jwtToken
            }`,
        },
      });

      if (data.value === false && data.errors && data.errors.length > 0) {
        setErrorMessage(data.errors[0]);
        showLoader(false);
      } else {
        await getChamados();
        //history.push('/acompanhar-chamado');
      }
    }
  };

  function onChangeInput(event) {
    const { value, name } = event.target;

    setChamados({
      ...chamados,
      [name]: value,
    });
  }

  function onChangeFile(event, name) {
    setChamados({
      ...chamados,
      [name]: event.target.files[0],
    });
  }

  return (
    <div className="wrapper-chat-chamado">
      <TitlePages
        className="page-title"
        title={
          'CHAMADO ' + chamados?.chamadoId + ' - ' + chamados?.chamadoTipo?.nome
        }
      />
      <div className="contents">
        <div id="conteudo_conversa" className="conversa col-lg-8 col-md-8  ">
          <div className="conteudo">
            {chamados &&
              chamados.chamadoRespostas &&
              chamados.chamadoRespostas.map((resposta, i) => {
                if (resposta.usuarioId != userAuth?.userId) {
                  return <Response resposta={resposta} />;
                } else {
                  return <Question resposta={resposta} />;
                }
              })}
          </div>
        </div>
        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage('')}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
        {!chamados.dataFinalizacao && (
          <FormGroup className=" mensagem col-lg-8 col-md-8">
            <textarea
              type="input"
              name="mensagem"
              onChange={onChangeInput}
              className="col-lg-12"
              placeholder="Digite sua mensagem"
              value={chamados.mensagem}
            ></textarea>
          </FormGroup>
        )}

        <div
          style={{ display: chamados.dataFinalizacao !== null ? 'none' : '' }}
          className="responder col-lg-8 col-md-8 "
        >
          <FormGroup className="arquivo ">
            <InputFileCustom
              name="arquivo"
              onChange={onChangeFile}
              file={chamados.arquivo}
            ></InputFileCustom>
          </FormGroup>
          <FormGroup className=" btnResponder ">
            <Button className="input botao" onClick={onSubmit} >
              <FontAwesomeIcon
                className="icon"
                icon={faLocationArrow}
              ></FontAwesomeIcon>
              RESPONDER
            </Button>
          </FormGroup>
        </div>

        <div className="voltar col-lg-8 col-md-8 ">
          <FormGroup className="">
            <Button className=" btnVoltar" href="/acompanhar-chamado">
              <FontAwesomeIcon
                className="icon"
                icon={faAngleLeft}
              ></FontAwesomeIcon>&nbsp;
              VOLTAR
            </Button>
          </FormGroup>
          <FormGroup
            style={{ display: chamados.dataFinalizacao !== null ? 'none' : '' }}
            className=""
          >
            <Button onClick={() => setChamadoId(chamados.chamadoId)}>
              FINALIZAR
            </Button>
          </FormGroup>
        </div>
      </div>
      <ModalDiscardCalled
        chamados={chamadoId}
        onClose={handleClose}
        onDiscard={handleDiscard}
      ></ModalDiscardCalled>
    </div>
  );
};

export default ContentChatCalled;
