import api from '../../api';

const regional = () => {
  async function getRegionalSelectListFilhas() {
    const result = await api.get('/Regional/select-list-filhas');
    return result.data.value || [];
  }

  return {
    getRegionalSelectListFilhas,
  };
};

export default regional;
