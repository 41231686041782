import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

import HeaderApp from '../../organisms/Header/HeaderApp';
import Bottom from '../../organisms/Bottom';

import imageError from '../../../assets/brf/imgs/erro.png';
import './styles.scss';

const ContentError = () => {
  return (
    <div className="d-flex justify-content-center align-items-center content-error">
      <div className="text-center p-4">
        <img src={imageError} alt="erro" />
        <p></p>
        <h1>Erro Inesperado</h1>
        <h5>Não foi possível realizar a operação. Por favor tente novamente mais tarde.</h5>
        <Link to="/">
          <button type="button" className="mt-3 btn btn-primary btn-navigation">
            <span>
              <FaHome size={26} />
              <span className="ml-2">PÁGINA INICIAL</span>
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

const PageError = () => {
  return (
    <>
      <HeaderApp />
      <ContentError />
      <Bottom />
    </>
  );
}

export default PageError;
