import React from 'react';

import HeaderApp from '../../organisms/Header/HeaderApp';
import ContentResumoPedido from '../../organisms/ContentResumoPedido';
import Bottom from '../../organisms/Bottom';
//import ContentRelatorios from '../../organisms/ContentRelatorios';
import ContentRelatorios from './ContentRelatorios';

const Relatorios = () => {
  return (
    <>
      <HeaderApp />
      <ContentRelatorios/>
      <Bottom />
    </>
  );
}

export default Relatorios;
