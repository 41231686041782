import React, { useEffect, useState } from 'react';
import { FaSearch, FaAngleRight } from 'react-icons/fa'
import { Modal, Button } from 'react-bootstrap';
import Select, { components, createFilter } from "react-select";
import { optimizeSelect } from '../../../../../atoms/OptimizeSelect';

//import './styles.scss';
import api from "../../../../../../api";

import { getCampoObrigatorioClassName } from "../../../_constants";

import InputCEP from '../../../../../atoms/InputCEP';
import InputTelefone from '../../../../../atoms/InputTelefone';

const Outro = (props) => {
  let model = props.model;
  let entities = props.entities;
  let estados = props.estados || [];
  let setModel = props.setModel;

  model.entregaOutro = model.entregaOutro || {};

  let selectListEstados = estados.map((estado) => {
    return {
      value: estado.uf,
      label: estado.nome
    }
  });
  let initialSelectListCidades = (estados.find(x => x.uf === model.entregaOutro.uf)?.cidades || []).map((cidade) => {
    return {
      value: cidade.cidadeId,
      label: cidade.nome
    }
  });

  const [selectListCidades, setSelectListCidades] = useState(initialSelectListCidades || []);
  const updateSelectListCidades = () => {
    model.entregaOutro.cidadeId = null;
    setSelectedCidade(null);

    var estado = estados.find(x => x.uf === model.entregaOutro.uf) || {};

    setSelectListCidades(estado.cidades?.map((cidade) => {
      return {
        value: cidade.cidadeId,
        label: cidade.nome
      }
    }))
  }

  const [selectedCidade, setSelectedCidade] = useState((initialSelectListCidades || []).find((item) => item.value === model.entregaOutro.cidadeId) || null);
  const updateSelectedCidade = () => {
    setSelectedCidade((selectListCidades || []).find((item) => item.value === model.entregaOutro.cidadeId) || null);
    model.entregaOutro.cidade = ((estados.find(x => x.uf === model.entregaOutro.uf) || {}).cidades || []).find(x => x.cidadeId === model.entregaOutro.cidadeId);
  }

  //atualizar quando recuperar o rascunho
  useEffect(() => {
    setSelectListCidades(initialSelectListCidades || []);
    setSelectedCidade((initialSelectListCidades || []).find((item) => item.value === model.entregaOutro.cidadeId) || null);
  }, [model, estados])

  const [entregaOutro, setEntregaOutro] = useState(model.entregaOutro);
  const updateEntregaOutro = () => {
    model.entregaOutro = { ...model.entregaOutro };
    setEntregaOutro(model.entregaOutro);
  }

  return (
    <div className="entrega-fisica-panel">
      <div className="row">
        <div className="col-lg-4">
          <div>
            <label>CEP</label>
            <InputCEP
              className={`form-control ${getCampoObrigatorioClassName(entregaOutro.cep, true)}`}
              value={entregaOutro.cep || ""} onChange={(value) => {
                model.entregaOutro.cep = value;
                updateEntregaOutro();
              }} />
          </div>

          <div>
            <label>Endereço</label>
            <input type="text"
              className={`form-control ${getCampoObrigatorioClassName(entregaOutro.logradouro, true)}`}
              value={entregaOutro.logradouro || ""}
              onChange={(event) => {
                model.entregaOutro.logradouro = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>

          <div>
            <label>Número</label>
            <input type="text"
              className={`form-control ${getCampoObrigatorioClassName(entregaOutro.numero, true)}`}
              value={entregaOutro.numero || ""}
              onChange={(event) => {
                model.entregaOutro.numero = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>

          <div>
            <label>Complemento</label>
            <input type="text" className="form-control" value={entregaOutro.complemento || ""}
              onChange={(event) => {
                model.entregaOutro.complemento = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>

          <div>
            <label>Bairro</label>
            <input type="text"
              className={`form-control ${getCampoObrigatorioClassName(entregaOutro.bairro, true)}`}
              value={entregaOutro.bairro || ""}
              onChange={(event) => {
                model.entregaOutro.bairro = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>
        </div>

        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-6">
              <div>
                <label>Estado</label>
                {/* <input type="text" className="form-control" value={entregaOutro.cidade?.uf || ""}
                  onChange={(event) => {
                    model.entregaOutro.cidade = model.entregaOutro.cidade || {};
                    model.entregaOutro.cidade.uf = event.target.value;
                    updateEntregaOutro();
                  }}
                /> */}
                <Select
                  className={`${getCampoObrigatorioClassName(model.entregaOutro.uf, true)}`}
                  options={selectListEstados} isClearable={true}
                  onChange={(item) => {
                    model.entregaOutro.uf = item?.value;
                    updateSelectListCidades();
                    updateEntregaOutro();
                  }}
                  value={selectListEstados.find((item) => item.value === model.entregaOutro?.uf)}
                />
              </div>

              <div>
                <label>Cidade</label>
                {/* <input type="text" className="form-control" value={entregaOutro.cidade?.nome || ""}
                  onChange={(event) => {
                    model.entregaOutro.cidade = model.entregaOutro.cidade || {};
                    model.entregaOutro.cidade.nome = event.target.value;
                    updateEntregaOutro();
                  }}
                /> */}
                <Select
                  className={`${getCampoObrigatorioClassName(model.entregaOutro.cidadeId, true)}`}
                  components={optimizeSelect.components}
                  filterOption={createFilter({ ignoreAccents: false })}
                  options={selectListCidades || []} isClearable={true}
                  onChange={(item) => {
                    var cidadeId = item?.value ?? 0;
                    if (model.entregaOutro.cidadeId !== cidadeId) {
                      model.entregaOutro.cidadeId = cidadeId;
                      updateSelectedCidade();
                      updateEntregaOutro();
                    }
                  }}
                  value={selectedCidade}
                  isDisabled={!model.entregaOutro?.uf}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>Razão Social</label>
                <input type="text"
                  className={`form-control ${getCampoObrigatorioClassName(entregaOutro.razaoSocial, true)}`}
                  value={entregaOutro.razaoSocial || ""}
                  onChange={(event) => {
                    model.entregaOutro.razaoSocial = event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>

              <div>
                <label>CNPJ/CPF</label>
                <input type="text"
                  className={`form-control ${getCampoObrigatorioClassName(entregaOutro.cnpj, true)}`}
                  value={entregaOutro.cnpj || ""}
                  onChange={(event) => {
                    model.entregaOutro.cnpj = event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>
            </div>
            <div />
          </div>

          <div className="row">
            <div className="col-lg-12">
              <hr />

              <h5>Dados do Responsável</h5>

              <div>
                <label>Nome</label>
                <input type="text"
                  className={`form-control ${getCampoObrigatorioClassName(entregaOutro.responsavelNome, true)}`}
                  value={entregaOutro.responsavelNome || ""}
                  onChange={(event) => {
                    model.entregaOutro.responsavelNome = event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>Telefone</label>
                <InputTelefone
                  className={`${getCampoObrigatorioClassName(entregaOutro.responsavelTelefone, true)}`}
                  value={entregaOutro.responsavelTelefone || ""} onChange={(value) => {
                    model.entregaOutro.responsavelTelefone = value;
                    updateEntregaOutro();
                  }} />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>E-mail</label>
                <input type="text"
                  className={`form-control ${getCampoObrigatorioClassName(entregaOutro.responsavelEmail, true)}`}
                  value={entregaOutro.responsavelEmail || ""}
                  onChange={(event) => {
                    model.entregaOutro.responsavelEmail = event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Outro;
