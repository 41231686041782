import React, { useEffect, useState, useContext } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


import { Context } from '../../../../Context/AuthContext';

import InputFileCustom from '../../../atoms/InputFile';

import api from '../../../../api';

import './styles.scss';

const ModalComment = (props) => {
  const { showLoader } = useContext(Context);

  const solicitacaoId = props.solicitacaoId;
  const enviarObservacao = props.enviarObservacao;
  const cancelarObservacao = props.cancelarObservacao;

  const [observacao, setObservacao] = useState('');
  const [urlAnexoObservacao, setUrlAnexoObservacao] = useState('');

  const uploadAnexoObservacao = async (event) => {
    showLoader(true);

    event.preventDefault();

    if (event.target.files.length === 0) return;

    // dispatch(LoaderAction.showLoader(true));
    const formData = new FormData();

    formData.append('solicitacaoId', solicitacaoId);
    formData.append('file', event.target.files[0]);

    const {
      data: { value },
    } = await api.post('/SolicitacaoFluxo/upload-file', formData);

    setUrlAnexoObservacao(value);
    showLoader(false);
  };

  return (
    <div className="off-light" style={{ display: props.show ? 'flex' : 'none' }}>
      <div className="mm-modal modal-lg">

        <div className="mm-header-modal">
          <div className="mm-header-close">
            <FontAwesomeIcon icon={faTimes} onClick={() => cancelarObservacao()} />
          </div>
          <h4>Comentário</h4>
        </div>
        <div className="mm-body-modal">

          <textarea className="form-control" onChange={(event) => setObservacao(event.target.value)} value={observacao} rows="6"></textarea>

          <div className="mt-4">
            <label>Anexar arquivo</label>
            <InputFileCustom
              name="Logo"
              onChange={uploadAnexoObservacao}
            />

            {
              urlAnexoObservacao?.length > 0 &&
              <div>
                <a href={`${urlAnexoObservacao}`} className="btn btn-success" target="_blank">Download arquivo</a>
              </div>
            }

          </div>

        </div>

        <div className="mm-footer-modal">
          <div>
            <button onClick={() => enviarObservacao(observacao, urlAnexoObservacao)}>Enviar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalComment;
