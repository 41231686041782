import React from 'react';

import Header from '../../organisms/Header/HeaderApp';
import Bottom from '../../organisms/Bottom';
import ContentAttendance from '../../organisms/ContentAttendance';

const Attendance = () => {
  return (
    <>
      <Header/>
      <ContentAttendance />
      <Bottom/>
    </>
  );
}

export default Attendance;