import React, { useState, useContext, useEffect } from 'react';

const Arquivo = (props) => {

    let arquivo = props.arquivo;
    const [ setArquivo ] = useState(() => props.setArquivo);

    function getFile(file) {
        var fileBytes = [];

        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = function (evt) {
            if (evt.target.readyState == FileReader.DONE) {
                var arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                for (var i = 0; i < array.length; i++) {
                    fileBytes.push(array[i]);
                }
            }
        }

        return {
            name: file.name,
            type: file.type,
            bytes: fileBytes
        };
    }

    return (
        <div className="mb-3">
            <label className="mb-0">Arquivo:</label>
            <div className="input-group">
                <div className="custom-file">
                    <input type="file" className="custom-file-input" onChange={(event) => { setArquivo(getFile(event.target.files[0])); }}/>
                    <label className="custom-file-label" >{arquivo && arquivo.name ? arquivo.name : "Escolher arquivo"}</label>
                </div>
            </div>
        </div>
    );

}

export default Arquivo;
