import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { FaHome, FaAngleRight, FaSearch } from 'react-icons/fa';
import Select from '../../../atoms/SelectSingle/index';


import './styles.scss';
import api from '../../../../api';
import TitlePages from '../../../atoms/TitlePages';
import InputFileCustom from "../../../atoms/InputFile";

import { tabs, passos, getCampoObrigatorioClassName } from '../_constants';

const Cliente = (props) => {
  const { model } = props;
  const { setModel } = props;
  const { updateRascunho } = props;
  const { renderErrors } = props;
  const { setActiveTab } = props;
  const { setLoader } = props;
  const { entities } = props;

  let errors = (model.errors || []).filter((error) => error.passo === passos.passoCliente.nro);

  const [cliente, setCliente] = useState({});
  const [search, setSearch] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [clientSelected, setClientSelected] = useState(null);
  const [withBrand, setWithBrand] = useState(true);
  const [segmentos, setSegmentos] = useState([]);
  const [regionais, setRegionais] = useState([]);
  const [selectListRegional, setSelectListRegional] = useState([]);
  const [bandeira, setBandeira] = useState("");
  const [showMessageNenhumCliente, setShowMessageNenhumCliente] = useState(false);
  const [isLatam, setIsLatam] = useState(false);

  const updateCliente = (obj) => {
    if (model.lojaId === obj.lojaId)
      return;

    model.loja = obj;
    model.lojaId = obj.lojaId || 0;
    model.segmentoId = obj.segmentoId || 0;
    model.bandeira = obj.bandeira;
    model.regionalId = obj.regionalId;
    setModel({ ...model });
    setIsLatam(regionalIsLatam(model.loja?.regional));
  }

  const regionalIsLatam = (regional) => {
    if(!regional){
      return false;
    }

    if(regional.nome == "LATAM"){
      return true;
    }

    if(regional.regionalSuperior?.nome == "LATAM"){
      return true;
    }

    return false;
  }

  useEffect(() => {
    setCliente(model.loja || {});
    setBandeira(model.bandeira || "");

    // loja.regional do rascunho tem regionalSuperior = null, por isso pega da lista de regionais que esta completo.
    const regional = regionais.find(r => r.regionalId == model.loja?.regionalId);
    setIsLatam(regionalIsLatam(regional));
  }, [model]);

  useEffect(() => {
    if (!segmentos.length && entities.segmentos?.length)
      setSegmentos(entities.segmentos);

    if (!regionais.length && entities.regionais?.length)
      setRegionais(entities.regionais);

  }, [entities])

  const searchByName = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    setSelectListRegional(regionais.map(regional => {
      return {
        value: regional.regionalId,
        label: regional.nome,
      };
    }));
  }, [regionais])

  const validaDados = () =>{
    if(!isLatam){
      return !cliente.lojaId || !model.bandeira?.length || !model.segmentoId || !model.regionalId;
    }

    return !cliente.lojaId || !model.regionalId
  }

  const onSearch = async () => {
    setLoader(true);
    setShowMessageNenhumCliente(false);
    const { data: { value } } = await api.get('/Solicitacao/pesquisar-lojas', {
      params: { codigoCnpjRazaoSocial: search },
    });

    if (value.length === 0) {
      setShowMessageNenhumCliente(true);
    }

    else if (value.length === 1) {
      updateCliente(value[0]);
    }

    else {
      setClientsList(value);
      setShowModal(true);
    }

    setLoader(false);
  };

  const selectClient = (index) => (event) => {
    setClientSelected(index);
    updateCliente(clientsList[index]);

    document.getElementById('set-brand').checked = true;
  };

  const hideModal = () => {
    setShowModal(false);
  }

  const setSettingsBrand = (event) => {
    setWithBrand((event.target.value === 'true'));
  }

  const uploadLogo = async (event) => {
    event.preventDefault();

    setLoader(true);
    const formData = new FormData();

    formData.append("lojaId", cliente.lojaId);
    formData.append("file", event.target.files[0]);

    const { data: { value } } = await api.post('/Solicitacao/upload-logo', formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          'WWW-Authenticate': `Bearer ${localStorage.getItem('midias_alpargatas') == null ? undefined : localStorage.getItem('midias_alpargatas').jwtToken}`,
        }
      }
    );

    updateCliente({ ...cliente, urlLogo: value });

    setLoader(false);

  };

  return (
    <div className="wrapper-content-pedido-cliente">
      <TitlePages title="Escolha o Cliente" />
      <div className="content-pedido-cliente">

        {renderErrors(errors)}

        <div className={`content-cliente-search`}>
          <div id='busca' className=" busca">
            <div className="col-lg-12">
              <label>Digite o nome do Cliente</label>
              <div className="input-with-icon">
                <input type="text" onChange={searchByName} onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onSearch();
                  }
                }} />
                <div className="icon" onClick={onSearch} >
                  <FaSearch size={22} />
                </div>
              </div>
            </div>

          </div>
        </div>
        <Modal show={showModal} onHide={hideModal} className="modal-busca-loja">
          <Modal.Header closeButton className="header">
            <Modal.Title>Clientes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Cliente</th>
                </tr>
              </thead>
              <tbody>
                {
                  clientsList.map((item, index) => {
                    return (
                      <tr className={clientSelected === index ? "tr-selecao-loja tr-selecionado" : "tr-selecao-loja"} key={index} onClick={selectClient(index)}
                        style={{
                          backgroundColor: clientSelected === index
                            ? 'rgba(40, 109, 184,.5)'
                            : 'inherit'
                        }}>
                        <td>{item.razaoSocial}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button variant="primary" onClick={hideModal} className="button-close">
              CONCLUIR
            </Button>
          </Modal.Footer>
        </Modal>

        {showMessageNenhumCliente &&
          <div className="alert alert-warning">
            Nenhum cliente encontrado.
          </div>
        }

        <div className={`display-client`}>
          <div className={`${!cliente.lojaId ? "d-none" : ""}`}>

            {/* Cliente ----------------------------------------------------------------------- */}
            <div className="mb-3">
              <label>Cliente: </label>
              <label className="form-control">{cliente.razaoSocial}</label>
            </div>

            {/* Bandeira ---------------------------------------------------------------------- */}
            <div className="mb-3">
              <label>Bandeira: </label>
              <input type="text" className={`form-control ${getCampoObrigatorioClassName(model.bandeira, !isLatam)}`}
                value={bandeira || ""}
                onChange={(e) => {
                  if (cliente.bandeira?.length)
                    return;

                  model.bandeira = e.target.value;
                  setBandeira(model.bandeira);
                }}
                disabled={cliente.bandeira?.length}
              />
            </div>

            {/* Segmento ---------------------------------------------------------------------- */}
            <div className="mb-3">
              <label>Segmento: </label>
              <div className={`p-2 ${getCampoObrigatorioClassName(model.segmentoId, !isLatam)}`}>
                {segmentos.map((segmento, index) => {
                  return (
                    <label key={index} className="mr-2">
                      <input type="radio"
                        value={segmento.segmentoId}
                        checked={segmento.segmentoId === model.segmentoId}
                        onChange={(e) => {
                          if (cliente.segmentoId > 0)
                            return;

                          setModel({ ...model, segmentoId: parseInt(e.target.value) });
                        }}
                        disabled={cliente.segmentoId > 0}
                      /> {segmento.nome}
                    </label>
                  )
                })}
              </div>
            </div>

            {/* Regional ---------------------------------------------------------------------- */}
            <div className="mb-3">
              <label>Regional: </label>
              <Select
                isClearable={true}
                value={model.regionalId}
                name="regionalId"
                onChange={(value, name) => {
                  setModel({
                    ...model,
                    [name]: value,
                  });
                }}
                options={selectListRegional}
                className={getCampoObrigatorioClassName(model.regionalId, true)}
                disabled={isLatam}
              />
            </div>
          </div>

          {/* Logo ---------------------------------------------------------------------------- */}
          <div className="set-settings-brand pl-5">
            <div style={{ opacity: cliente.lojaId ? 1 : 0 }} className="text-right" >
              <input type="radio" id="set-brand" name="with-brand" onChange={setSettingsBrand} value={true} checked={withBrand} />
              <label htmlFor="set-brand">Com Logo</label>

              <input type="radio" id="set-no-brand" name="with-brand" onChange={setSettingsBrand} value={false} checked={!withBrand} />
              <label htmlFor="set-no-brand">Sem Logo</label>

              {
                withBrand
                &&
                <div>

                  {cliente.urlLogo && <img className="img-logo" src={cliente.urlLogo ? cliente.urlLogo : ''} alt="" />}
                  <div style={{ marginTop: "20px" }}>
                  </div>
                  <InputFileCustom
                    name="Logo"
                    onChange={uploadLogo}
                  />

                </div>
              }
            </div>
          </div>
        </div>

        <div className="row m-0">

          {/* Botao Pagina Inicial ------------------------------------------------------------ */}
          <div className=" text-left">
            {/* <button className="btn btn-primary btn-navigation">
              <span><FaHome size={26} /></span>
              <span>VOLTAR PARA A PÁGINA INICIAL</span>
            </button> */}

            <Link to="/">
              <button className="btn btn-primary btn-navigation">
                <span><FaHome size={26} /></span>
                <span>PÁGINA INICIAL</span>
              </button>
            </Link>
          </div>

          {/* Boatao Avancar ------------------------------------------------------------------ */}
          <div className=" d-flex flex-row-reverse">
            <button
              className={`btn btn-primary btn-navigation btn-navigation-right ${validaDados()
                ? "btn-disabled"
                : ""
                }`}
              onClick={() => {
                if (validaDados())
                  return;

                updateRascunho();

                if (model.pedidoDeCampanha)
                  setActiveTab(tabs.tabLayoutCampanha)
                else
                  setActiveTab(tabs.tabMateriais)
              }
              }>
              <span>AVANÇAR</span>
              <span><FaAngleRight size={26} /></span>
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Cliente;
