import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
registerLocale('pt', pt);

const DatePickerCustom = (props) => {
  return (
    <div className="date-picker-custom input-group">
      <DatePicker
        locale="pt"
        name={props.name}
        dateFormat="dd/MM/yyyy"
        disabled={props.disabled}
        selected={props.selected}
        minDate={props.minDate}
        onChange={(value) => props.onChange(value, props.name)}
        className={'form-control ' + props.className}
      />
      <div className="input-group-append">
        <span className="input-group-text">
          <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
        </span>
      </div>
    </div>
  );
};

export default DatePickerCustom;
