import React, { useState, useContext, useEffect } from 'react';
import { FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEraser } from '@fortawesome/free-solid-svg-icons';

import MultiSelect from '../../../atoms/SelectMult';
import { Context } from '../../../../Context/AuthContext';

import DatePicker from '../../../atoms/DatePicker';
import './styles.scss';

const Filter = (props) => {
  const model = props.model;
  const { getMaterialComunicacaoSelectList, getEtapaSelectList } =
    useContext(Context);
  const [materialList, setMaterialList] = useState([]);
  const [etapaList, setEtapaList] = useState([]);

  useEffect(() => {
    (async () => {
      setMaterialList(await getMaterialComunicacaoSelectList());
      setEtapaList(await getEtapaSelectList());
    })();
  }, []);

  function onChangeInput(event) {
    const { value, name } = event.target;

    props.setModel({
      ...model,
      [name]: value,
    });
  }

  async function onChangeWithName(value, name) {
    props.setModel({
      ...model,
      [name]: value,
    });
  }
  return (
    <div className="filter-query">
      <form>
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Nº da Solicitação
          </label>
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              name="solicitacaoId"
              onChange={onChangeInput}
              value={model.solicitacaoId}
            ></input>
          </div>
        </FormGroup>
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Material de Comunicação
          </label>
          <div className="col-md-9">
            <MultiSelect
              value={model.materialComunicacaoIds}
              name="materialComunicacaoIds"
              onChange={onChangeWithName}
              options={materialList}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Etapa Atual
          </label>
          <div className="col-md-9">
            <MultiSelect
              value={model.etapaIds}
              name="etapaIds"
              onChange={onChangeWithName}
              options={etapaList}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Criado em
          </label>
          <div className="col-md-4">
            <DatePicker
              className="filter-data"
              name="dataInicio"
              selected={model.dataInicio}
              onChange={onChangeWithName}
            ></DatePicker>
          </div>
          <label className="col-md-1 col-form-label text-md-center">até</label>
          <div className="col-md-4">
            <DatePicker
              className="filter-data"
              name="dataFim"
              selected={model.dataFim}
              onChange={onChangeWithName}
            ></DatePicker>
          </div>
        </FormGroup>
        <FormGroup className="row">
          <div className="offset-md-3 col-md-9">
            <Button
              className="query-button"
              variant="primary"
              onClick={props.filter}
            >
              <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon> Filtrar
            </Button>
            <Button
              className="query-button"
              variant="outline-primary"
              onClick={props.clearfilter}
            >
              <FontAwesomeIcon icon={faEraser}></FontAwesomeIcon> Limpar
            </Button>
          </div>
        </FormGroup>
      </form>
    </div>
  );
};

export default Filter;
