import React, { useEffect, useState, useContext } from 'react';
import './styles.scss';
import { FormGroup, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import SingleSelect from '../../atoms/SelectSingle';
import api from '../../../api';
import { Context } from '../../../Context/AuthContext';

const FormDataUser = (props) => {
  const { showLoader } = useContext(Context);
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));

  const { getRegionalSelectListFilhas } = useContext(Context);

  const [dadosDoUsuario, setdadosDoUsuario] = useState({});
  const [regionalList, setRegionalList] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const onSubmit = async () => {
    showLoader(true);
    const { data } = await api.post('/Usuario/alterar-meusdados', {
      ...dadosDoUsuario,
    });

    setShowSuccess(false);
    setErrorMessage('');

    if (data.value === false && data.errors && data.errors.length > 0) {
      setErrorMessage(data.errors[0]);
    } else if (data.value === true) {
      setShowSuccess(true);
    } else {
      setErrorMessage('Não foi possível executar esta operação!');
    }
    showLoader(false);
  };

  const getRegionalId = (value) => {
    setdadosDoUsuario({ ...dadosDoUsuario, regionalId: value });
  };

  useEffect(() => {
    (async () => {
      showLoader(true);
      setRegionalList(await getRegionalSelectListFilhas());

      const {
        data: { value },
      } = await api.get('/Usuario/get', { params: { id: userAuth.userId } });
      setdadosDoUsuario({
        usuarioId: value.usuarioId,
        nome: value.nome,
        email: value.email,
        telefone: value.telefone,
        regionalId: value.regionalId,
        recebeEmail: value.recebeEmail,
      });
      showLoader(false);
    })();
  }, []);

  async function onChangeWithName(value, name) {
    setdadosDoUsuario({
      ...dadosDoUsuario,
      [name]: value,
    });
  }

  function onChangeInput(event) {
    const { value, name } = event.target;

    setdadosDoUsuario({
      ...dadosDoUsuario,
      [name]: value,
    });
  }

  function onChangeCheckbox(event) {
    const { checked, name } = event.target;

    setdadosDoUsuario({
      ...dadosDoUsuario,
      [name]: checked,
    });
  }

  return (
    <div className="form-data-user">
      <div className="title">DADOS</div>
      <div className="info">
        <form>
          {/* Erros -------------------------------------------------------------------------------------------------------- */}
          {errorMessage && (
            <Alert
              variant="danger"
              onClose={() => setErrorMessage('')}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}

          {/* Mensagem de Sucesso ------------------------------------------------------------------------------------------ */}
          {showSuccess && (
            <Alert
              variant="success"
              onClose={() => setShowSuccess(false)}
              dismissible
            >
              Dados alterados com sucesso!
            </Alert>
          )}

          {/* Nome --------------------------------------------------------------------------------------------------------- */}
          <FormGroup className="row">
            <label className="col-md-4 col-form-label text-md-right">
              Nome
            </label>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="nome"
                onChange={onChangeInput}
                value={dadosDoUsuario.nome}
              ></input>
            </div>
          </FormGroup>

          {/* Email -------------------------------------------------------------------------------------------------------- */}
          <FormGroup className="row">
            <label className="col-md-4 col-form-label text-md-right">
              E-mail
            </label>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="email"
                onChange={onChangeInput}
                value={dadosDoUsuario.email}
              ></input>
            </div>
          </FormGroup>

          {/* Telefone ----------------------------------------------------------------------------------------------------- */}
          <FormGroup className="row">
            <label className="col-md-4 col-form-label text-md-right">
              Telefone
            </label>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="telefone"
                onChange={onChangeInput}
                value={dadosDoUsuario.telefone}
              ></input>
            </div>
          </FormGroup>

          {/* Regional ----------------------------------------------------------------------------------------------------- */}
          <FormGroup className="row">
            <label className="col-md-4 col-form-label text-md-right">
              Regional
            </label>
            <div className="col-md-6">
              <SingleSelect
                value={dadosDoUsuario.regionalId}
                name="regionalId"
                onChange={onChangeWithName}
                options={regionalList}
              />
            </div>
          </FormGroup>

          {/* Receber emails ----------------------------------------------------------------------------------------------- */}
          <FormGroup className="row">
            <label className="offset-md-4 col-md-6 col-form-label">
              <input
                name="recebeEmail"
                onChange={onChangeCheckbox}
                type="checkbox"
                checked={dadosDoUsuario.recebeEmail}
              />{' '}
              Receber emails de notificações sobre solicitações
            </label>
          </FormGroup>

          {/* Botao Salvar ------------------------------------------------------------------------------------------------- */}
          <FormGroup className="row">
            <div className="offset-md-4 col-md-4">
              <Button
                onClick={onSubmit}
                className="query-button"
                variant="primary"
              >
                <FontAwesomeIcon icon={faSave}></FontAwesomeIcon> Salvar
              </Button>
            </div>
          </FormGroup>
        </form>
      </div>
    </div>
  );
};

export default FormDataUser;
