import React from 'react';

import './styles.scss';
import Brand from '../../../atoms/Brand/';

const HeaderLogoOnly = () => {
  return (
    <header className="header-logo-only">
      <div className="content-brand">
        <Brand white/>
      </div>
    </header>
  );
}

export default HeaderLogoOnly;
