import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import api from '../../api';
import history from '../../Routes/history';
import actionLoader from '../../store/modules/loader/actions';

const useAuth = () => {
  const dispatch = useDispatch();

  const [loaderShowing, setShowLoader] = useState(false);
  const [userData, setUserData] = useState({
    atualizarDados: false,
    jwtToken: null,
    userId: null,
    authenticated: false,
    nome: null,
    regionalId: null,
  });

  useEffect(() => {
    async function autoLogin() {
      const loginUserData = JSON.parse(localStorage.getItem('midias_alpargatas'));
      setUserData(loginUserData);
    }
    autoLogin();
  }, []);

  async function handleLogin({ username, password, redirect }) {
    showLoader(true);
    const {
      data: {
        value: { authenticated, nome, userId, regionalId, atualizarDados, aprovaUsuario, regiao, acessaRelatorio, acessaTodosRelatorios },
        jwtToken,
      },
    } = await api.post('/login', { username, password });

    localStorage.setItem(
      'midias_alpargatas',
      JSON.stringify({
        jwtToken,
        userId,
        authenticated,
        nome,
        regionalId,
        atualizarDados,
        aprovaUsuario,
        regiao,
        acessaRelatorio,
        acessaTodosRelatorios
      }),
    );
    localStorage.setItem('token', jwtToken);
    setUserData({
      jwtToken,
      userId,
      authenticated,
      nome,
      regionalId,
      atualizarDados,
      aprovaUsuario,
      regiao,
      acessaRelatorio,
      acessaTodosRelatorios
    });
    showLoader(false);
    if (authenticated === true) {
      history.push(redirect);
    }
    return authenticated;
  }

  async function handleLogout() {
    showLoader(true);
    await api.get('/logout');
    localStorage.removeItem('midias_alpargatas');
    setUserData({
      atualizarDados: false,
      jwtToken: null,
      userId: null,
      authenticated: false,
      nome: null,
      regionalId: null,
      aprovaUsuario: false,
      regiao: "",
      acessaRelatorio: false,
      acessaTodosRelatorios: false
    });
    history.replace('/login');
    showLoader(false);
  }

  async function adminLoginAs(usuarioId, redirect) {
    showLoader(true);
    const {
      data: {
        value: { authenticated, nome, userId, regionalId, atualizarDados, aprovaUsuario, regiao, acessaRelatorio, acessaTodosRelatorios },
        jwtToken,
      },
    } = await api.get('/admin/logar?usuarioId=' + usuarioId);

    setLocalStorage({
      jwtToken,
      userId,
      authenticated,
      nome,
      regionalId,
      atualizarDados,
      aprovaUsuario,
      regiao,
      acessaRelatorio,
      acessaTodosRelatorios
    });

    showLoader(false);
    history.push(redirect || "/");
  }

  async function loginWithToken(token) {
    showLoader(true);
    const {
      data: {
        value: { authenticated, nome, userId, regionalId, atualizarDados, urlRedirect, aprovaUsuario, error, regiao, acessaRelatorio, acessaTodosRelatorios },
        jwtToken,
      },
    } = await api.post('/login-with-token', { token: token });

    if (error?.length) {
      history.push("/");
      return;
    }

    setLocalStorage({
      jwtToken,
      userId,
      authenticated,
      nome,
      regionalId,
      atualizarDados,
      aprovaUsuario,
      regiao,
      acessaRelatorio,
      acessaTodosRelatorios
    });

    history.push(urlRedirect || "/");
    showLoader(false);
  }

  function setLocalStorage(user) {
    localStorage.setItem(
      'midias_alpargatas',
      JSON.stringify(user),
    );
    localStorage.setItem('token', user.jwtToken);
    setUserData(user);
  }

  async function showLoader(value) {
    dispatch(actionLoader.showLoader(value));
    setShowLoader(value);
  }

  return {
    loaderShowing,
    showLoader,
    handleLogin,
    handleLogout,
    userData,
    setUserData,
    adminLoginAs,
    loginWithToken
  };
};

export default useAuth;
