class dateUtil_{
  // Retorna a differenca de dias de duas datas
  getDaysDiff = (date1, date2) => {
    const date1_ = new Date(date1);
    const date2_ = new Date(date2);
    const diffTime = Math.abs(date2_ - date1_);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }
}

const dateUtil = new dateUtil_();
export default dateUtil;
