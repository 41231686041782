import React from 'react';

import './styles.scss';

import HeaderApp from '../../organisms/Header/HeaderApp';
import Bottom from '../../organisms/Bottom';
//import ContentPendencias from '../../organisms/ContentPendencias';
import ContentPendenciaDemanda from '../../organisms/ContentPendeciaDemanda';

const Pendencias = () => {
  return (
    <>
      <HeaderApp/>
      <ContentPendenciaDemanda/>
      <Bottom />
    </>
  );
};

export default Pendencias;
