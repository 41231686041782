import React from 'react';

import HeaderApp from '../../organisms/Header/HeaderApp';
import ContentDraft from '../../organisms/ContentDraft';
import Bottom from '../../organisms/Bottom';
import PageTitle from '../../atoms/PageTitle';

const Draft = () => {
  return (
    <>
      <HeaderApp />
      <PageTitle title="Rascunhos"/>
      <hr></hr>
      <ContentDraft />
      <Bottom />
    </>
  );
}

export default Draft;