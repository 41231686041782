import React from 'react';

import HeaderApp from '../../organisms/Header/HeaderApp';
import ContentAdminUsuarios from '../../organisms/ContentAdminUsuarios';
import Bottom from '../../organisms/Bottom';
import PageTitle from '../../atoms/PageTitle';

const App = () => {
  return (
    <>
      <HeaderApp />
      <PageTitle title="Usuários"/>
      <ContentAdminUsuarios />
      <Bottom />
    </>
  );
}

export default App;
