import React from 'react';
import './styles.scss';

/**
 * Wrapper element for template content
 */
const ContentWrapper = props =>(
    <div className="content-wrapper-1024">
            {props.children}
    </div>
)

export default ContentWrapper;
