import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Context } from '../../../Context/AuthContext';
import { UncontrolledCollapse } from 'reactstrap';
import { FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEraser, faPlus } from '@fortawesome/free-solid-svg-icons';

import '../Filter/styles.scss';
import api from '../../../api';
import BancoImagensActions from '../../../store/modules/bancoImagens/actions';
import { propTypes } from 'react-bootstrap/esm/Image';

const Filter = (props) => {
  const dispatch = useDispatch();
  const { showLoader } = useContext(Context);

  const [categorias, setCategorias] = useState([]);
  const [categoriasProdutos, setCategoriasProdutos] = useState([]);
  const [categoriasFilhas, setCategoriasFilhas] = useState([]);

  const [categoriasImagensSelecionadas, setCategoriasImagensSelecionadas] = useState([]);
  const [categoriasProdutosSelecionados, setCategoriasProdutosSelecionados] = useState([]);

  const [marcas, setMarcas] = useState([]);
  const [marcasSelecionadas, setMarcasSelecionadas] = useState([]);

  const [familias, setFamilias] = useState([]);
  const [familiasSelecionadas, setFamiliasSelecionadas] = useState([]);
  const [familiasFiltradas, setFamiliasFiltradas] = useState([]);

  const [texto, setTexto] = useState('');

  function onChangeTexto(event) {
    const { value, name } = event.target;
    setTexto(value);
  }

  const onChangeCategorias = (event) => {
    let catImagens = categoriasImagensSelecionadas;
    if (event.target.checked) {
      const item = catImagens.find(
        (item) => item === parseFloat(event.target.value),
      );

      if (!item) {
        catImagens.push(parseFloat(event.target.value));
      }
    } else {
      const item = catImagens.find(
        (item) => item === parseFloat(event.target.value),
      );
      const indexItem = catImagens.findIndex(
        (item) => item === parseFloat(event.target.value),
      );

      if (item) {
        catImagens.splice(indexItem);
      }
    }

    // let action = BancoImagensActions.setCategoriasImagens(catImagens);
    // dispatch(action);

    setCategoriasImagensSelecionadas(catImagens);
  };

  const onChangeMarcas = (event) => {
    const elementValue = parseInt(event.target.value);
    const $element = event.target;
    let marcas = marcasSelecionadas;
    let items = [];

    if ($element.checked) {
      const itemMarca = marcas.find((marca) => marca === elementValue);

      if (!itemMarca) {
        marcas.push(elementValue);
      }
      setMarcasSelecionadas(marcas);

      marcas.map((marca) => {
        categoriasFilhas.map((categoria) => {
          if (categoria.superiorIds.includes(marca)) {
            if (items.length === 0) {
              items.push(categoria);
            } else {
              const objMatch = items.find(
                (obj) => JSON.stringify(obj) === JSON.stringify(categoria),
              );
              if (!objMatch) {
                items.push(categoria);
              }
            }
          }
        });
      });

      if (marcas.length === 0) {
        setCategoriasProdutos(categoriasFilhas);
      } else {
        setCategoriasProdutos(items);
      }
    } else {
      let newMarcas = [];

      for (let i = 0; i < marcas.length; i++) {
        if (marcas[i] !== elementValue) {
          newMarcas.push(marcas[i]);
        }
      }
      let newItems = [];
      const items = categoriasProdutos;

      setMarcasSelecionadas(newMarcas);

      newMarcas.map((marca) => {
        items.map((item) => {
          if (item.superiorIds.includes(marca)) {
            if (newItems.length === 0) {
              newItems.push(item);
            } else {
              const objMatch = newItems.find(
                (obj) => JSON.stringify(obj) === JSON.stringify(item),
              );
              if (!objMatch) {
                newItems.push(item);
              }
            }
          }
        });
      });

      if (newMarcas.length === 0) {
        setCategoriasProdutos(categoriasFilhas);
      } else {
        setCategoriasProdutos(newItems);
      }
    }
  };

  const onChangeCategoriasProdutos = (event) => {
    const elementValue = event.target.value;
    const $element = event.target;
    let categorias = categoriasProdutosSelecionados;
    let items = [];
    let idsCategorias = [];

    if ($element.checked) {
      const itemCategoria = categorias.find((cat) => cat === elementValue);

      if (!itemCategoria) {
        categorias.push(elementValue);
      }
      setCategoriasProdutosSelecionados(categorias);

      categorias.forEach(function(nome) {
          let categoria = categoriasProdutos.filter((e) => e.nomeNormalizado === nome);
          if (categoria)
          {
            categoria[0].ids.forEach(function(id) {
              idsCategorias.push(id);
            });
          }
      });

      idsCategorias.map((categoria) => {
        familiasFiltradas.map((familia) => {
          if (familia.superiorIds.includes(categoria)) {
            if (items.length === 0) {
              items.push(familia);
            } else {
              const objMatch = items.find(
                (obj) => JSON.stringify(obj) === JSON.stringify(familia),
              );
              if (!objMatch) {
                items.push(familia);
              }
            }
          }
        });
      });
      if (categorias.length === 0) {
        setFamilias(familiasFiltradas);
      } else {
        setFamilias(items);
      }
    } else {
      let newCategoria = [];

      for (let i = 0; i < categorias.length; i++) {
        if (categorias[i] !== elementValue) {
          newCategoria.push(categorias[i]);
        }
      }
      let newItems = [];
      const items = familias;

      setCategoriasProdutosSelecionados(newCategoria);

      newCategoria.forEach(function(nome) {
        let categoria = categoriasProdutos.filter((e) => e.nomeNormalizado === nome);
        if (categoria)
        {
          categoria[0].ids.forEach(function(id) {
            idsCategorias.push(id);
          });
        }
    });

      newCategoria.map((categoria) => {
        items.map((item) => {
          if (item.superiorIds.includes(categoria)) {
            if (newItems.length === 0) {
              newItems.push(item);
            } else {
              const objMatch = newItems.find(
                (obj) => JSON.stringify(obj) === JSON.stringify(item),
              );
              if (!objMatch) {
                newItems.push(item);
              }
            }
          }
        });
      });

      if (newCategoria.length === 0) {
        setFamilias(familiasFiltradas);
      } else {
        setFamilias(newItems);
      }
    }
  };

  const onChangeFamilia = (event) => {
    let familiasSele = familiasSelecionadas;

    if (event.target.checked) {
      const item = familiasSele.find((item) => item === event.target.value);

      if (!item) {
        familiasSele.push(event.target.value);
      }
    } else {
      const item = familiasSele.find((item) => item === event.target.value);
      const indexItem = familiasSele.findIndex(
        (item) => item === event.target.value,
      );

      if (item) {
        familiasSele.splice(indexItem);
      }
    }

    setFamiliasSelecionadas(familiasSele);
  };

  const filterImages = () => {
    props.filtrar(
      texto,
      marcasSelecionadas,
      categoriasProdutosSelecionados,
      familiasSelecionadas,
      categoriasImagensSelecionadas,
    );
  };

  const clearFilter = () => {
    setCategoriasImagensSelecionadas([]);
    setCategoriasProdutosSelecionados([]);
    setMarcasSelecionadas([]);
    setFamiliasSelecionadas([]);
    setTexto('');

    const items = document.getElementsByTagName('INPUT');

    for (let loop = 0; loop < items.length; loop++) {
      let item = items[loop];
      if (item.type === 'checkbox' && item.checked) {
        item.checked = false;
      }
    }

    props.filtrar('', [], [], []);
  };

  useEffect(() => {
    (async () => {
      const {
        data: { value },
      } = await api.get('/BancoImagem/get-categorias');
      setCategorias(value);
    })();

    (async () => {
      const {
        data: { value },
      } = await api.get('/ProdutoCategoria/get-marcas');
      setMarcas(value);

      const { data } = await api.get(
        '/ProdutoCategoria/get-categorias-filhas-normalizado',
      );
      setCategoriasFilhas(data.value);
      setCategoriasProdutos(data.value);

      const familias = await api.get('/ProdutoCategoria/get-familias-normalizado');
      setFamilias(familias.data.value);
      setFamiliasFiltradas(familias.data.value);
    })();
  }, []);

  return (
    <div className="filter-body">
      <div className="titulo">FILTROS</div>
      <div className="body">
        <div className="input">
          <label>Nome ou Código</label>
          <input
            type="text"
            className="form-control"
            onChange={onChangeTexto}
            value={texto}
          />
        </div>

        <hr className="default"></hr>

        <div className="filters">
          <label type="button" className="btn btn-block" id="itensCat">
            Categoria Imagem{' '}
            <FontAwesomeIcon
              className="float-right"
              style={{ marginLeft: '10px' }}
              icon={faPlus}
            ></FontAwesomeIcon>
          </label>
          <UncontrolledCollapse toggler="#itensCat">
            <div>
              {categorias.map((categoria) => (
                <div key={categoria.bancoImagemCategoriaId}>
                  <label style={{ fontSize: '14px' }}>
                    <input
                      onChange={onChangeCategorias}
                      type="checkbox"
                      value={categoria.bancoImagemCategoriaId}
                    />{' '}
                    {categoria.nome}
                  </label>
                </div>
              ))}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default"></hr>

        <div className="filters">
          <label type="button" className="btn btn-block" id="itensMarca">
            Categoria{' '}
            <FontAwesomeIcon
              className="float-right"
              icon={faPlus}
            ></FontAwesomeIcon>
          </label>
          <UncontrolledCollapse toggler="#itensMarca">
            <div>
              {marcas.map((marca) => {
                return (
                  <div key={marca.produtoCategoriaId}>
                    <input
                      onChange={onChangeMarcas}
                      value={marca.produtoCategoriaId}
                      type="checkbox"
                    />{' '}
                    {marca.nome}
                  </div>
                );
              })}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default"></hr>
        <div className="filters">
          <label type="button" className="btn btn-block" id="itensProd">
            Line{' '}
            <FontAwesomeIcon
              className="float-right"
              icon={faPlus}
            ></FontAwesomeIcon>
          </label>

          <UncontrolledCollapse toggler="#itensProd">
            <div>
              {categoriasProdutos.map((item) => (
                <div key={`${item.id}-${item.nome}`}>
                  <label style={{ fontSize: '14px' }}>
                    <input
                      onChange={onChangeCategoriasProdutos}
                      type="checkbox"
                      value={item.nomeNormalizado}
                    />{' '}
                    {item.nome}
                  </label>
                </div>
              ))}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default"></hr>
        <div className="filters">
          <label type="button" className="btn btn-block" id="itensFamilia">
            Family{' '}
            <FontAwesomeIcon
              className="float-right"
              icon={faPlus}
            ></FontAwesomeIcon>
          </label>

          <UncontrolledCollapse toggler="#itensFamilia">
            <div>
              {familias.map((item) => (
                <div key={`${item.id}-${item.nome}`}>
                  <label style={{ fontSize: '14px' }}>
                    <input
                      onChange={onChangeFamilia}
                      type="checkbox"
                      value={item.nomeNormalizado}
                    />{' '}
                    {item.nome}
                  </label>
                </div>
              ))}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default"></hr>

        <div className="buttons">
          <FormGroup className="row">
            <div className=" col-lg-12">
              <Button
                onClick={filterImages}
                className="query-button"
                variant="primary"
              >
                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon> Filtrar
              </Button>
              <Button
                onClick={clearFilter}
                className="query-button"
                variant="outline-primary"
              >
                <FontAwesomeIcon icon={faEraser}></FontAwesomeIcon> Limpar
              </Button>
            </div>
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default Filter;
