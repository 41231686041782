import React, { useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import './styles.scss';
import TitlePages from '../../../../atoms/TitlePages';
import SubHeader from '../../../SubHeader';
import { tabs, entregaTabs, getCampoObrigatorioClassName } from '../../_constants';


import InputTelefone from '../../../../atoms/InputTelefone';

const EntregaDigital = (props) => {
  let model = props.model;
  let entities = props.entities;
  let setModel = props.setModel;
  let errors = props.errors;
  let renderErrors = props.renderErrors;

  let avancar = props.avancar;
  let voltar = props.voltar;

  model.entregaDigital = model.entregaDigital || {};
  const [entregaDigital, setEntregaDigital] = useState(model.entregaDigital || {});

  const updateEntregaDigital = (obj) => {
    model.entregaDigital = { ...obj };
    setEntregaDigital(model.entregaDigital);
  }

  useEffect(() => {
    setEntregaDigital(model.entregaDigital || {})
  }, [model]);

  const entregaTipoDigital =
    (entities.tipoEntregas || []).find((tipoEntrega) => {
      return tipoEntrega.entregaDigital;
    });

  const avancarSolicitacoes = () => {
    model.solicitacoes.forEach((solicitacao) => {
      if (!solicitacao.imprimir)
        solicitacao.entregaTipoId = entregaTipoDigital.entregaTipoId;
    })

    avancar();
  }

  const renderFields = () => {
    if (!model.entregaDigital)
      return null;

    return (
      <div className="row mb-5">
        <div className="col-lg-12 mb-3">
          <div>
            <label>Nome</label>
            <input type="text"
              className={`form-control ${getCampoObrigatorioClassName(entregaDigital.nome, true)}`}
              value={entregaDigital.nome || ""}
              onChange={(event) => {
                entregaDigital.nome = event.target.value;
                updateEntregaDigital(entregaDigital);
              }}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div>
            <label>Telefone</label>
            <InputTelefone 
              className={`${getCampoObrigatorioClassName(entregaDigital.telefone, true)}`}
              value={entregaDigital.telefone || ""} onChange={(value) => {
              entregaDigital.telefone = value;
              updateEntregaDigital(entregaDigital);
            }} />
          </div>
        </div>

        <div className="col-lg-6">
          <div>
            <label>E-mail</label>
            <input type="text" 
              className={`form-control ${getCampoObrigatorioClassName(entregaDigital.email, true)}`} 
              value={entregaDigital.email || ""}
              onChange={(event) => {
                entregaDigital.email = event.target.value;
                updateEntregaDigital(entregaDigital);
              }}
            />
          </div>
        </div>
      </div>

    )
  }

  return (
    <div className="wrapper-content-pedido-entrega-digital">

      <SubHeader title={model.loja?.razaoSocial} />

      <TitlePages title="Entrega de Materiais Digitais" />

      <div className="content-pedido-entrega-digital">

        {renderErrors(errors)}

        <div className="row m-5">
          <div className="col-lg-12 text-center">
            <h4>Você selecionou materiais digitais e/ou sem impressão.<br />Preencha os dados para envio da Arte Final.</h4>
          </div>
        </div>

        {renderFields()}

        <div className="row buttons">
          <div className="col ">
            <button className="btn btn-primary btn-navigation" onClick={() => voltar()} >
              <span><FaAngleLeft size={26} /></span>
              <span>VOLTAR</span>
            </button>
          </div>

          <div className="col  flex-row-reverse">
            <button className="btn btn-primary btn-navigation btn-navigation-right" onClick={() => avancarSolicitacoes()}>
              <span>AVANÇAR</span>
              <span><FaAngleRight size={26} /></span>
            </button>
          </div>

          {/* <pre className="text-left">{JSON.stringify(tipoMateriais, null, 2)}</pre> */}

        </div>
      </div>
    </div>

  );
}

export default EntregaDigital;
