import { interactionSettingsStore } from '@fullcalendar/core';

function dateFormat(date, showTime) {
  if (date == null || date == undefined || date == '') return null;

  let newDate = new Date(date);
  let ye = new Intl.DateTimeFormat('pt', { year: 'numeric' }).format(newDate);
  let mo = new Intl.DateTimeFormat('pt', { month: 'numeric' }).format(newDate);
  if (mo.length == 1) mo = '0' + mo;

  let da = new Intl.DateTimeFormat('pt', { day: '2-digit' }).format(newDate);
  if (da.length == 1) da = '0' + da;

  let ho = new Intl.DateTimeFormat('pt', { hour: '2-digit' }).format(newDate);
  if (ho.length == 1) ho = '0' + ho;

  let mi = new Intl.DateTimeFormat('pt', { minute: '2-digit' }).format(newDate);
  if (mi.length == 1) mi = '0' + mi;

  if (showTime) return `${da}/${mo}/${ye} ${ho}:${mi}`;

  return `${da}/${mo}/${ye}`;
}

function getDate(date) {
  if (date != null && date != undefined && date != 'null' && date != '') {
    const dateVariable = CsDateStrToJsDate(date);
    return dateVariable;
  }

  return '';
}

function JsDateToCsDateStr(date) {
  if (date == null || date == undefined) return null;

  let ye = date.getFullYear().toString();

  let mo = (date.getMonth() + 1).toString();
  if (mo.length == 1) mo = '0' + mo;

  let da = date.getDate().toString();
  if (da.length == 1) da = '0' + da;

  let ho = date.getHours().toString();
  if (ho.length == 1) ho = '0' + ho;

  let mi = date.getMinutes().toString();
  if (mi.length == 1) mi = '0' + mi;

  return `${ye}-${mo}-${da}T${ho}:${mi}`;
}

function CsDateStrToJsDate(date) {
  let dateTime = date.split(' ');
  let newDate = dateTime[0].split('/');
  let da = parseInt(newDate[0]);
  let mo = parseInt(newDate[1]) - 1;
  let ye = parseInt(newDate[2]);

  if (dateTime.length == 1) {
    return new Date(ye, mo, da, 0, 0, 0);
  }

  let time = dateTime[1].split(':');
  let ho = parseInt(time[0]);
  let mi = parseInt(time[1]);

  return new Date(ye, mo, da, ho, mi, 0);
}

export { dateFormat, getDate, JsDateToCsDateStr, CsDateStrToJsDate };
