import React from 'react';

import './styles.scss';

import HeaderApp from '../../../organisms/Header/HeaderApp';
import Bottom from '../../../organisms/Bottom';
import ContentSolicitacaoFluxoAprovarArte from '../../../organisms/ContentSolicitacaoFluxoAprovarArte';

const SolicitacaoAprovarArte = () => {
  return (
    <>
      <HeaderApp/>
        <ContentSolicitacaoFluxoAprovarArte/>
      <Bottom />
    </>
  );
};

export default SolicitacaoAprovarArte;
