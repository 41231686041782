import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../PageTitle';

import './styles.scss';

const TitlePages = (props) => {
  return (
    <div className="title-pages">
      <PageTitle title={props.title}></PageTitle>
      <hr></hr>
      {/*<div className="text-center">
        <FontAwesomeIcon icon={ faQuestionCircle }/>&nbsp;
        Se tiver dúvidas para preencher essa tela, CLIQUE AQUI.
  </div>*/}
    </div>
  );
};

export default TitlePages;
