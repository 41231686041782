import React, { useState, useContext, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import history from '../../../Routes/history';

import './styles.scss';
import api from '../../../api';
import { Context } from '../../../Context/AuthContext';
import TitlePages from '../../atoms/TitlePages';
import Summary from '../../molecules/Summary';
import ModalSummary from '../../molecules/ModalSummary';
import ModalComment from './ModalComment';
import SolicitacaoArquivos from './SolicitacaoArquivos';
import Historico from './Historico';

const ContentSolicitacaoFluxoStatus = (props) => {
  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0,
  );
  const [model, setModel] = useState({});
  const [showModalComment, setShowModalComment] = useState(false);
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [etapaDestinoId, setEtapaDestinoId] = useState(0);
  const [resultModel, setResultModel] = useState({});

  useEffect(() => {
    (async () => {
      showLoader(true);

      const {
        data: { value: responseModel },
      } = await api.get(
        '/SolicitacaoFluxo/get-status?solicitacaoId=' + solicitacaoId,
      );

      if (
        responseModel.etapaViewEspecifica &&
        responseModel.etapaViewEspecifica.length > 0
      ) {
        history.push(
          '/solicitacao-fluxo/' +
            responseModel.etapaViewEspecifica +
            '?solicitacaoId=' +
            solicitacaoId,
        );
        return;
      }

      setModel(responseModel);

      showLoader(false);
    })();
  }, []);

  function usuarioPodeAlterarSolicitacao() {
    return (
      model.usuarioComPendencia &&
      model.solicitacaoSituacao &&
      model.solicitacaoSituacao === 'R'
    );
  }

  function renderResumoButton() {
    // if (usuarioPodeAlterarSolicitacao()) {
    //   return null;
    // }

    if (model.solicitacaoSituacao && model.solicitacaoSituacao === 'A')
      return (
        <div className="text-left">
          <button
            className="btn btn-primary"
            onClick={() => {
              setResumoSolicitacaoId(solicitacaoId);
            }}
          >
            Ver Solicitação
          </button>
        </div>
      );

    return null;
  }

  function renderSummary() {
    // if (usuarioPodeAlterarSolicitacao()) {
    //   return null;
    // }

    if (model.solicitacaoSituacao && model.solicitacaoSituacao === 'A')
      return null;

    return (
      <>
        <hr />
        <Summary solicitacaoId={model.solicitacaoId} />
        <hr />
      </>
    );
  }

  function renderArquivos() {
    if (!model.solicitacaoId || model.solicitacaoId === 0)
      return null;

    return (
      <SolicitacaoArquivos solicitacaoId={model.solicitacaoId} />
    )
  }

  function renderButtonAlterarSolicitacao() {
    if (usuarioPodeAlterarSolicitacao()) {
      return (
        <div className="text-left">
          <Link to={`/alterar-solicitacao/${model.solicitacaoId}`}>
            <button className="btn btn-primary">Alterar Solicitação</button>
          </Link>
        </div>
      );

      return null;
    }
  }

  function renderHistory() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    );
  }

  function renderButtons() {
    if (!model.etapaButtons || model.etapaButtons.length === 0) return null;

    return (
      <div className="text-center">
        {model.etapaButtons.map((etapaButton, index) => {
          return (
            <button
              key={index}
              className={'m-2 btn btn-primary btn-' + (etapaButton.tipo || '')}
              onClick={() => buttonAction(etapaButton)}
            >
              {etapaButton.texto}
            </button>
          );
        })}
      </div>
    );
  }

  function buttonAction(etapaButton) {
    if (etapaButton.observacaoObrigatorio) {
      setEtapaDestinoId(etapaButton.etapaDestinoId);
      setShowModalComment(true);
    } else {
      salvar(etapaButton.etapaDestinoId);
    }
  }

  function renderModalComment() {
    if (!showModalComment) return null;

    return (
      <ModalComment
        show={true}
        solicitacaoId={model.solicitacaoId}
        enviarObservacao={enviarObservacao}
        cancelarObservacao={cancelarObservacao}
      />
    );
  }

  function renderModalSummary() {
    if (resumoSolicitacaoId === 0) return null;

    return (
      <ModalSummary
        solicitacaoId={resumoSolicitacaoId}
        onClose={() => {
          setResumoSolicitacaoId(0);
        }}
      />
    );
  }

  function enviarObservacao(observacao, urlAnexoObservacao) {
    salvar(etapaDestinoId, observacao, urlAnexoObservacao);
    setShowModalComment(false);
  }

  function cancelarObservacao() {
    setShowModalComment(false);
  }

  function salvar(etapaId, observacao, urlAnexoObservacao) {
    showLoader(true);

    var postModel = {
      solicitacaoId: solicitacaoId,
      etapaDestinoId: etapaId,
      observacao: observacao,
      urlAnexoObservacao: urlAnexoObservacao
    };

    (async () => {
      const { data: response } = await api.post(
        '/SolicitacaoFluxo/update-status',
        postModel,
      );

      //value == true => alterado com sucesso
      if (response.value) {
        setModel({});
      }

      setResultModel(response);

      showLoader(false);
    })();
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <span key={index}>{error}</span>;
        })}
      </div>
    );
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  return (
    <div className="wrapper-content-solicitacaofluxo">
      <TitlePages
        title={
          'Solicitação ' +
          solicitacaoId +
          (model.header ? ' - ' + model.header : '')
        }
      />

      <div className="content-solicitacaofluxo">
        {/* {<pre>{JSON.stringify(resultModel, null, 2)}</pre>} */}

        {renderErrors()}

        {renderSuccess()}

        {renderResumoButton()}

        {renderArquivos()}

        {renderButtons()}

        {renderButtonAlterarSolicitacao()}

        {renderSummary()}

        {renderButtonAlterarSolicitacao()}

        {renderHistory()}

        {renderModalComment()}

        {renderModalSummary()}

        {/* {<pre className="text-left">{JSON.stringify(model, null, 2)}</pre>} */}
      </div>
    </div>
  );
};

export default ContentSolicitacaoFluxoStatus;
