import React, { useState, useContext } from 'react';
import { getStringFromDate } from '../_constants';
import { Link } from 'react-router-dom';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';

import { Context } from '../../../../Context/AuthContext';
import './styles.scss';

function Sucesso(props) {
  let { model, enviarSolicitacao, entities } = props;
  const { showLoader } = useContext(Context);
  const [ico, setIcon] = useState(false);

  let segmento = (entities.segmentos || []).find((x) => {
    return x.segmentoId === model?.segmentoId;
  }) || {};

  let bandeira = model?.bandeira || "";

  let regional = (entities.regionais || []).find((x) => {
    return x.regionalId === model?.regionalId;
  }) || {};

  const mudarIcon = () => {
    if (ico === false) {
      setIcon(true);
    } else {
      setIcon(false);
    }
  };

  const resumoItem = (item, model) => {
    return (
      <div className="col-lg-12 resumo mb-4" key={item.materialComunicacaoId}>
        <div className="resumo-header">
          <Accordion.Toggle
            onClick={mudarIcon}
            as={Card.Header}
            eventKey={item.materialComunicacaoId}
          >
            {item.solicitacaoId} - {item.materialComunicacao.nome}
            <div className="float-right">
              <FontAwesomeIcon
                id="icone"
                icon={ico !== true ? faAngleDown : faAngleUp}
              ></FontAwesomeIcon>
            </div>
          </Accordion.Toggle>
        </div>
        <Accordion.Collapse eventKey={item.materialComunicacaoId}>
          <div className="resumo-body">
            <div className="resumo-informacoes">
              <h4>Informações</h4>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Solicitante</th>
                    <td>{model?.solicitante?.nome}</td>
                  </tr>
                  <tr>
                    <th>Regional</th>
                    <td>{regional?.nome}</td>
                  </tr>
                  <tr>
                    <th>Cliente</th>
                    <td>{model.loja?.razaoSocial}</td>
                  </tr>
                  <tr>
                    <th>Bandeira</th>
                    <td>{model.bandeira}</td>
                  </tr>
                  <tr>
                    <th>Segmento</th>
                    <td>{segmento.nome}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="resumo-informacoes">
              <h4>Layout</h4>
              <img
                src={model.solicitacoes[0].materialComunicacao.urlImagem}
                className="img-fluid"
              />
              <hr />
            </div>
            <div className="resumo-informacoes">
              <h4>Ação Promocional</h4>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Início da Vigência</th>
                    <td>
                      {getStringFromDate(
                        item.solicitacaoDados?.dataVeiculacaoInicio,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Fim da Vigência</th>
                    <td>
                      {getStringFromDate(
                        item?.solicitacaoDados?.dataVeiculacaoFim,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Incluir logo?</th>
                    <td>{item.solicitacaoDados?.possuiLogo ? 'Sim' : 'Não'}</td>
                  </tr>
                  <tr>
                    <th>Tamanhos</th>
                    <td>
                      {item.solicitacaoTamanhos.map(
                        (tamanho) => `${tamanho.altura}x${tamanho.largura} `,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Observação</th>
                    <td>{item.solicitacaoDados?.observacoesGerais}</td>
                  </tr>
                  <tr>
                    <th>Incluir Ação Promocional?</th>
                    <td>
                      {item.solicitacaoDados.possuiAcaoPromocional
                        ? 'Sim'
                        : 'Não'}
                    </td>
                  </tr>
                  {item.solicitacaoDados.possuiAcaoPromocional &&
                    item.solicitacaoAcaoPromocionais.length &&
                    item.solicitacaoAcaoPromocionais.map((acao, key) => (
                      <tr key={key}>
                        <th></th>
                        <td>
                          {acao.acaoPromocional.texto}
                          {'  '}
                          {acao.solicitacaoAcaoPromocionalValores.length
                            ? acao.solicitacaoAcaoPromocionalValores.map(
                                (valor) =>
                                  `${valor.acaoPromocionalCampo.texto}: ${
                                    valor.valor ? valor.valor : ''
                                  } `,
                              )
                            : ''}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="resumo-informacoes">
              <h4>Dados de Envio</h4>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Nome</th>
                    <td>{item.solicitacaoEntregaDigital?.nomeResponsavel}</td>
                  </tr>
                  <tr>
                    <th>Telefone</th>
                    <td>
                      {item.solicitacaoEntregaDigital?.telefoneResponsavel}
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{item.solicitacaoEntregaDigital?.emailResponsavel}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Accordion.Collapse>
      </div>
    );
  };

  return (
    <div className="container mt-5 content-pedido-resumo">
      <div className="row">
        <div className="col-lg-12">
          <h4 className="text-center mb-5">
            Suas solicitações já foram enviadas para aprovação e em seguida
            serão encaminhadas para o time de criação. <br />
            Você poderá acompanhá-las pelos números de solicitação abaixo
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Accordion>
            {model.solicitacoes &&
              model.solicitacoes.map((item) => resumoItem(item, model))}
          </Accordion>
        </div>
      </div>

      <div className="row">
        <div className="col-12 d-flex flex-row-reverse">
          <Link to="/">
            <button className="btn btn-primary btn-navigation btn-navigation-right">
              <span>Pagina Inicial</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sucesso;
