import React from 'react';

import { Context, userData } from '../../../../Context/AuthContext';
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFilter, faPlus, faRecycle, faTrash, faCheck, faBan, faUserCheck } from '@fortawesome/free-solid-svg-icons';

import api from '../../../../api';
import HeaderApp from '../../../organisms/Header/HeaderApp';
import Bottom from '../../../organisms/Bottom';
import PageTitle from '../../../atoms/PageTitle';
import ModalUsuarioAprovacao from '../ModalUsuarioAprovacao';

import "./styles.scss"

const ContentUsuarioLista = () => {
  const { showLoader, userData, setUserData } = React.useContext(Context);
  const [usuarioList, setUsuarioList] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const [usuarioId, setUsuarioId] = React.useState();

  const history = useHistory();

  //-------------------------------------------------------------------------------------------
  // Effects
  React.useEffect(() => {
    (async () => {
      showLoader(true);

      setUsuarioList(await getUsuariosAutoCadastrados());

      showLoader(false);
    })();
  }, []);

  //-------------------------------------------------------------------------------------------
  // API
  const getUsuariosAutoCadastrados = async () => {
    let result = await api.get('/CadastroUsuario/get-usuarios-autocadastrados');
    return result.data.value || [];
  }

  //-------------------------------------------------------------------------------------------
  // Render
  return (
    <div className="table-query">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Email</th>
              <th>Regional</th>
              <th>Função</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {
              usuarioList?.map((usuario, idx) => {
                return (
                  <tr key={idx} >
                    <td>{usuario?.nome}</td>
                    <td>{usuario?.cpf}</td>
                    <td>{usuario?.email}</td>
                    <td>{usuario?.regional?.nome}</td>
                    <td>{usuario?.usuarioTipo?.nome}</td>
                    <td className='action-bar'>
                      <button type="button"
                        className="btn btn-secondary btn-sm"
                        title='Avaliar Usuário'
                        onClick={() => {
                          setUsuarioId(usuario.usuarioId);
                          setShowModal(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faUserCheck} />
                      </button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      {/* Modal ------------------------------------------------------------------------------- */}
      <ModalUsuarioAprovacao
        usuarioId={usuarioId}
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={async () => { setUsuarioId(0); setUsuarioList(await getUsuariosAutoCadastrados());}}
      />
    </div>
  );
}

const UsuarioLista = () => {
  return (
    <>
      <HeaderApp />
      <PageTitle title="Lista de Usuários Auto-Cadastrados" />
      <hr></hr>
      <ContentUsuarioLista />
      <Bottom />
    </>
  );
}

export default UsuarioLista;
