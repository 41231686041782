import React from 'react';

import './styles.scss';
import BrandWhite from '../../../assets/brf/imgs/LOGO_Havaianas_Branco.png';

const Brand = (props) => {
  return (
    <img className="brand" src={ props.white ? BrandWhite : '' } alt="Havaianas"/>
  );
}

export default Brand;
