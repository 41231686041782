import React from 'react';

import './styles.scss';
import HeaderApp from '../../organisms/Header/HeaderApp';
import Bottom from '../../organisms/Bottom';
import ContentDeliveryMaterials from '../../organisms/ContentDeliveryMaterials';

const DeliveryMaterials = () => {
  return (
    <>
      <HeaderApp />
      <ContentDeliveryMaterials />
      <Bottom />
    </>
  );
};

export default DeliveryMaterials;
