import React from 'react';

import Header from '../../organisms/Header/HeaderApp';
import ContentCalledSent from '../../organisms/ContentCalledSent';
import Bottom from '../../organisms/Bottom';

const CalledSent = () => {
  return (
    <>
      <Header/>
      <ContentCalledSent />
      <Bottom/>
    </>
  );
}

export default CalledSent;