import React from 'react';

import './styles.scss';

import TitlePages from '../../atoms/TitlePages';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';



const ContentCalledSent = (props) => {
  return (
    <div className="wrapper-chamado-finalizado">
      <TitlePages title="ATENDIMENTO" />
      <div className='contents '>
        <div className='finalizado col-lg-8 '>
          <span>Chamado finalizado com sucesso!</span>  
          <small>Seu chamado foi resolvido e finalizado no sistema.</small>
          <Button className='btnVoltar ' href='/atendimento'><FontAwesomeIcon className='icon' icon={faAngleLeft}></FontAwesomeIcon>VOLTAR</Button>
        </div>
      </div>
    </div>
  );
}

export default ContentCalledSent;
