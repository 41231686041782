import React, { useState } from 'react';
import TablePagination from '../../../molecules/TablePagination';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons';
import { dateFormat } from '../../../../helpers/Date.Helper';
import ModalDiscard from '../ModalDiscard';
import history from '../../../../Routes/history';
import actionLoader from '../../../../store/modules/loader/actions';
import { useDispatch } from 'react-redux';
import api from '../../../../api';
import './styles.scss';

const TableQuery = (props) => {
  const model = props.model;
  const [rascunhoId, setRascunhoId] = useState(0);
  const dispatch = useDispatch();

  const handleDiscard = async () => {
    dispatch(actionLoader.showLoader(true));
    const {
      data: { value },
    } = await api.get('/rascunho/delete-rascunho/' + rascunhoId);

    if (value === true) {
      props.onRefresh();
    }

    dispatch(actionLoader.showLoader(false));

    handleClose();
  };

  const handleClose = async () => {
    setRascunhoId(0);
  };

  const handleContinue = async (id) => {
    history.push('/pedido-materiais/' + id);
  };

  const handleContinueArteFacil = async (id) => {
    history.push('/artes-facil/' + id);
  };

  return (
    <div className="table-rascunho">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nro</th>
              <th>Tipo Solicitação</th>
              <th>Cliente</th>
              <th>Materiais</th>
              <th>Criado</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {model.list.map((item, idx) => {
              const json = JSON.parse(item.jsonModel);
              const materiais = (
                json.solicitacoes?.map(function (elem) {
                  return elem.materialComunicacao?.nome;
                }) || []
              ).join(', ');
              return (
                <tr key={idx}>

                  {/* ID Rascunho ------------------------------------------------------------- */}
                  <td>{item.rascunhoId}</td>

                  {/* Tipo Solicitacao -------------------------------------------------------- */}
                  <td>
                    {json.pedidoTipoId === 3
                      ? 'Artes fácil'
                      : json.pedidoDeCampanha === false
                      ? 'Materiais'
                      : 'Campanha'}
                  </td>

                  {/* Razao social ------------------------------------------------------------ */}
                  <td>{item.loja ? item.loja.razaoSocial : ''}</td>

                  {/* Material ---------------------------------------------------------------- */}
                  <td>
                    {materiais.length > 40
                      ? materiais.substring(0, 40) + '...'
                      : materiais}
                  </td>

                  {/* Data Criacao ------------------------------------------------------------ */}
                  <td>{dateFormat(item.dataCriacao, true)}</td>

                  {/* Botao Continuar --------------------------------------------------------- */}
                  <td className="text-center">
                    <Button
                      className="table-button"
                      variant={'primary'}
                      onClick={() =>
                        json.pedidoTipoId === 3
                          ? handleContinueArteFacil(item.rascunhoId)
                          : handleContinue(item.rascunhoId)
                      }
                    >
                      <FontAwesomeIcon icon={faPen}></FontAwesomeIcon> Continuar
                    </Button>
                  </td>

                  {/* Botao Descartar --------------------------------------------------------- */}
                  <td className="text-center">
                    <Button
                      className="table-button"
                      variant={'danger'}
                      onClick={() => setRascunhoId(item.rascunhoId)}
                    >
                      <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>{' '}
                      Descartar
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageData={props.model.pageData}
        handlePageChange={props.handlePageChange}
      />
      <ModalDiscard
        rascunhoId={rascunhoId}
        onClose={handleClose}
        onDiscard={handleDiscard}
      />
    </div>
  );
};

export default TableQuery;
