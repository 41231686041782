import React from 'react';
import Select, { components } from 'react-select';

const groupStyles = {
  fontWeight: 'bold',
  fontSize: '23px',
  color: '#bbb',
  display: 'flex',
  marginLeft: '-7px',
  padding: '5px 0 2px 0',
};

const GroupHeading = (props) => (
  <div style={groupStyles}>
    <components.GroupHeading {...props} />
  </div>
);

export const MultiSelect = (props) => (
  <Select
    isMulti
    closeMenuOnSelect={false}
    options={props.options}
    value={setMultiSelectedValue(props.options, props.value)}
    onChange={(arrayOptions) =>
      props.onChange(
        arrayOptions == null
          ? []
          : arrayOptions.map(
              (option) => parseInt(option.value) || option.value,
            ),
        props.name,
      )
    }
    placeholder="Selecione..."
    components={{ GroupHeading }}
    styles={{
      groupHeading: (base) => ({
        ...base,
      }),
    }}
  />
);

const setMultiSelectedValue = function (selectList, values) {
  if (!selectList || selectList.length === 0) return false;

  if (!values || values.length === 0) return false;

  var selectedValues = [];

  for (let i = 0; i < selectList.length; i++) {
    var el = selectList[i];

    for (let j = 0; j < values.length; j++) {
      var value = values[j];

      if (el.value && el.value == value) {
        selectedValues.push(el);
      }
    }

    if (el.options && el.options.length > 0) {
      var childSelectedValues = setMultiSelectedValue(el.options, values);

      if (childSelectedValues)
        selectedValues = selectedValues.concat(childSelectedValues);
    }
  }
  return selectedValues;
};

export default MultiSelect;
