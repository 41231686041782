import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Badge, Row, Col } from 'react-bootstrap';
import { Button, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles.scss';
import api from '../../../api';
import { Context } from '../../../Context/AuthContext';
import TitlePages from '../../atoms/TitlePages';
import SingleSelect from '../../atoms/SelectSingle';
import history from '../../../Routes/history';
import ContentPendencias from '../../organisms/ContentPendencias';
import ContentDemandas from '../../organisms/ContentDemandas';

import {
  faHome,
  faFile,
  faFileAlt,
  faExclamationTriangle,
  faSearch,
  faImage,
  faFileImage,
  faFileExcel,
  faAt,
  faUser,
  faSignOutAlt,
  faQuestionCircle,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';

// TO DO
// Adicionar o link para auxiliar a preencher a página no CLIQUE AQUI.
// Remover importações que não está usando na página.

const ContentPendenciaDemanda = () => {
  const [activeButtom, setActiveButtom] = useState('pendencias');
  const { showLoader } = useContext(Context);
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));
  const [usuario, setUsuario] = useState({});
  const [demandaDisabled, setDemandaDisanabled] = useState(true);

  useEffect(() => {
    (async () => {
      showLoader(true);

      const { data: { value } } = await api.get('/Usuario/get',
        {
          params: { id: userAuth.userId }
        });
      setUsuario({
        usuarioId: value.usuarioId,
        nome: value.nome,
        email: value.email,
        telefone: value.telefone,
        regionalId: value.regionalId,
        usuarioTipoSigla: value.usuarioTipo?.sigla
      });
      if (value.usuarioTipo.sigla !== "DIAG") {
        setDemandaDisanabled(true);
      }else{
        setDemandaDisanabled(false);
      }
      showLoader(false);
    })()
  }, []);

  return (
    <div className="content-pendencia-demenda">
      {demandaDisabled &&
        <TitlePages title="Pendências" />}
      {!demandaDisabled &&
        <div className="Selector">
          <Row>

            <Col>
              {activeButtom === 'pendencias' ? <Button size="lg" variant="primary" block>PENDÊNCIAS</Button>
                : <Button size="lg" variant="outline-primary" onClick={() => { setActiveButtom('pendencias') }} block>PENDÊNCIAS</Button>}
            </Col>

            <Col>
              {activeButtom === 'demandas' ? <Button size="lg" variant="primary" block disabled={demandaDisabled}>DEMANDAS</Button>
                : <Button size="lg" variant="outline-primary" onClick={() => { setActiveButtom('demandas') }} block disabled={demandaDisabled}>DEMANDAS</Button>}
            </Col>

          </Row>
          <hr />
        </div>
      }
      {/*<div className="text-center demanda-help-link">
        <span>
          <FontAwesomeIcon icon={faQuestionCircle} />
          &nbsp; Se tiver dúvidas para preencher essa página, <a href="#">CLIQUE AQUI</a>
        </span>
    </div>*/}
      {activeButtom === 'pendencias' ?
        <ContentPendencias />
        : <ContentDemandas />
      }
    </div>
  );
}

export default ContentPendenciaDemanda;
