import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, } from 'react-icons/fa';
import { Accordion, Card, Button } from 'react-bootstrap'

import './styles.scss';

import TitlePages from '../../../atoms/TitlePages';
import SubHeader from '../../SubHeader';

import { tabs } from './../_constants';

import ResumoMaterial from '../Resumo/ResumoMaterial';

const Sucesso = (props) => {
  let model = props.model;
  let pedidoCriado = props.pedidoCriado;
  let entities = props.entities;

  return (
    <div className="wrapper-content-pedido-resumo">

      <SubHeader title={model.loja?.razaoSocial} />

      <TitlePages title="Solicitações criadas com sucesso" />

      <div className="content-pedido-resumo">

        <div className="row">
          <div className="col-lg-12">
            <h4 className="text-center mb-5">
              Suas solicitações já foram enviadas para aprovação e em seguida serão encaminhadas para o time de criação. <br />
            Você poderá acompanhá-las pelos números de solicitação abaixo
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <Accordion>
              {(model.solicitacoes || []).map((solicitacao, index) => {
                return (
                  <ResumoMaterial
                    key={index}
                    pedido={model}
                    solicitacao={solicitacao}
                    loja={model.loja}
                    entregaDigital={model.entregaDigital}
                    entregaCentroDistribuicao={model.entregaCentroDistribuicao}
                    entregaLojasParticipantes={model.entregaLojasParticipantes}
                    entregaOutro={model.entregaOutro}
                    entities={entities}
                    pedidoCriado={pedidoCriado}
                    index={index}
                  />
                )
              })}
            </Accordion>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex flex-row-reverse">
            {/* <button className="btn btn-primary btn-navigation btn-navigation-right" onClick={() => { }}>
              <span>Pagina Inicial</span>
            </button> */}

            <Link to="/">
              <button className="btn btn-primary btn-navigation btn-navigation-right">
                <span>Pagina Inicial</span>
              </button>
            </Link>
          </div>

          {/* <pre className="text-left">{JSON.stringify(tipoMateriais, null, 2)}</pre> */}

        </div>
      </div>
    </div >

  );
}

export default Sucesso;
