import { useEffect, useState } from 'react';

import api from '../../../api';

const Entities = props => {
  const { setEntities } = props;
  const { pedido } = props;
  const { solicitacao } = props;
  const { setLoader } = props;

  const [primaryLoader, setPrimaryLoader] = useState(false);
  const [secondaryLoader, setSecondaryLoader] = useState(false);


  const [lojaId, setLojaId] = useState(0);
  const [tipoMateriais, setTipoMateriais] = useState([]);
  const [tipoEntregas, setTipoEntregas] = useState([]);
  const [campanhas, setCampanhas] = useState([]);
  const [estados, setEstados] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [produtoCategorias, setProdutoCategorias] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [linguagens, setLinguagens] = useState([]);
  const [publicos, setPublicos] = useState([]);
  const [extensoes, setExtensoes] = useState([]);
  const [formaPagamentos, setFormaPagamentos] = useState([]);
  const [quantidadesImpressos, setQuantidadesImpressos] = useState([]);
  const [tempos, setTempos] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [regionais, setRegionais] = useState([]);

  const [enderecoFilial, setEnderecoFilial] = useState({});
  const [campanhaMateriais, setCampanhaMateriais] = useState([]);
  const [materiaisCadastroItens, setMateriaisCadastroItens] = useState([]);
  //const [clienteLayouts, setClienteLayouts] = useState([]);
  const [regionalLayouts, setRegionalLayouts] = useState([]);

  useEffect(() => {
    if (primaryLoader || secondaryLoader)
      setLoader(true);
    else
      setLoader(false);
  }, [primaryLoader, secondaryLoader])

  useEffect(() => {
    setEntities({
      tipoMateriais,
      tipoEntregas,
      campanhas,
      campanhaMateriais,
      estados,
      materiaisCadastroItens,
      unidades,
      produtos,
      produtoCategorias,
      //clienteLayouts,
      regionalLayouts,
      motivos,
      linguagens,
      publicos,
      extensoes,
      formaPagamentos,
      quantidadesImpressos,
      tempos,
      enderecoFilial,
      segmentos,
      regionais
    });
  }, [
    tipoMateriais,
    tipoEntregas,
    campanhas,
    campanhaMateriais,
    estados,
    materiaisCadastroItens,
    unidades,
    produtos,
    produtoCategorias,
    //clienteLayouts,
    regionalLayouts,
    motivos,
    linguagens,
    publicos,
    extensoes,
    formaPagamentos,
    quantidadesImpressos,
    tempos,
    enderecoFilial,
    segmentos,
    regionais
  ]);

  useEffect(() => {
    (async () => {
      setPrimaryLoader(true);

      Promise.all([

        getTipoEntregas(),
        getCampanhas(),
        getProdutosCategoriasUnidades(),
        getMotivos(),
        getLinguagens(),
        getPublicos(),
        getExtensoes(),
        getFormaPagamentos(),
        getEstados(),
        getQuantidadeImpressos(),
        getTempos(),
        getSegmentos(),
        getRegionais()

      ]).then(() => {

        setPrimaryLoader(false);

      })
    })();
  }, [])

  useEffect(() => {
    (async () => {

      Promise.all([

        getTipoMateriais(),
        getEnderecoFilial(),
        getMateriaisCadastroItens(),
        //getClienteLayouts(),
        getRegionalLayouts(),
        getCampanhaMateriais(),

      ]).then(() => {

        setSecondaryLoader(false);

      })
    })();
  }, [pedido, solicitacao])

  //tipoMateriais
  async function getTipoMateriais() {
    if (!pedido.loja?.lojaId)
      return;

    if (lojaId === 0 || lojaId !== pedido.loja.lojaId) {

      setSecondaryLoader(true);

      const result = await api.get(`/Solicitacao/list-tipo-materiais/${pedido.loja.lojaId}`,
      );

      if (result?.data?.value) {
        setLojaId(pedido.loja.lojaId);

        let tipoMateriais = result.data.value;
        setTipoMateriais(tipoMateriais);
      }

    }
  };

  //tipoEntregas
  async function getTipoEntregas() {
    if (!tipoEntregas.length) {
      const result = await api.get('/Solicitacao/list-tipo-entrega');

      if (result?.data?.value?.length) {
        let tipoEntregas = result.data.value;
        setTipoEntregas(tipoEntregas);
      }
    }
  };

  // Carregar os tipos de campanhas
  async function getCampanhas() {
    if (!campanhas.length) {
      const result = await api.get('/Solicitacao/get-campanhas');

      if (result?.data?.value?.length) {
        setCampanhas(result.data.value);
      }
    }
  };

  //produtos e categorias e unidades
  async function getProdutosCategoriasUnidades() {
    if (!produtos.length || !produtoCategorias.length || !unidades.length) {
      const result = await api.get('/Solicitacao/get-tab-produtos-required-values');

      if (result?.data?.value) {
        let produtos = result.data.value.produtos || [];
        let produtoCategorias = result.data.value.produtoCategorias || [];
        let unidades = result.data.value.unidades || [];

        setProdutos(produtos);
        setProdutoCategorias(produtoCategorias);
        setUnidades(unidades);
      }
    }
  };

  //motivos
  async function getMotivos() {
    if (!motivos.length) {
      const result = await api.get('/Solicitacao/list-motivo-acao');

      if (result?.data?.value?.length) {
        let motivos = result.data.value;
        setMotivos(motivos);
      }
    }
  };
  //linguagens
  async function getLinguagens() {
    if (!linguagens.length) {
      const result = await api.get('/Solicitacao/list-linguagem');

      if (result?.data?.value?.length) {
        let linguagens = result.data.value;
        setLinguagens(linguagens);
      }
    }
  };

  //Publico Alvo
  async function getPublicos() {
    if (!publicos.length) {
      const result = await api.get('/Solicitacao/list-publico-alvo');

      if (result?.data?.value?.length) {
        let publicos = result.data.value;
        setPublicos(publicos);
      }
    }
  };

  //extensoes
  async function getExtensoes() {
    if (!extensoes.length) {
      const result = await api.get('/Solicitacao/list-extensao-arquivos');

      if (result?.data?.value?.length) {
        let extensoes = result.data.value;
        setExtensoes(extensoes);
      }
    }
  };

  //formaPagamentos
  async function getFormaPagamentos() {
    if (!formaPagamentos.length) {
      const result = await api.get('/Solicitacao/list-formas-pagamentos');

      if (result?.data?.value?.length) {
        let formaPagamentos = result.data.value;
        setFormaPagamentos(formaPagamentos);
      }
    }
  };

  //estados
  async function getEstados() {
    if (!estados.length) {
      const result = await api.get('/Solicitacao/list-estados');

      if (result?.data?.value?.length) {
        let estados = result.data.value;
        setEstados(estados);
      }
    }
  };

  //quantidadesImpressos
  async function getQuantidadeImpressos() {
    if (!quantidadesImpressos.length) {
      const result = await api.get('/Solicitacao/list-quantidade-impressos');

      if (result?.data?.value?.length) {
        let quantidadesImpressos = result.data.value;
        setQuantidadesImpressos(quantidadesImpressos);
      }
    }
  };

  //tempos
  async function getTempos() {
    if (!tempos.length) {
      const result = await api.get('/Solicitacao/list-tempo-video');

      if (result?.data?.value?.length) {
        let tempos = result.data.value;
        setTempos(tempos);
      }
    }
  };

  //segmentos
  async function getSegmentos() {
    if (!segmentos.length) {
      const result = await api.get('/Solicitacao/list-segmento');

      if (result?.data?.value?.length) {
        let segmentos = result.data.value;
        setSegmentos(segmentos);
      }
    }
  };

  //regionais
  async function getRegionais() {
    if (!regionais.length) {
      const result = await api.get('/Solicitacao/list-regional');

      if (result?.data?.value?.length) {
        let regionais = result.data.value;
        setRegionais(regionais);
      }
    }
  };

  //enderecoFilial
  async function getEnderecoFilial() {
    if (!pedido.loja?.lojaId)
      return;

    if (!pedido.loja.enderecoFilial?.endereco) {

      setSecondaryLoader(true);

      const result = await api.get(
        `/Solicitacao/get-endereco-centro-distribuicao/${pedido.loja.lojaId}`,
      );

      if (result?.data?.value) {
        pedido.loja.enderecoFilial = result.data.value;
        setEnderecoFilial(pedido.loja.enderecoFilial);
      }

    }
  };

  //materiaisCadastroItens (sempre que houver mudança no pedido ocorre a verificação)
  //todo: talvez ja buscar todos os cadastroItens dos materiais
  async function getMateriaisCadastroItens() {
    let materialComunicacaoIds = [];

    if (solicitacao?.materialComunicacaoId) {
      materialComunicacaoIds = [solicitacao.materialComunicacaoId];
    } else {
      materialComunicacaoIds = (pedido?.solicitacoes || []).map(
        (solicitacao) => solicitacao.materialComunicacaoId
      );
    }

    if (materialComunicacaoIds.length === 0)
      return;

    if (
      materialComunicacaoIds.filter(
        (materialComunicacaoId) =>
          materiaisCadastroItens.findIndex(
            (x) => x.materialComunicacaoId === materialComunicacaoId,
          ) === -1,
      ).length > 0
    ) {

      let params = new URLSearchParams();
      materialComunicacaoIds.forEach((materialComunicacaoId) => {
        params.append('MaterialComunicacaoIds', materialComunicacaoId);
      });

      setSecondaryLoader(true);

      const result = await api.get('/Solicitacao/get-cadastro-itens', {
        params: params,
      });

      if (result?.data?.value) {
        let materiaisCadastroItens = result.data.value;
        setMateriaisCadastroItens(materiaisCadastroItens);
      }

    }
  };

  //regionalLayouts (sempre que ocorre um setModel verifica se a regional mudou para buscar novamente os layouts)
  async function getRegionalLayouts() {
    if (
      pedido.regionalId > 0 &&
      pedido.regionalId !== regionalLayouts.regionalId
    ) {

      setSecondaryLoader(true);

      const result = await api.get(
        '/Solicitacao/list-layout-artes-by-regional',
        {
          params: { regionalId: pedido.regionalId },
        },
      );

      if (result?.data?.value) {
        let regionalLayouts = result.data.value;
        setRegionalLayouts(regionalLayouts);
      }

    }
  };

  //clienteLayouts (sempre que ocorre um setModel verifica se o cliente mudou para buscar novamente os layouts)
  // async function getClienteLayouts() {
  //   if (
  //     pedido.loja?.lojaId > 0 &&
  //     pedido.loja.lojaId !== clienteLayouts.lojaId
  //   ) {

  //     setSecondaryLoader(true);

  //     const result = await api.get(
  //       '/Solicitacao/list-layout-artes-by-cliente',
  //       {
  //         params: { lojaId: pedido.loja.lojaId },
  //       },
  //     );

  //     if (result?.data?.value) {
  //       let clienteLayouts = result.data.value;
  //       setClienteLayouts(clienteLayouts);
  //     }

  //   }
  // };

  //campanhaMateriais (sempre que ocorre um setModel verifica se a campanha mudou)
  async function getCampanhaMateriais() {
    if (
      pedido.campanhaId > 0 && (!campanhaMateriais.length ||
        campanhaMateriais.findIndex(
          (campanhaMaterial) =>
            campanhaMaterial.campanhaId === pedido.campanhaId,
        ) === -1)
    ) {

      setSecondaryLoader(true);

      const result = await api.get('/Solicitacao/get-materiais-campanha', {
        params: {
          lojaId: pedido.loja.lojaId,
          campanhaId: pedido.campanhaId,
        },
      });

      if (result?.data?.value) {
        let campanhaMateriais = result.data.value;
        setCampanhaMateriais(campanhaMateriais);
      }

    }
  };

  return null;
}

export default Entities;
