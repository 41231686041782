import React from 'react';

import './styles.scss';

import HeaderApp from '../../../organisms/Header/HeaderApp';
import Bottom from '../../../organisms/Bottom';
import ContentSolicitacaoFluxoAprovarOrcamento from '../../../organisms/ContentSolicitacaoFluxoAprovarOrcamento';

const SolicitacaoFluxoAprovarOrcamento = () => {
  return (
    <>
      <HeaderApp/>
        <ContentSolicitacaoFluxoAprovarOrcamento/>
      <Bottom />
    </>
  );
};

export default SolicitacaoFluxoAprovarOrcamento;
