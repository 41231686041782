import React, { useEffect, useState, useContext } from 'react';
import { FaAngleLeft, FaAngleRight, FaSave } from 'react-icons/fa';
import Select, { components } from 'react-select';

import { Context } from '../../../../../../Context/AuthContext';

import api from '../../../../../../api';

import InputFileCustom from '../../../../../atoms/InputFile';

import InputTelefone from '../../../../../atoms/InputTelefone';

import InputInteger from '../../../../../atoms/InputInteger';

import DatePickerCustom from '../../../../../atoms/DatePicker';

import {
  tabs,
  solicitacaoTabs,
  cadastroItemKeys,
  unidadeMedidas,
  passos,
  getDateFromStringJson,
  getCampoObrigatorioClassName,
  entregaTipoNomes,
} from '../../../_constants';

import Tamanhos from './Tamanhos';
import RodapeEnderecos from './RodapeEnderecos';

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const Dados = (props) => {
  const { showLoader } = useContext(Context);

  let pedidoId = props.pedidoId;
  let loja = props.loja;

  let entregaCentroDistribuicao = props.entregaCentroDistribuicao;
  let entregaLojasParticipantes = props.entregaLojasParticipantes;
  let entregaOutro = props.entregaOutro;

  let entities = props.entities;
  let solicitacao = props.solicitacao;
  let cadastroItensTodos = props.cadastroItens;
  let avancar = props.avancar;
  let hideVoltar = props.hideVoltar;
  let voltar = props.voltar;
  let renderErrors = props.renderErrors;

  let errors = (solicitacao.errors || []).filter(
    (error) => error.passo === passos.passoDados.nro,
  );

  let tipoEntregas = entities.tipoEntregas;
  let motivos = entities.motivos;
  let linguagens = entities.linguagens;
  let publicos = entities.publicos;
  let extensoes = entities.extensoes;
  let formaPagamentos = entities.formaPagamentos;
  let tempos = entities.tempos;
  let quantidadesImpressos = entities.quantidadesImpressos;

  const [minFim, setMinFim] = useState('');
  const [minInicio, setMinInicio] = useState('');
  const [duracaoVigencia, setDuracaoVigencia] = useState('');

  const getEntregaTipo = () => {
    return (
      (tipoEntregas || []).find((tipoEntrega) => {
        return tipoEntrega.entregaTipoId === solicitacao.entregaTipoId;
      }) || {}
    );
  };

  const getMinData = () => {
    let minData = new Date();

    //entrega digital
    if (!solicitacao.imprimir) {
      if (material.diasInicioDigital > 0)
        return minData.addDays(material.diasInicioDigital);
    }
    //entrega fisica
    else {
      let entregaTipo = getEntregaTipo();

      var solicitacaoEnderecos = [];

      if (entregaTipo.nome === entregaTipoNomes.centroDistribuicao)
        solicitacaoEnderecos = [entregaCentroDistribuicao || {}];

      if (entregaTipo.nome === entregaTipoNomes.outro)
        solicitacaoEnderecos = [entregaOutro || {}];

      if (entregaTipo.nome === entregaTipoNomes.lojaLoja)
        solicitacaoEnderecos = [...(entregaLojasParticipantes || [])];

      console.log('solicitacaoEnderecos: ', solicitacaoEnderecos, material);

      if (solicitacaoEnderecos.length === 1) {
        //entrega capital
        if (solicitacaoEnderecos[0]?.cidade?.capital) {
          return minData.addDays(material.diasInicioCapital);
        }
        if (!solicitacaoEnderecos[0]?.cidade?.capital) {
          // entrega interior
          return minData.addDays(material.diasInicioInterior);
        }
      } else {
        let enderecoCapital = solicitacaoEnderecos.filter(
          (item) => item.cidade?.capital === true,
        );
        let enderecoInterior = solicitacaoEnderecos.filter(
          (item) => item.cidade?.capital === false,
        );

        if (enderecoCapital.length && enderecoInterior.length) {
          // Quando possui endereco capital e endereco interior
          return minData.addDays(material.diasInicioInterior);
        }
        if (enderecoCapital.length > 0) {
          // Quando só tiver endereco capital
          return minData.addDays(material.diasInicioCapital);
        }
        if (enderecoInterior.length > 0) {
          // Quando só tiver endereco interior
          return minData.addDays(material.diasInicioInterior);
        }
      }
    }
    return minData.addDays(material.diasInicio);
  };

  const [solicitacaoDados, setSolicitacaoDados] = useState(
    solicitacao.solicitacaoDados || {},
  );

  const [urlRodape, setUrlRodape] = useState(loja?.urlRodape || '');

  useEffect(() => {
    let minData = getMinData();
    let duracaoVigenciaNumero = material?.duracaoVigencia ?? 0;

    setMinFim(minData.addDays(duracaoVigenciaNumero));
    setMinInicio(minData);
    setDuracaoVigencia(duracaoVigenciaNumero);
  }, [entities]);

  let material =
    []
      .concat(
        ...entities.tipoMateriais.map(
          (tipoMaterial) => tipoMaterial.materiaisComunicacao,
        ),
      )
      .find(
        (m) => m.materialComunicacaoId === solicitacao.materialComunicacaoId,
      ) || {};
  let layoutArte =
    //(entities.clienteLayouts?.layoutArtes || []).find(
    (entities.regionalLayouts?.layoutArtes || []).find(
      (layout) =>
        layout.layoutArteId === solicitacao?.solicitacaoDados?.layoutArteId,
    ) || {};

  let selectListMotivos = motivos.map((motivo) => {
    return {
      value: motivo.motivoAcaoId,
      label: motivo.nome,
    };
  });

  let selectListLinguagens = linguagens.map((linguagem) => {
    return {
      value: linguagem.linguagemId,
      label: linguagem.nome,
    };
  });

  let selectListPublicos = publicos.map((publicoAlvo) => {
    return {
      value: publicoAlvo.publicoAlvoId,
      label: publicoAlvo.nome,
    };
  });

  let selectListExtensaoArquivos = extensoes.map((extensao) => {
    return {
      value: extensao.extensaoArquivoId,
      label: extensao.nome,
    };
  });

  let selectListTempos = tempos.map((tempo) => {
    return {
      value: tempo.tempoVideoId,
      label: tempo.descricao,
    };
  });

  let selectListQuantidadesImpressos = quantidadesImpressos.map((qtde) => {
    return {
      value: qtde,
      label: qtde,
    };
  });

  let unidadeMedida = material.unidadeMedida;

  //filtrar cadastro itens dependendo da entrega digital ou fisica
  let cadastroItens = (cadastroItensTodos || []).filter(
    (cadastroItem) =>
      (solicitacao.imprimir && cadastroItem.entregaFisica) ||
      (!solicitacao.imprimir && cadastroItem.entregaDigital),
  );
  // setQuantidadeLojasParticipantes();
  solicitacao.solicitacaoDados = solicitacao.solicitacaoDados || {};
  solicitacao.solicitacaoDados.quantidadeLojasParticipantes =
    solicitacao.solicitacaoDados.quantidadeLojasParticipantes > 0
      ? solicitacao.solicitacaoDados.quantidadeLojasParticipantes
      : (entregaLojasParticipantes || []).length;

  const updateSolicitacaoDados = (obj) => {
    let solicitacaoDados = { ...obj };

    solicitacao.solicitacaoDados = solicitacaoDados;
    setSolicitacaoDados(solicitacaoDados);
  };

  const uploadRodape = async (event) => {
    event.preventDefault();

    if (event.target.files.length === 0) return;

    // dispatch(LoaderAction.showLoader(true));
    const formData = new FormData();

    formData.append('lojaId', loja.lojaId);
    formData.append('file', event.target.files[0]);

    const {
      data: { value },
    } = await api.post('/Solicitacao/upload-rodape', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'WWW-Authenticate': `Bearer ${localStorage.getItem('midias_alpargatas') == null
          ? undefined
          : localStorage.getItem('midias_alpargatas').jwtToken
          }`,
      },
    });

    loja.urlRodape = value;
    setUrlRodape(loja.urlRodape);

    // dispatch(LoaderAction.showLoader(false));
  };

  const uploadAnexoObservacao = async (event) => {
    showLoader(true);
    event.preventDefault();

    if (event.target.files.length === 0) return;

    // dispatch(LoaderAction.showLoader(true));
    const formData = new FormData();

    formData.append('pedidoId', pedidoId);
    formData.append('file', event.target.files[0]);

    const {
      data: { value },
    } = await api.post('/Pedido/upload-file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'WWW-Authenticate': `Bearer ${localStorage.getItem('midias_alpargatas') == null
          ? undefined
          : localStorage.getItem('midias_alpargatas').jwtToken
          }`,
      },
    });

    solicitacaoDados.urlAnexoObservacoesGerais = value;
    updateSolicitacaoDados(solicitacaoDados);

    showLoader(false);
  };

  const veiculacao = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Veiculacao,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <label>Data de Início da Vigência do Material</label>
              <DatePickerCustom
                className={getCampoObrigatorioClassName(
                  solicitacaoDados.dataVeiculacaoInicio,
                  cadastroItem.obrigatorio,
                )}
                selected={
                  getDateFromStringJson(
                    solicitacaoDados.dataVeiculacaoInicio,
                  ) || ''
                }
                minDate={minInicio}
                onChange={(value) => {
                  solicitacaoDados.dataVeiculacaoInicio = value;

                  if (solicitacaoDados.dataVeiculacaoInicio) {
                    let minFim =
                      solicitacaoDados.dataVeiculacaoInicio.addDays(
                        duracaoVigencia,
                      );
                    if (minFim > solicitacaoDados.dataVeiculacaoFim)
                      solicitacaoDados.dataVeiculacaoFim = minFim;

                    setMinFim(minFim);
                  }

                  updateSolicitacaoDados(solicitacaoDados);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-4">
              <label>Data Final da Vigência do Materia</label>
              <DatePickerCustom
                className={getCampoObrigatorioClassName(
                  solicitacaoDados.dataVeiculacaoFim,
                  cadastroItem.obrigatorio,
                )}
                selected={
                  getDateFromStringJson(solicitacaoDados.dataVeiculacaoFim) ||
                  ''
                }
                minDate={minFim}
                onChange={(value) => {
                  solicitacaoDados.dataVeiculacaoFim = value;
                  updateSolicitacaoDados(solicitacaoDados);
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const recebimentoArte = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_DataEnvioArte,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6 mb-4">
          <label>Data para Envio da Arte</label>
          <DatePickerCustom
            className={getCampoObrigatorioClassName(
              solicitacaoDados.dataEnvioArte,
              cadastroItem.obrigatorio,
            )}
            selected={
              getDateFromStringJson(solicitacaoDados.dataEnvioArte) || ''
            }
            minDate={minInicio}
            onChange={(value) => {
              solicitacaoDados.dataEnvioArte = value;
              updateSolicitacaoDados(solicitacaoDados);
            }}
          />
        </div>
      );
    }

    return null;
  };

  const motivo = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Motivo,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <label>Motivo da Ação</label>
              <Select
                className={getCampoObrigatorioClassName(
                  solicitacaoDados.motivoAcaoId,
                  cadastroItem.obrigatorio,
                )}
                options={selectListMotivos}
                isClearable={true}
                onChange={(item) => {
                  solicitacaoDados.motivoAcaoId = item?.value;
                  solicitacaoDados.motivoAcao = motivos.find(
                    (motivo) =>
                      motivo.motivoAcaoId === solicitacaoDados.motivoAcaoId,
                  );
                  updateSolicitacaoDados(solicitacaoDados);
                }}
                value={selectListMotivos.find(
                  (item) => item.value === solicitacaoDados.motivoAcaoId,
                )}
              />
            </div>

            {motivoOutro()}
          </div>
        </div>
      );
    }

    return null;
  };

  const motivoOutro = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Motivo,
    );
    if (cadastroItem) {
      if (solicitacaoDados.motivoAcaoId > 0) {
        let motivoAcao =
          motivos.find(
            (item) => item.motivoAcaoId === solicitacaoDados.motivoAcaoId,
          ) || {};

        if (motivoAcao.habilitaDescricao) {
          return (
            <div className="col-lg-12 mb-4">
              <label>Outro Motivo</label>
              <input
                type="text"
                className={`form-control ${getCampoObrigatorioClassName(
                  solicitacaoDados.outroMotivo,
                  cadastroItem.obrigatorio,
                )}`}
                value={solicitacaoDados.outroMotivo || ''}
                onChange={(event) => {
                  solicitacaoDados.outroMotivo = event.target.value;
                  updateSolicitacaoDados(solicitacaoDados);
                }}
              />
            </div>
          );
        }
      }
    }

    return null;
  };

  const linguagem = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Linguagem,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <label>Idioma</label>
              <Select
                className={getCampoObrigatorioClassName(
                  solicitacaoDados.linguagemId,
                  cadastroItem.obrigatorio,
                )}
                options={selectListLinguagens}
                isClearable={true}
                onChange={(item) => {
                  solicitacaoDados.linguagemId = item?.value;
                  solicitacaoDados.linguagem = linguagens.find(
                    (linguagem) =>
                    linguagem.linguagemId === solicitacaoDados.linguagemId,
                  );
                  updateSolicitacaoDados(solicitacaoDados);
                }}
                value={selectListLinguagens.find(
                  (item) => item.value === solicitacaoDados.linguagemId,
                )}
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const publico = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_PublicoAlvo,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <label>Esta arte tem como público alvo clientes ou o nosso consumidor final?</label>
              <Select
                className={getCampoObrigatorioClassName(
                  solicitacaoDados.publicoAlvoId,
                  cadastroItem.obrigatorio,
                )}
                options={selectListPublicos}
                isClearable={true}
                onChange={(item) => {
                  solicitacaoDados.publicoAlvoId = item?.value;
                  solicitacaoDados.publicoAlvo = publicos.find(
                    (publicoAlvo) =>
                    publicoAlvo.publicoAlvoId === solicitacaoDados.publicoAlvoId,
                  );
                  updateSolicitacaoDados(solicitacaoDados);
                }}
                value={selectListPublicos.find(
                  (item) => item.value === solicitacaoDados.publicoAlvoId,
                )}
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const nomeFantasia = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_NomeFantasia,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6 mb-4">
          <label>Nome Fantasia</label>
          <input
            type="text"
            className={`form-control ${getCampoObrigatorioClassName(
              solicitacaoDados.nomeFantasia,
              cadastroItem.obrigatorio,
            )}`}
            value={solicitacaoDados.nomeFantasia || ''}
            onChange={(event) => {
              solicitacaoDados.nomeFantasia = event.target.value;
              updateSolicitacaoDados(solicitacaoDados);
            }}
          />
        </div>
      );
    }

    return null;
  };

  const quantidadeLojasParticipantes = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key ===
        cadastroItemKeys.Dados_QuantidadeLojasParticipantes,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6 mb-4">
          <label>Quantidade de Lojas Participantes</label>
          <input
            type="text"
            className={`form-control ${getCampoObrigatorioClassName(
              solicitacaoDados.quantidadeLojasParticipantes,
              cadastroItem.obrigatorio,
            )}`}
            value={solicitacaoDados.quantidadeLojasParticipantes || ''}
            onChange={(event) => {
              solicitacaoDados.quantidadeLojasParticipantes =
                event.target.value;
              updateSolicitacaoDados(solicitacaoDados);
            }}
          />
        </div>
      );
    }

    return null;
  };

  const quantidadeImpressosLoja = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_QuantidadeImpressosLoja,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6 mb-4">
          <label>Quantidade de Impressos por Loja</label>
          <Select
            className={getCampoObrigatorioClassName(
              solicitacaoDados.quantidadeImpressosLoja,
              cadastroItem.obrigatorio,
            )}
            options={selectListQuantidadesImpressos}
            isClearable={true}
            onChange={(item) => {
              solicitacaoDados.quantidadeImpressosLoja = item?.value;
              updateSolicitacaoDados(solicitacaoDados);
            }}
            value={selectListQuantidadesImpressos.find(
              (item) => item.value === solicitacaoDados.quantidadeImpressosLoja,
            )}
          />
        </div>
      );
    }

    return null;
  };

  const tamanhoUnico = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_Tamanho_Unico,
    );
    if (cadastroItem) {
      return (
        <Tamanhos
          solicitacao={solicitacao}
          material={material}
          updateSolicitacaoDados={updateSolicitacaoDados}
          tamanhoUnico={true}
          obrigatorio={cadastroItem.obrigatorio}
        />
      );
    }

    return null;
  };

  const tamanhosMulti = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_Tamanhos_Multi,
    );
    if (cadastroItem) {
      return (
        <Tamanhos
          solicitacao={solicitacao}
          material={material}
          layoutArte={layoutArte}
          updateSolicitacaoDados={updateSolicitacaoDados}
          tamanhoUnico={false}
          obrigatorio={cadastroItem.obrigatorio}
        />
      );
    }

    return null;
  };

  function onChangeMultiSelect(item) {
    const tipoDeArquivos =
      item && item.length !== null
        ? item
          .map((value) => {
            return value.label;
          })
          .toString()
        : '';

    solicitacaoDados.extensaoArquivo = tipoDeArquivos;
    updateSolicitacaoDados(solicitacaoDados);
  }

  const extensaoArquivo = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_ExtensaoArquivo,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <label>Tipo de Arquivo</label>
              {/* <Select
                className={getCampoObrigatorioClassName(solicitacaoDados.extensaoArquivo, cadastroItem.obrigatorio)}
                options={selectListExtensaoArquivos} isClearable={true}
                onChange={(item) => {
                  solicitacaoDados.extensaoArquivo = item?.label;
                  console.log(solicitacaoDados.extensaoArquivo)
                  updateSolicitacaoDados(solicitacaoDados);
                }}
                value={selectListExtensaoArquivos.find((item) => item.label === solicitacaoDados.extensaoArquivo)}
              /> */}
              <Select
                isMulti
                name="colors"
                options={selectListExtensaoArquivos}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Selecione os tipos de Arquivo"
                onChange={(item) => onChangeMultiSelect(item)}
                value={
                  solicitacaoDados.extensaoArquivo &&
                  solicitacaoDados.extensaoArquivo
                    .split(',')
                    .map((value) =>
                      selectListExtensaoArquivos.find(
                        (item) => item.label === value,
                      ),
                    )
                }
              />
            </div>

            {extensaoArquivoOutro()}
          </div>
        </div>
      );
    }

    return null;
  };

  const extensaoArquivoOutro = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_ExtensaoArquivo,
    );
    if (cadastroItem) {
      if (solicitacaoDados.extensaoArquivo?.length > 0) {
        let extensaoArquivo =
          extensoes.find(
            (item) => item.nome === solicitacaoDados.extensaoArquivo,
          ) || {};

        if (extensaoArquivo.habilitaDescricao) {
          return (
            <div className="col-lg-12 mb-4">
              <label>Outro Tipo de Arquivo</label>
              <input
                type="text"
                className={`form-control multiple ${getCampoObrigatorioClassName(
                  solicitacaoDados.extensaoArquivoOutro,
                  cadastroItem.obrigatorio,
                )}`}
                value={solicitacaoDados.extensaoArquivoOutro || ''}
                onChange={(event) => {
                  solicitacaoDados.extensaoArquivoOutro = event.target.value;
                  updateSolicitacaoDados(solicitacaoDados);
                }}
              />
            </div>
          );
        }
      }
    }

    return null;
  };

  const tempo = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Tempo,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6 mb-4">
          <label>Tempo (segundos)</label>
          <Select
            className={getCampoObrigatorioClassName(
              solicitacaoDados.tempoVideoId,
              cadastroItem.obrigatorio,
            )}
            options={selectListTempos}
            isClearable={true}
            onChange={(item) => {
              solicitacaoDados.tempoVideoId = item?.value;
              solicitacaoDados.tempoVideo = tempos.find(
                (tempo) => tempo.tempoVideoId === solicitacaoDados.tempoVideoId,
              );
              updateSolicitacaoDados(solicitacaoDados);
            }}
            value={selectListTempos.find(
              (item) => item.value === solicitacaoDados.tempoVideoId,
            )}
          />
        </div>
      );
    }

    return null;
  };

  const site = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Site,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6 mb-4">
          <label>Site</label>
          <input
            type="text"
            className={`form-control ${getCampoObrigatorioClassName(
              solicitacaoDados.site,
              cadastroItem.obrigatorio,
            )}`}
            value={solicitacaoDados.site || ''}
            onChange={(event) => {
              solicitacaoDados.site = event.target.value;
              updateSolicitacaoDados(solicitacaoDados);
            }}
          />
        </div>
      );
    }

    return null;
  };

  const facebook = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Facebook,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6 mb-4">
          <label>Facebook</label>
          <input
            type="text"
            className={`form-control ${getCampoObrigatorioClassName(
              solicitacaoDados.facebook,
              cadastroItem.obrigatorio,
            )}`}
            value={solicitacaoDados.facebook || ''}
            onChange={(event) => {
              solicitacaoDados.facebook = event.target.value;
              updateSolicitacaoDados(solicitacaoDados);
            }}
          />
        </div>
      );
    }

    return null;
  };

  const instagram = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Instagram,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6 mb-4">
          <label>Instagram</label>
          <input
            type="text"
            className={`form-control ${getCampoObrigatorioClassName(
              solicitacaoDados.instagram,
              cadastroItem.obrigatorio,
            )}`}
            value={solicitacaoDados.instagram || ''}
            onChange={(event) => {
              solicitacaoDados.instagram = event.target.value;
              updateSolicitacaoDados(solicitacaoDados);
            }}
          />
        </div>
      );
    }

    return null;
  };

  const telefoneContato = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_TelefoneContato,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6 mb-4">
          <label>Telefone Contato</label>
          <InputTelefone
            className={getCampoObrigatorioClassName(
              solicitacaoDados.telefoneContato,
              cadastroItem.obrigatorio,
            )}
            value={solicitacaoDados.telefoneContato || ''}
            onChange={(value) => {
              solicitacaoDados.telefoneContato = value;
              updateSolicitacaoDados(solicitacaoDados);
            }}
          />
        </div>
      );
    }

    return null;
  };

  const telefoneDelivery = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) =>
        cadastroItem.key === cadastroItemKeys.Dados_TelefoneDelivery,
    );
    if (cadastroItem) {
      return (
        <div className="col-lg-6 mb-4">
          <label>Telefone Delivery</label>
          <InputTelefone
            className={getCampoObrigatorioClassName(
              solicitacaoDados.telefoneDelivery,
              cadastroItem.obrigatorio,
            )}
            value={solicitacaoDados.telefoneDelivery || ''}
            onChange={(value) => {
              solicitacaoDados.telefoneDelivery = value;
              updateSolicitacaoDados(solicitacaoDados);
            }}
          />
        </div>
      );
    }

    return null;
  };

  const logo = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Logo,
    );
    if (cadastroItem) {
      if (loja.urlLogo && loja.urlLogo.length) {
        if (typeof solicitacao.solicitacaoDados.possuiLogo !== 'boolean') {
          solicitacao.solicitacaoDados.possuiLogo = true;
          solicitacaoDados.possuiLogo = true;
        }
      }

      return (
        <div className="col-lg-6 mb-4">
          <label>Incluir logo do cliente na arte?</label>
          <div className="d-block">
            <label className="mr-3">
              <input
                type="radio"
                value={'true'}
                checked={solicitacaoDados.possuiLogo === true}
                onChange={(e) => {
                  solicitacaoDados.possuiLogo = e.target.value === 'true';
                  updateSolicitacaoDados(solicitacaoDados);
                }}
              />{' '}
              Sim
            </label>

            <label>
              <input
                type="radio"
                value={'false'}
                checked={solicitacaoDados.possuiLogo === false}
                onChange={(e) => {
                  solicitacaoDados.possuiLogo = e.target.value === 'true';
                  updateSolicitacaoDados(solicitacaoDados);
                }}
              />{' '}
              Não
            </label>
          </div>
        </div>
      );
    }

    return null;
  };

  const rodape = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Rodape,
    );
    if (cadastroItem) {
      if (loja.urlRodape && loja.urlRodape.length) {
        if (typeof solicitacao.solicitacaoDados.possuiRodape !== 'boolean') {
          solicitacao.solicitacaoDados.possuiRodape = true;
          solicitacaoDados.possuiRodape = true;
        }
      }

      return (
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <label>O cliente possui arquivo para o rodapé?</label>
              <div className="d-block">
                <label className="mr-3">
                  <input
                    type="radio"
                    value={'true'}
                    checked={solicitacaoDados.possuiRodape === true}
                    onChange={(e) => {
                      solicitacaoDados.possuiRodape = e.target.value === 'true';
                      updateSolicitacaoDados(solicitacaoDados);
                    }}
                  />{' '}
                  Sim
                </label>

                <label>
                  <input
                    type="radio"
                    value={'false'}
                    checked={solicitacaoDados.possuiRodape === false}
                    onChange={(e) => {
                      solicitacaoDados.possuiRodape = e.target.value === 'true';
                      updateSolicitacaoDados(solicitacaoDados);
                    }}
                  />{' '}
                  Não
                </label>
              </div>
            </div>

            {comRodape()}
            {semRodape()}
          </div>
        </div>
      );
    }

    return null;
  };

  const comRodape = () => {
    if (solicitacaoDados.possuiRodape === true) {
      return (
        <div className="col-lg-12 mb-4">
          {urlRodape.length > 0 && (
            <a href={urlRodape} target="_blank">
              <img
                className="img-rodape mb-3"
                src={urlRodape}
                alt=""
                width="100"
              />
            </a>
          )}
          <InputFileCustom name="Rodape" onChange={uploadRodape} />
        </div>
      );
    }
  };

  const semRodape = () => {
    if (solicitacaoDados.possuiRodape === false) {
      return (
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6 mb-4">
              <label>Formas de Pagamento</label>
              <div className="container-forma-pagamento">
                {formaPagamentos.map((formaPagamento, index) => {
                  return (
                    <label key={index}>
                      <input
                        type="checkbox"
                        checked={
                          (
                            solicitacaoDados.formaPagamento?.split(',') || []
                          ).findIndex((x) => x === formaPagamento.nome) !== -1
                        }
                        onChange={(event) => {
                          var lista =
                            solicitacaoDados.formaPagamento?.split(',') || [];
                          let index = lista.findIndex(
                            (x) => x === formaPagamento.nome,
                          );

                          if (event.target.checked) {
                            if (index === -1) lista.push(formaPagamento.nome);
                          } else {
                            if (index !== -1) lista.splice(index, 1);
                          }

                          solicitacaoDados.formaPagamento = lista.join(',');
                          updateSolicitacaoDados(solicitacaoDados);
                        }}
                      />
                      <img src={formaPagamento.urlImagem} height="24" />
                    </label>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 mb-4">
              <label>Outra Forma de Pagamento</label>
              <input
                type="text"
                className="form-control"
                value={solicitacaoDados.outraFormaPagamento || ''}
                onChange={(event) => {
                  solicitacaoDados.outraFormaPagamento = event.target.value;
                  updateSolicitacaoDados(solicitacaoDados);
                }}
              />
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-lg-12 mb-4">
              <RodapeEnderecos
                entities={entities}
                solicitacao={solicitacao}
                entregaLojasParticipantes={entregaLojasParticipantes}
                updateSolicitacaoDados={updateSolicitacaoDados}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const observacao = () => {
    let cadastroItem = cadastroItens.find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Observacao,
    );
    if (cadastroItem) {
      return (
        <>
          <div className="col-lg-12 mb-4">
            <label>Qual a finalidade desta arte? Descreva para onde ela vai e como será utilizada.</label>
            <textarea
              className={`form-control ${getCampoObrigatorioClassName(
                solicitacaoDados.observacoesGerais,
                cadastroItem.obrigatorio,
              )}`}
              rows="5"
              value={solicitacaoDados.observacoesGerais || ''}
              onChange={(event) => {
                solicitacaoDados.observacoesGerais = event.target.value;
                updateSolicitacaoDados(solicitacaoDados);
              }}
            ></textarea>
          </div>
          <div className="col-lg-12 mb-4">
            <label>Anexar arquivo</label>
            <InputFileCustom
              name="AnexoObservacoesGerais"
              onChange={uploadAnexoObservacao}
            />

            {
              solicitacaoDados.urlAnexoObservacoesGerais?.length > 0 &&
              <div>
                <a href={`${solicitacaoDados.urlAnexoObservacoesGerais}`} className="btn btn-success" target="_blank">Download arquivo</a>
              </div>
            }

          </div>

        </>
      );
    }

    return null;
  };

  return (
    <div>
      <hr />

      {renderErrors(errors)}

      <div className="row">
        {veiculacao()}
        {recebimentoArte()}
        {motivo()}
        {nomeFantasia()}
        {quantidadeLojasParticipantes()}
        {quantidadeImpressosLoja()}
        {tamanhoUnico()}
        {tamanhosMulti()}
        {extensaoArquivo()}
        {tempo()}
        {site()}
        {facebook()}
        {instagram()}
        {telefoneContato()}
        {telefoneDelivery()}
        {logo()}
        {linguagem()}
        {publico()}
        {rodape()}
        {observacao()}
      </div>

      <div className="row mt-5">
        {!hideVoltar && (
          <div className="col col btn-avancar-voltar text-left">
            <button
              className="btn btn-primary btn-navigation"
              onClick={() => voltar()}
            >
              <span>
                <FaAngleLeft size={26} />
              </span>
              <span>VOLTAR</span>
            </button>
          </div>
        )}

        <div className="col col btn-avancar-voltar d-flex flex-row-reverse">
          <button
            className="btn btn-primary btn-navigation btn-navigation-right"
            onClick={() => avancar()}
          >
            <span>SALVAR</span>
            <span>
              <FaSave size={26} />
            </span>
          </button>
        </div>

        {/* <pre className="text-left">{JSON.stringify(solicitacao, null, 2)}</pre> */}
      </div>
    </div>
  );
};

export default Dados;
