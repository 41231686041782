import React, { useState, useContext, useEffect } from 'react';
import {Tabs, Tab} from 'react-bootstrap';


import './styles.scss';
import api from '../../../../../api';
import ExtratoBrasil from './ExtratoBrasil';
import ExtratoParcial from './ExtratoParcial';

const RelatorioBrasil = (props) => {
  const [urlTableau, setUrlTableau] = useState('');

  useEffect(() => {
    (async () => {
      const {
        data: { value },
      } = await api.get('/Tableau/get-url-produtividade-brasil');

      console.log(value);
      setUrlTableau(value);
    })();
  }, []);

  return (
    <div className="content_relatorios">
      {/*<h1 className="relatorio-title">RELATÓRIOS</h1>*/}
      <Tabs defaultActiveKey="produtividade" id="uncontrolled-tab-example">
        <Tab eventKey="produtividade" title="Produtividade">
          <div className="text-center iframe-container">
           {urlTableau.length > 0 && <iframe src={urlTableau}></iframe>}
          </div>
        </Tab>
        {/*<Tab eventKey="visaogeral" title="Visão Geral">
          <div className="placeHold" />
        </Tab>*/}
        <Tab eventKey="extrato" title="Extrato Mensal">
          <ExtratoBrasil />
        </Tab>
        <Tab eventKey="extratoparcial" title="Extrato Parcial">
          <ExtratoParcial />
        </Tab>
      </Tabs>
    </div>
  );
};

export default RelatorioBrasil;
