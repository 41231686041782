import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class TablePagination extends Component {
  renderTotal() {
    if (!this.props.pageData) return null;

    if (this.props.pageData.totalRows === 0) {
      return (
        <div className="row">
          <div className="col-md-12">
            <label>Nenhum registro encontrado.</label>
          </div>
        </div>
      );
    } else if (this.props.pageData.totalRows > 0) {
      return (
        <div className="row">
          <div className="col-md-12">
            <label>Total de registros: {this.props.pageData.totalRows}</label>
          </div>
        </div>
      );
    }

    return null;
  }

  renderPagination() {
    let pages = getPages(this.props.pageData);

    if (!pages || pages.length < 2) return null;

    return (
      <Pagination
        style={{
          display: 'inline-flex',
        }}
      >
        <PaginationItem
          disabled={!this.props.pageData.hasPrevious}
          onClick={(e) => {
            if (this.props.pageData.hasPrevious) {
              this.handlePageChange(e, this.props.pageData.pageNumber - 1);
            }
          }}
        >
          <PaginationLink previous href="#" />
        </PaginationItem>

        {pages.map((page, index) => (
          <PaginationItem
            disabled={this.props.pageData.disabled}
            key={index}
            active={page == this.props.pageData.pageNumber}
            onClick={(e) => this.handlePageChange(e, page)}
          >
            <PaginationLink href="#">{page}</PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem
          disabled={!this.props.pageData.hasNext}
          onClick={(e) => {
            if (this.props.pageData.hasNext) {
              this.handlePageChange(e, this.props.pageData.pageNumber + 1);
            }
          }}
        >
          <PaginationLink next href="#" />
        </PaginationItem>
      </Pagination>
    );
  }

  render() {
    if (!this.props.pageData) return null;

    return (
      <div>
        {this.renderPagination()}
        {this.renderTotal()}
      </div>
    );
  }

  handlePageChange = (e, pageNumber) => {
    e.preventDefault();

    this.props.handlePageChange(pageNumber);
  };
}

function getPages(pageData) {
  var pages = [];

  if (!pageData) return pages;

  var pageCount = pageData.totalPages;
  var pageNum = pageData.pageNumber;

  var maximoPaginas = 5;

  var inicio =
    pageCount - pageNum + 1 >= maximoPaginas
      ? pageNum
      : pageCount - maximoPaginas + 1;

  if (inicio <= 0) inicio = 1;

  for (var i = inicio; i <= pageCount && pages.length < 5; i++) {
    pages.push(i);
  }

  return pages;
}

export default TablePagination;
