import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation, } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, } from 'react-icons/fa';
import { Accordion, Card, Button } from 'react-bootstrap'

import './styles.scss';

import TitlePages from '../../../atoms/TitlePages';
import SubHeader from '../../SubHeader';

import { tabs } from './../_constants';

import ResumoMaterial from './ResumoMaterial';

const Resumo = (props) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  let model = props.model;
  let pedidoCriado = props.pedidoCriado;
  let entities = props.entities;
  let finalizar = props.finalizar;
  const [setModel] = useState(() => props.setModel);
  let setActiveTab = props.setActiveTab;
  let setRascunho = props.setRascunho;

  const initialIndex = 0;
  const currentIndex = parseInt(location.pathname.split("/")[4] ?? "0") || initialIndex;
  const [activeSolicitacaoIndex, setActiveSolicitacaoIndex] = useState(currentIndex);

  return (
    <div className="wrapper-content-pedido-resumo">

      <SubHeader title={model.loja?.razaoSocial} />

      <TitlePages title="Resumo das solicitações" />

      <div className="content-pedido-resumo">

        <div className="row">
          <div className="col-lg-12">
            <Accordion>
              {(model.solicitacoes || []).map((solicitacao, index) => {
                return (
                  <ResumoMaterial 
                    key={index} 
                    pedido={model}
                    solicitacao={solicitacao} 
                    loja={model.loja}
                    entregaDigital={model.entregaDigital}
                    entregaCentroDistribuicao={model.entregaCentroDistribuicao}
                    entregaLojasParticipantes={model.entregaLojasParticipantes}
                    entregaOutro={model.entregaOutro}
                    entities={entities} 
                    pedidoCriado={pedidoCriado} 
                    index={index} 
                  />
                )
              })}
            </Accordion>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 text-left">
            <button className="btn btn-primary btn-navigation" onClick={() => setActiveTab(tabs.tabSolicitacoes)} >
              <span><FaAngleLeft size={26} /></span>
              <span>VOLTAR</span>
            </button>
          </div>

          <div className="col-lg-6 d-flex flex-row-reverse">
            <button className="btn btn-primary btn-navigation btn-navigation-right" onClick={() => finalizar()}>
              <span>FINALIZAR</span>
              <span><FaAngleRight size={26} /></span>
            </button>
          </div>

          {/* <pre className="text-left">{JSON.stringify(tipoMateriais, null, 2)}</pre> */}

        </div>
      </div>
    </div >

  );
}

export default Resumo;
