import React from 'react';

import HeaderApp from '../../organisms/Header/HeaderApp';
import ContentValidacaoDadosUsuario from '../../organisms/ContentValidacaoDadosUsuario';
import Bottom from '../../organisms/Bottom';

const ArtesFacil = () => {
  return (
    <>
      <HeaderApp />
      <ContentValidacaoDadosUsuario />
      <Bottom />
    </>
  );
};

export default ArtesFacil;
