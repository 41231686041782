import React from 'react';

import './styles.scss';
import HeaderApp from '../../organisms/Header/HeaderApp';
import Bottom from '../../organisms/Bottom';
import ContentAlterarSolicitacao from '../../organisms/ContentAlterarSolicitacao';

const AlterarSolicitacao = (props) => {
  
  return (
    <>
      <HeaderApp />
        <ContentAlterarSolicitacao />
      <Bottom/>
    </>
  )
};

export default AlterarSolicitacao;
