import React from 'react';
import { useSelector } from 'react-redux';

import './styles.scss';

const SubHeader = (props) => {
  //const { customer } = useSelector(state => state.requestMaterials);
  let title = props.title || "";


  return (
    <div className="sub-header">
      {title}
    </div>
  );
};

export default SubHeader;
