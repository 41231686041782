import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
//import HeaderApp from '../../organisms/Header/HeaderApp';
//import ContentResumoPedido from '../../organisms/ContentResumoPedido';
//import Bottom from '../../organisms/Bottom';
//import ContentRelatorios from '../../organisms/ContentRelatorios';
import RelatorioBrasil from './RelatorioBrasil/index ';
import RelatorioLatam from './RelatorioLatam';
import './styles.scss';

const ContentRelatorios = (props) => {
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));

  return (
    <div className='content_relatorios'>
      {(userAuth.acessaRelatorio || userAuth.acessaTodosRelatorios) &&
        <Tabs defaultActiveKey={userAuth.regiao == "LATAM"? "tab-latam" : "tab-brasil"} id="uncontrolled-tab">
          {
            (userAuth.regiao == "BRASIL" || userAuth.acessaTodosRelatorios) &&
            <Tab eventKey="tab-brasil" title="Brasil">
              <RelatorioBrasil />
            </Tab>
          }
          {
            (userAuth.regiao == "LATAM" || userAuth.acessaTodosRelatorios) &&
            <Tab eventKey="tab-latam" title="Latam"  >
              <RelatorioLatam />
            </Tab>
          }
        </Tabs>
      }
    </div>
  );
}

export default ContentRelatorios;
