import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Context } from '../Context/AuthContext';

import PageNotFound from '../components/pages/PageNotFound/';
import Login from '../components/pages/Login';

import RecoverPassword from '../components/pages/RecoverPassword/index';
import RecoveryEmailSent from '../components/pages/RecoverPassword/RecoveryEmailSent/index';
import PasswordChanged from '../components/pages/RecoverPassword/PasswordChanged/index';
import NewPassword from '../components/pages/RecoverPassword/NewPassword/index';

import UsuarioAutoCadastro from '../components/pages/UsuarioAutoCadastro';
import SucessoCadastro from '../components/pages/UsuarioAutoCadastro/SucessoCadastro';
import UsuarioLista from '../components/pages/UsuarioCadastroAprovacao/UsuarioLista';
import UsuarioEditor from '../components/pages/UsuarioCadastroAprovacao/UsuarioEditor';

import Home from '../components/pages/Home/';
import ChooseTheCustomer from '../components/pages/ChooseTheCustomer';
import SelectMaterials from '../components/pages/SelectMaterials';
import DeliveryMaterials from '../components/pages/DeliveryMaterials';
import Query from '../components/pages/Query';
import Pendencias from '../components/pages/Pendencias';
import BancoImagens from '../components/pages/BancoImagens';
import BancoArtes from '../components/pages/BancoArtes';
import DadosUsuario from '../components/pages/DadosUsuario';
import Attendance from '../components/pages/Attendance';
import Called from '../components/pages/Called';
import CalledSent from '../components/pages/CalledSent';
import CalledFinished from '../components/pages/CalledFinished';
import FollowCalled from '../components/pages/FollowCalled';
import ChatCalled from '../components/pages/ChatCalled';

import Draft from '../components/pages/Draft';
// import Pendencias from '../components/pages/Pendencias';
import SolicitacaoFluxoStatus from '../components/pages/SolicitacaoFluxo/Status';
import SolicitacaoFluxoEnviarArte from '../components/pages/SolicitacaoFluxo/EnviarArte';
import SolicitacaoFluxoConfirmarEnvio from '../components/pages/SolicitacaoFluxo/ConfirmarEnvio';
import SolicitacaoFluxoAprovarArte from '../components/pages/SolicitacaoFluxo/AprovarArte';
import SolicitacaoFluxoDefinirOrcamento from '../components/pages/SolicitacaoFluxo/DefinirOrcamento';
import SolicitacaoFluxoAprovarOrcamento from '../components/pages/SolicitacaoFluxo/AprovarOrcamento';
import SolicitacaoFluxoOrcamentoReprovado from '../components/pages/SolicitacaoFluxo/OrcamentoReprovado';
import SolicitacaoFluxoRevisao from '../components/pages/SolicitacaoFluxo/Revisao';

import DeliveryDigitalMaterials from '../components/pages/DeliveryDigitalMaterials';

import PedidoMateriais from '../components/pages/PedidoMateriais';
import PedidoCampanha from '../components/pages/PedidoCampanha';
import AlterarSolicitacao from '../components/pages/AlterarSolicitacao';
import ResumoPedido from '../components/pages/ResumoPedido';

import HistoricoPedidos from '../components/pages/HistoricoPedidos';
import PlanoOnOffMidiasEnviadas from '../components/pages/PlanoOnOff/MidiasEnviadas';
import PlanoOnOffRelatorios from '../components/pages/PlanoOnOff/Relatorios';
import AdicionarMidiasOnOff from '../components/pages/PlanoOnOff/AdicionarMidias';
import ArtesFacil from '../components/pages/ArtesFacil';
import ValidacaoDadosUsuario from '../components/pages/ValidacaoDadosUsuario';
import Relatorios from '../components/pages/Relatorios';

import AdminUsuarios from '../components/pages/AdminUsuarios';
import AdminLogar from '../components/pages/AdminLogar';
import Acesso from '../components/pages/Acesso';

import AcessoRestrito from '../components/pages/PageWithoutPermission';
import PageError from '../components/pages/PageError';

// import api from '../api';

function CustomRoute({ isPrivate, ...rest }) {
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));

  // Validação caso o usuario precisa atualizar os dados cadastrais
  if (rest.path === '/atualizar-dados' && userAuth && userAuth.atualizarDados == false) {
    let redirectLink = '/';
    return <Redirect to={redirectLink} />;
  }

  if (userAuth && userAuth.atualizarDados == true && rest.path !== '/atualizar-dados') {
    let redirectLink = '/atualizar-dados';
    return <Redirect to={redirectLink} />;
  }

  if (isPrivate === true && (!userAuth || userAuth.authenticated === false)) {
    let redirect = '/login?urlReturn=' + rest.path;
    return <Redirect to={redirect} />;
  }

  return <Route {...rest} />;
}

const Routes = () => {
  return (
    <Switch>
      <CustomRoute exact isPrivate path="/" component={Home} />
      <CustomRoute exact isPrivate path="/admin" component={AdminUsuarios} />
      <CustomRoute exact isPrivate path="/admin/logar/:usuarioId?" component={AdminLogar} />
      <CustomRoute path="/login" component={Login} />
      <CustomRoute path="/acesso" component={Acesso} />
      <CustomRoute path="/recuperar-senha" component={RecoverPassword} />
      <CustomRoute path="/email-recuperacao-enviado" component={RecoveryEmailSent} />
      <CustomRoute path="/senha-alterada" component={PasswordChanged} />
      <CustomRoute path="/novasenha" component={NewPassword} />
      <CustomRoute path="/atualizar-dados" isPrivate component={ValidacaoDadosUsuario} />

      <CustomRoute exact path="/usuario-autocadastro" component={UsuarioAutoCadastro} />
      <CustomRoute exact path="/usuario-autocadastro/sucesso" component={SucessoCadastro} />
      <CustomRoute isPrivate exact path="/usuario-aprovacao" component={UsuarioLista} />
      <CustomRoute isPrivate exact path="/usuario-aprovacao/editar/:usuarioId?" component={UsuarioEditor} />

      <CustomRoute isPrivate path="/escolha-o-cliente/:id?" component={ChooseTheCustomer} />
      <CustomRoute isPrivate path="/selecione-os-materiais/:rascunhoId" component={SelectMaterials} />
      <CustomRoute isPrivate path="/entrega-de-materiais" component={DeliveryMaterials} />
      <CustomRoute isPrivate path="/entrega-de-materiais-digitais/:rascunhoId" component={DeliveryDigitalMaterials} />
      <CustomRoute isPrivate path="/consulta" component={Query} />

      <CustomRoute isPrivate path="/banco-de-imagens" component={BancoImagens} />
      <CustomRoute isPrivate path="/banco-de-artes" component={BancoArtes} />
      <CustomRoute isPrivate path="/meus-dados" component={DadosUsuario} />
      <CustomRoute isPrivate path="/atendimento" component={Attendance} />
      <CustomRoute isPrivate path="/chamado" component={Called} />
      <CustomRoute isPrivate path="/chamado-enviado" component={CalledSent} />
      <CustomRoute isPrivate path="/chamado-finalizado" component={CalledFinished} />
      <CustomRoute isPrivate path="/acompanhar-chamado" component={FollowCalled} />
      <CustomRoute isPrivate path="/chat" component={ChatCalled} />
      <CustomRoute isPrivate path="/rascunho" component={Draft} />
      <CustomRoute isPrivate path="/pendencias" component={Pendencias} />

      <CustomRoute isPrivate path="/solicitacao-fluxo/status" component={SolicitacaoFluxoStatus} />
      <CustomRoute isPrivate path="/solicitacao-fluxo/enviararte" component={SolicitacaoFluxoEnviarArte} />
      <CustomRoute isPrivate path="/solicitacao-fluxo/confirmar-envio" component={SolicitacaoFluxoConfirmarEnvio} />
      <CustomRoute isPrivate path="/solicitacao-fluxo/aprovararte" component={SolicitacaoFluxoAprovarArte} />
      <CustomRoute isPrivate path="/solicitacao-fluxo/definirorcamento" component={SolicitacaoFluxoDefinirOrcamento} />
      <CustomRoute isPrivate path="/solicitacao-fluxo/aprovarorcamento" component={SolicitacaoFluxoAprovarOrcamento} />
      <CustomRoute isPrivate path="/solicitacao-fluxo/orcamentoreprovado" component={SolicitacaoFluxoOrcamentoReprovado} />
      <CustomRoute isPrivate path="/solicitacao-fluxo/revisao" component={SolicitacaoFluxoRevisao} />

      <CustomRoute isPrivate path="/novo-pedido/materiais/:id?" component={PedidoMateriais} />
      <CustomRoute isPrivate path="/pedido-materiais/:pedidoId?/:tabPedido?/:tabSolicitacao?" component={PedidoMateriais} />
      {/* <CustomRoute isPrivate path="/pedido-campanha/:pedidoId?/:tabPedido?/:tabSolicitacao?" component={PedidoCampanha} /> */}
      <CustomRoute isPrivate path="/alterar-solicitacao/:solicitacaoId?/:tab?" component={AlterarSolicitacao} />
      <CustomRoute isPrivate path="/resumo-pedido/:pedidoId?" component={ResumoPedido} />
      <CustomRoute isPrivate path="/historico-pedidos" component={HistoricoPedidos} />
      {/* <CustomRoute isPrivate path="/adicionar-midia-on-off" component={AdicionarMidiasOnOff} /> */}
      {/* <CustomRoute isPrivate path="/on-off-midias-enviadas" component={PlanoOnOffMidiasEnviadas} /> */}
      {/* <CustomRoute isPrivate path="/on-off-relatorios/:onOffRedeId?" component={PlanoOnOffRelatorios} /> */}
      {/* <CustomRoute isPrivate path="/artes-facil/:pedidoId?/:tabPedido?" component={ArtesFacil} /> */}
      <CustomRoute isPrivate path="/relatorios" component={Relatorios} />

      <CustomRoute path="/sem-permissao" component={AcessoRestrito} />
      <CustomRoute path="/error" component={PageError} />
      <CustomRoute path="*" component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
