import React from "react";
import { Router } from 'react-router-dom';

import history from './history';
import Routes from './routes';
import { AuthProvider } from '../Context/AuthContext';

const MainRoutes = () => {
  return (
    <AuthProvider>
      <Router history={ history }>
        <Routes/>
      </Router>
    </AuthProvider>
  );
}

export default MainRoutes;
