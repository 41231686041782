import React, { useEffect, useState, useContext } from 'react';
import { Carousel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './styles.scss';
import api from '../../../api';

import { Context } from '../../../Context/AuthContext';

import rascunho from '../../../assets/brf/imgs/icons/rascunho.png';
import consultar from '../../../assets/brf/imgs/icons/consultar.png';
import pendencias from '../../../assets/brf/imgs/icons/pendencias.png';
import relatorios from '../../../assets/brf/imgs/icons/relatorios.png';
import solicitarCampanha from '../../../assets/brf/imgs/icons/solicitar_campanha.png';
import solicitarMateriais from '../../../assets/brf/imgs/icons/solicitar_materiais.png';
import artesFacil from '../../../assets/brf/imgs/icons/artes_facil.png';
import planoMidiasOnOff from '../../../assets/brf/imgs/icons/plano_midias_on_off.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faFile,
  faFileAlt,
  faExclamationTriangle,
  faSearch,
  faImage,
  faFileImage,
  faFileExcel,
  faAt,
  faUser,
  faSignOutAlt,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';

const ContentHome = () => {
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));

  const [warnings, setWarnings] = useState([]);
  const { showLoader, userData } = useContext(Context);
  const [corPendencia, setCorPendencia] = useState([]);
  const [corRascunho, setCorRascunho] = useState([]);

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      const {
        data: { value: corPendenciaResult },
      } = await api.get('/Pendencia/count-pendencia-usuario');
      setCorPendencia(corPendenciaResult);

      const {
        data: { value: corRascunhoResult },
      } = await api.get('/Rascunho/count-rascunhos');
      setCorRascunho(corRascunhoResult);

      const {
        data: { value: warningsResult },
      } = await api.get('/Aviso/list');
      setWarnings(warningsResult);

      showLoader(false);
    }
    fetchData();
  }, []);

  return (
    <div className="content-home">
      <div className="wrapper-choose-the-costumer">
        <div className="warnings">
          <Carousel>
            {warnings?.map((item) => {
              return (
                <Carousel.Item key={item.avisoId}>
                  {item.urlRedirecionamento ? (
                    <a
                      href={item.urlRedirecionamento}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="d-block w-100"
                        src={item.urlImagem}
                        alt={item.texto}
                      />
                    </a>
                  ) : (
                    <img
                      className="d-block w-100"
                      src={item.urlImagem}
                      alt={item.texto}
                    />
                  )}
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        <div className="actions">
          {/* <div> */}

            {/* Arte Facil -------------------------------------------------------------------- */}
            {/* <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-artesfacil">
                  Solicite aqui artes pre aprovadas, trocando apenas logo do PDV
                  e/ou preço do produto e/ou tipo de ação promocional.
                </Tooltip>
              }
            >
              <Link to="/artes-facil">
                <div>
                  <div>
                    <img src={artesFacil} alt="Artes fácil" />
                  </div>
                  <div className="btn-divider"></div>
                  <div>Artes fácil</div>
                </div>
              </Link>
            </OverlayTrigger> */}

            {/* Campanha ---------------------------------------------------------------------- */}
            {/* <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-campanha">
                  Solicite aqui artes dos materiais disponibilizados para cada
                  campanha.
                </Tooltip>
              }
            >
              <Link to="/pedido-campanha">
                <div>
                  <div>
                    <img src={solicitarCampanha} alt="pedido-campanha" />
                  </div>
                  <div className="btn-divider"></div>
                  <div>Adaptação Campanha</div>
                </div>
              </Link>
            </OverlayTrigger> */}
          {/* </div> */}

          {/* Materiais ----------------------------------------------------------------------- */}
          <div>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-material">
                  Solicite aqui artes personalizadas para todo e qualquer material.
                </Tooltip>
              }
            >
              <Link to="/pedido-materiais">
                <div>
                  <div>
                    <img src={solicitarMateriais} alt="pedido-materiais" />
                  </div>
                  <div className="btn-divider"></div>
                  <div>Solicitar Diversos Materiais</div>
                </div>
              </Link>
            </OverlayTrigger>
            <a href="/rascunho">
              <div className={corRascunho > 0 ? 'corbotao' : ''}>
                <div>
                  <img src={rascunho} alt="Rascunhos" />
                </div>
                <div className="btn-divider"></div>
                <div>Rascunhos</div>
              </div>
            </a>
          </div>

          <div>
            {/* Pendencias -------------------------------------------------------------------- */}
            <a href="/pendencias">
              <div className={corPendencia > 0 ? 'corbotao' : ''}>
                <div>
                  <img src={pendencias} alt="pendencias" />
                </div>
                <div className="btn-divider"></div>
                <div>Pendências</div>
              </div>
            </a>

            {/* Historico --------------------------------------------------------------------- */}
            <a href="/historico-pedidos">
              <div>
                <div>
                  <img src={rascunho} alt="Histórico de Pedidos" />
                </div>
                <div className="btn-divider"></div>
                <div>
                  Histórico <br></br>de Pedidos
                </div>
              </div>
            </a>
          </div>

          <div>
            {/* Consulta ---------------------------------------------------------------------- */}
            <a href="/consulta">
              <div>
                <div>
                  <img src={consultar} alt="Consultar" />
                </div>
                <div className="btn-divider"></div>
                <div>Consultar</div>
              </div>
            </a>

            {/* Relatorios -------------------------------------------------------------------- */}
            <a href={(userAuth?.acessaRelatorio || userAuth?.acessaTodosRelatorios) ? "/relatorios" : "#"}>
              <div>
                <div>
                  <img src={relatorios} alt="atendimento" />
                </div>
                <div className="btn-divider"></div>
                <div>Relatórios</div>
              </div>
            </a>
          </div>

          <div>
            {/* Banco de Imagens -------------------------------------------------------------- */}
            <a href="/banco-de-imagens">
              <div>
                <div style={{paddingLeft: "20px", paddingRight: "10px"}}>
                  {/* <img src={consultar} alt="Banco de Imagens" /> */}
                  <FontAwesomeIcon icon={faImage} size="3x"/>
                </div>
                <div className="btn-divider"></div>
                <div>Banco de Imagens</div>
              </div>
            </a>

            {/* Banco de Artes ---------------------------------------------------------------- */}
            <a href="/banco-de-artes">
              <div>
                <div style={{paddingRight: "10px"}}>
                  {/* <img src={relatorios} alt="Banco de Artes" /> */}
                  <FontAwesomeIcon icon={faFileImage} size="3x"/>
                </div>
                <div className="btn-divider"></div>
                <div>Banco de Artes</div>
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* Texto Atendimento ------------------------------------------------------------------- */}
      <div className="texto-atendimento">
        <p>
          Em caso de dúvidas ou ocorrências sobre o Creative Havaianas, ligar
          para (11) 9 8982-1821 de segunda a sexta no horário das 9:00 às 18:00
          ou, clique em atendimento ao usuário e abra um chamado que entraremos
          em contato.
        </p>
      </div>
    </div>
  );
};

export default ContentHome;
