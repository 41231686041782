import React, { useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import './styles.scss';
import TitlePages from '../../../../atoms/TitlePages';
import SubHeader from '../../../SubHeader';
import { tabs, passos } from '../../_constants';

import TipoMaterialLista from './TipoMaterialLista'

const Materiais = (props) => {
  let updateRascunho = props.updateRascunho;
  let model = props.model;
  let entities = props.entities;
  let setModel = props.setModel;
  let renderErrors = props.renderErrors;
  let setActiveTab = props.setActiveTab;

  let errors = (model.errors || []).filter((error) => error.passo === passos.passoMateriais.nro);
  model.solicitacoes = model.solicitacoes || [];

  const [solicitacoes, setSolicitacoes] = useState([]);

  const updateSolicitacoes = () => {
    setSolicitacoes([...model.solicitacoes]);
  }

  useEffect(() => {
    updateSolicitacoes();
  }, [model]);

  return (
    <div className="wrapper-content-pedido-materiais">

      <SubHeader title={model.loja?.razaoSocial} />

      <TitlePages title="Selecione os Materiais" />

      <div className="content-pedido-materiais">
        {/* <button onClick={() => {
          model.solicitacoes = [{ materialComunicacaoId: 1 }, { materialComunicacaoId: 2 }, { materialComunicacaoId: 3 }];
          setModel(model);
        }}>set solicitacoes</button> */}

        {renderErrors(errors)}

        <div className="row material text-center">
          {
            // entities.tipoMateriais.map((tipoMaterial, index) => {
            (entities.tipoMateriais || []).filter((value) => value.nome !== 'ARTES FÁCIL').map((tipoMaterial, index) => {
              return (
                <div className="col-lg-4 m-auto" key={index}>
                  <TipoMaterialLista 
                    title={tipoMaterial.nome} 
                    materiais={ tipoMaterial.materiaisComunicacao } 
                    model={model} 
                    entities={entities} 
                    solicitacoes={solicitacoes}
                    updateSolicitacoes={updateSolicitacoes} />
                </div>
              )
            })
          }
        </div>



        <div className="row buttons">

          <div className="col">
            <button className="btn btn-primary btn-navigation" onClick={() => {
              updateRascunho();
              setActiveTab(tabs.tabCliente);
            }}>
              <span><FaAngleLeft size={26} /></span>
              <span>VOLTAR</span>
            </button>
          </div>

          <div className="col flex-row-reverse">
            <button className={`btn btn-primary btn-navigation btn-navigation-right ${
              !solicitacoes.length
                ? "btn-disabled"
                : ""
            }`} onClick={() => {
              if (!solicitacoes.length)
                return;

              updateRascunho();
              setActiveTab(tabs.tabEntrega)
            }}>
              <span>AVANÇAR</span>
              <span><FaAngleRight size={26} /></span>
            </button>
          </div>

          {/* <pre className="text-left">{JSON.stringify(tipoMateriais, null, 2)}</pre> */}

        </div>
      </div>
    </div>

  );
}

export default Materiais;
