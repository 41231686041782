import api from '../../api';

const materialComunicacao = () => {
  async function getMaterialComunicacaoSelectList() {
    const result = await api.get('/materialcomunicacao/select-list');
    return result.data.value || [];
  }

  return {
    getMaterialComunicacaoSelectList,
  };
};

export default materialComunicacao;
