import { createStore, combineReducers } from 'redux';

import moduleReducers from './modules/reducers';

const reducers = combineReducers({
  ...moduleReducers,
});

function storeConfig() {
  return createStore(reducers);
}

export default storeConfig;