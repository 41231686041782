import React, { useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight, FaFilter, FaEraser } from 'react-icons/fa';

import TitlePages from '../../../../atoms/TitlePages';
import SubHeader from '../../../SubHeader';

import DatePickerCustom from '../../../../atoms/DatePicker';

import { tabs, passos, compareString, getDateFromStringJson } from '../../_constants';

const Campanha = (props) => {
  let updateRascunho = props.updateRascunho;
  let model = props.model;
  let entities = props.entities;
  let renderErrors = props.renderErrors;
  let errors = (model.errors || []).filter((error) => error.passo === passos.passoLayout.nro);
  let setActiveTab = props.setActiveTab;

  const [campanhas, setCampanhas] = useState(entities.campanhas || []);

  const [campanhaId, setCampanhaId] = useState(model.campanhaId || 0);

  const [nome, setNome] = useState("");
  const [vigenciaInicio, setVigenciaInicio] = useState("");
  const [vigenciaFim, setVigenciaFim] = useState("");

  useEffect(() => {
    setCampanhaId(model.campanhaId || 0);
  }, [model])

  useEffect(() => {
    setCampanhas(entities.campanhas || []);
  }, [entities])

  const filtrar = () => {
    let campanhas = entities.campanhas || [];

    if (nome.length > 0)
      campanhas = campanhas.filter((campanha) => compareString(campanha.nome, nome));

    //todo: verificar qual a regra desse filtro de vigencia
    if (vigenciaInicio.toString().length > 0)
      campanhas = campanhas.filter((campanha) => vigenciaInicio < getDateFromStringJson(campanha.dataFim));

    if (vigenciaFim.toString().length > 0)
      campanhas = campanhas.filter((campanha) => vigenciaFim > getDateFromStringJson(campanha.dataInicio))

    setCampanhas([...campanhas]);
  }

  const limpar = () => {
    setNome("");
    setVigenciaInicio("");
    setVigenciaFim("");
    setCampanhas(entities.campanhas || []);
  }

  const renderFiltro = () => {
    return (
      <>
        <div className="row mb-3 d-flex align-items-center ">
          <div className="col-lg-2 text-right">
            Nome
          </div>
          <div className="col-lg-5">
            <input type="text" className="form-control"
              value={nome}
              onChange={(event) => {
                setNome(event.target.value);
              }} />
          </div>
        </div>


        <div className="row mb-3 d-flex align-items-center ">
          <div className="col-lg-2 text-right">
            Vigencia
          </div>
          <div className="col-lg-5">
            <DatePickerCustom
              selected={vigenciaInicio}
              onChange={(value) => {
                setVigenciaInicio(value);
              }} />
          </div>
          <div className="col-lg-5">
            <DatePickerCustom
              selected={vigenciaFim}
              onChange={(value) => {
                setVigenciaFim(value);
              }} />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-10 offset-lg-2 d-flex justify-content-start">

            <button className="btn btn-primary btn-navigation mr-3  " onClick={() => {
              filtrar();
            }}>
              <span><FaFilter size={18} /></span>
              <span>FILTRAR</span>
            </button>

            <button className="btn bg-white text-primary border border-primary btn-navigation" onClick={() => {
              limpar();
            }}>
              <span><FaEraser size={22} /></span>
              <span>LIMPAR</span>
            </button>
          </div>
        </div>
        <hr />
      </>
    )
  }

  const renderCampanhas = () => {
    if (!campanhas.length)
      return null;

    return (
      <div className="row">
        {
          campanhas.map((campanha, index) => {
            return (
              <div key={index} className="col-lg-4 mg-3">
                <label>
                  <div>
                    <input  type="radio" checked={campanha.campanhaId === campanhaId} onChange={() => {
                      model.campanhaId = campanha.campanhaId;
                      setCampanhaId(model.campanhaId);
                    }} />
                    {campanha.nome}
                  </div>
                  <img id="imagem-campanha" src={campanha.urlImagem} className="img-fluid"  />
                </label>
              </div>



            )
          })
        }
      </div>
    )
  }

  return (
    <div>
      <SubHeader title={model.loja?.razaoSocial} />

      <TitlePages title="Escolha a Campanha" />

      <div className="content-pedido-default">

        {renderErrors(errors)}

        {renderFiltro()}

        {renderCampanhas()}

        <div className="row mt-5">

          <div className="col-sm-6 text-left">
            <button className="btn btn-primary btn-navigation" onClick={() => {
              updateRascunho();
              setActiveTab(tabs.tabCliente)
            }} >
              <span><FaAngleLeft size={26} /></span>
              <span>VOLTAR</span>
            </button>
          </div>

          <div className="col-sm-6 d-flex flex-row-reverse">
          <button className={`btn btn-primary btn-navigation btn-navigation-right 
              ${!campanhaId
                ? "btn-disabled"
                : ""
              }`} onClick={() => {
                if (!campanhaId)
                  return;

                updateRascunho();
                setActiveTab(tabs.tabMateriais)
              }}>
              <span>AVANÇAR</span>
              <span><FaAngleRight size={26} /></span>
            </button>
          </div>

          {/* <pre className="text-left">{JSON.stringify(tipoMateriais, null, 2)}</pre> */}

        </div>
      </div>
    </div>
  );
}

export default Campanha;
