import React, { useEffect, useState } from 'react';
import { FaSearch, FaAngleRight } from 'react-icons/fa'
import { Modal, Button } from 'react-bootstrap';

//import './styles.scss';
import api from "../../../../../../api";

import InputTelefone from '../../../../../atoms/InputTelefone';

const CentroDistribuicao = (props) => {
  let solicitacao = props.solicitacao;
  let loja = props.loja || {};
  let setModel = props.setModel;

  solicitacao.entregaCentroDistribuicao = solicitacao.entregaCentroDistribuicao || {};
  const [entregaCentroDistribuicao, setEntregaCentroDistribuicao] = useState(solicitacao.entregaCentroDistribuicao);
  
  const updateEntregaCentroDistribuicao = () => {
    solicitacao.entregaCentroDistribuicao = {...solicitacao.entregaCentroDistribuicao};
    setEntregaCentroDistribuicao(solicitacao.entregaCentroDistribuicao);
  }
  
  return (
    <div className="entrega-fisica-panel">
      <div className="row">
        <div className="col-lg-4">
          <div>
            <label>CEP</label>
            <input type="text" className="form-control" value={loja.enderecoFilial.cep || ""} readOnly/>
          </div>

          <div>
            <label>Endereço</label>
            <input type="text" className="form-control" value={loja.enderecoFilial.logradouro || ""} readOnly />
          </div>

          <div>
            <label>Número</label>
            <input type="text" className="form-control" value={loja.enderecoFilial.numero || ""} readOnly />
          </div>

          <div>
            <label>Complemento</label>
            <input type="text" className="form-control" value={loja.enderecoFilial.complemento || ""} readOnly  />
          </div>

          <div>
            <label>Bairro</label>
            <input type="text" className="form-control" value={loja.enderecoFilial.bairro || ""} readOnly  />
          </div>
        </div>

        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-6">
              <div>
                <label>Estado</label>
                <input type="text" className="form-control" value={loja.enderecoFilial.cidade?.uf || ""} readOnly  />
              </div>

              <div>
                <label>Cidade</label>
                <input type="text" className="form-control" value={loja.enderecoFilial.cidade?.nome || ""} readOnly  />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>Razão Social</label>
                <input type="text" className="form-control" value={loja.enderecoFilial.razaoSocial || ""} readOnly  />
              </div>

              <div>
                <label>CNPJ</label>
                <input type="text" className="form-control" value={loja.enderecoFilial.cnpj || ""}  readOnly />
              </div>
            </div>
            <div />
          </div>

          <div className="row">
            <div className="col-lg-12">
              <hr />

              <h5>Dados do Responsável</h5>  

              <div>
                <label>Nome</label>
                <input type="text" className="form-control" value={entregaCentroDistribuicao.responsavelNome || ""}
                  onChange={(event) => {
                    loja.entregaCentroDistribuicao.responsavelNome = event.target.value;
                    updateEntregaCentroDistribuicao()
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>Telefone</label>
                <InputTelefone value={entregaCentroDistribuicao.responsavelTelefone || ""} onChange={(value) => {
                  loja.entregaCentroDistribuicao.responsavelTelefone = value;
                  updateEntregaCentroDistribuicao()
                }} />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>E-mail</label>
                <input type="text" className="form-control" value={entregaCentroDistribuicao.responsavelEmail || ""}
                  onChange={(event) => {
                    loja.entregaCentroDistribuicao.responsavelEmail = event.target.value;
                    updateEntregaCentroDistribuicao()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CentroDistribuicao;
