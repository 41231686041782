import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Badge, Row, Col } from 'react-bootstrap';
//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Button, FormGroup, InputGroup } from 'react-bootstrap';
import './styles.scss';
import api from '../../../api';
import { Context } from '../../../Context/AuthContext';
import TitlePages from '../../atoms/TitlePages';
import SingleSelect from '../../atoms/SelectSingle';
import history from '../../../Routes/history';
import { dateFormat } from '../../../helpers/Date.Helper';
import getConfiguration from "../../../util/sysConfigUtil";
import dateUtil from "../../../util/dateUtil";

import {
  faHome,
  faFile,
  faFileAlt,
  faExclamationTriangle,
  faSearch,
  faImage,
  faFileImage,
  faFileExcel,
  faAt,
  faUser,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { preventDefault } from '@fullcalendar/common';

const ContentDemandas = () => {
  const [demandas, setDemandas] = useState([]);
  const { getMaterialComunicacaoSelectList, showLoader } = useContext(Context);
  const [materialComunicacaoId, setMaterialComunicacaoId] = useState(0);
  const [materialList, setMaterialList] = useState([]);
  const [solicitacaoId, setSolicitacaoId] = useState(0);
  const maxResults = 2000;

  const [modal, setModal] = useState({
    ativo: false,
    demandaId: null, // Numero da solicitacao da demanda
  });

  var configs;

  useEffect(() => {
    (async () => {
      setMaterialList(await getMaterialComunicacaoSelectList());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      showLoader(true);

      const { data: { value } } = await api.get('/Demanda/list-paged?pageSize=2000');

      setDemandas(value);

      showLoader(false);
    })();
  }, []);

  return (
    <div className="content-demanda">

      <div className="filter-box">
        <Row>
          {/* <label className="col-sm-2 col-form-label  " htmlFor="">Número da solicitação</label> */}
          <div className="col-lg-3 ">
            <InputGroup className="mb-3">
              <input type="text" className="form-control" placeholder="Buscar por número" onChange={solicitacaoIdOnChange} />
              <InputGroup.Append>
                <Button variant="primary" onClick={searchBySolicitacaoId}><FontAwesomeIcon icon={faSearch} /></Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <div className="col-lg-4">
          </div>
          <label className="col-sm-2 col-form-label numero" htmlFor="">Material de Comunicação</label>
          <div className="col-lg-3 ">
            <SingleSelect isClearable={true} onChange={searchByMaterialComunicacao} options={materialList} value={materialComunicacaoId} />
          </div>
        </Row>
      </div>

      <div className="table-query ">
        <div className="table-responsive">
          <table className="table  table-stripped">
            <thead>
              <tr>
                <th>Nro.</th>
                <th>Regional</th>
                <th>Material</th>
                <th>Cliente</th>
                <th>Data limite</th>
                <th>Data Criação</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                demandas?.data?.map((demanda, index2) => {
                  return (
                    <tr key={index2} className={demanda.alert? "alerta" : ""}>
                      <td className="monospace">{demanda.solicitacaoId}</td>
                      <td>{demanda?.regional}</td>
                      <td>{demanda?.materialComunicacao}</td>
                      <td>{demanda?.razaoSocial}</td>
                      <td className="monospace">{demanda?.dataLimiteStr}</td>
                      <td className="monospace">{demanda?.dataCriacaoStr}</td>
                      <td>
                        <Button className="table-button button-action" href={"solicitacao-fluxo/status?solicitacaoId=" + demanda.solicitacaoId}>
                          Visualizar
                        </Button>
                        <Button className="table-button button-action" onClick={() => setModal({ativo: true, demandaId: demanda.solicitacaoId})}>
                          Pegar Solicitação
                        </Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      {ModalAssumirPendencia()}
    </div>
  );

  function FilterBox() {
    return (
      <div className="filter-box">
        <Row>
          {/* <label className="col-sm-2 col-form-label  " htmlFor="">Número da solicitação</label> */}
          <div className="col-lg-3 ">
            <InputGroup className="mb-3">
              <input type="text" className="form-control" placeholder="Buscar por número" onChange={solicitacaoIdOnChange} />
              <InputGroup.Append>
                <Button variant="primary" onClick={searchBySolicitacaoId}><FontAwesomeIcon icon={faSearch} /></Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <div className="col-lg-4">
          </div>
          <label className="col-sm-2 col-form-label numero" htmlFor="">Material de Comunicação</label>
          <div className="col-lg-3 ">
            <SingleSelect isClearable={true} className="form-control " onChange={searchByMaterialComunicacao} options={materialList} value={materialComunicacaoId} />
          </div>
        </Row>
      </div>
    );
  }

  function ModalAssumirPendencia() {
    return(
    <Modal show={modal.ativo} onHide={() => setModal({ativo: !modal.ativo, demandaId: null})} className="modal-discard-draft">
      <Modal.Body>
          <h3>Tem certeza que deseja assumir a demanda da solicitacção n° {modal.demandaId} ?</h3>
          <p>
              Ao clicar em "Assumir", uma pendência de será criada para você. Deseja continuar?
          </p>
          <Row className="buttons">
              <Col className="text-center">
                  <Button variant="secondary" onClick={() => setModal({ativo: !modal.ativo, pendenciaId: null})} className="button">
                      Cancelar
                  </Button>
              </Col>
              <Col className="text-center">
                  <Button variant="danger" onClick={() => pegarDemandaClick(modal.demandaId)} className="button">
                      Assumir
                  </Button>
              </Col>
          </Row>
      </Modal.Body>
    </Modal>
    )
  }

  function getDataLimite(solicitacao){
    const dataLimite = solicitacao?.solicitacaoDados?.dataEnvioArte != null ?
                         solicitacao?.solicitacaoDados?.dataEnvioArte
                         : solicitacao?.solicitacaoDados?.dataVeiculacaoInicio;

    return dateFormat(dataLimite);
  }

  function pegarSolicitacaoClick(solicitacaoId){
    alert("Teste!!! -> " + solicitacaoId);
  }

  async function pegarDemandaClick(solicitacaoId) {
    showLoader(true);

    setModal({ativo: false, demandaId: null})
    const { data: { code } } = await api.post('/Demanda/assumir?solicitacaoId=' + solicitacaoId + "&usuarioId=0");

    if (200 === code) {
            const { data: { value } } = await api.get('/Demanda/list-paged');
            setDemandas(value);
    }

    showLoader(false);
  }


  function solicitacaoIdOnChange(event){
    setSolicitacaoId(event.target.value);
  }

  function searchByMaterialComunicacao(value) {
    setMaterialComunicacaoId(value);

    getDemandas(solicitacaoId, value);
  }

  function searchBySolicitacaoId() {
    getDemandas(solicitacaoId, materialComunicacaoId);
  }

  async function getDemandas(solicitacaoId, materialComunicacaoId){
    showLoader(true);
    var params = "?pageSize=" + maxResults;
    if(solicitacaoId > 0){
      params += "&solicitacaoId=" + solicitacaoId;
    }
    else{
      if(materialComunicacaoId > 0){
        params += "&materialComunicacaoIds=" + materialComunicacaoId;
      }
    }

    const { data: { value } } = await api.get('/Demanda/list-paged' + params);

    setDemandas(value);
    showLoader(false);
  }
}

export default ContentDemandas;
