import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import './style-digital-materials.scss';
import api from '../../../api';
import RequestMaterialsActions from '../../../store/modules/request-materials/actions';

import TitlePages from '../../atoms/TitlePages';
import SubHeader from '../../organisms/SubHeader';

const DigitalMaterials = () => {
  const dispatch = useDispatch();
  const { customer } = useSelector(state => state.requestMaterials);
  const params = useParams();

  if(!customer.codigo) {
    (async () => {
      try {
        const { data: { value } } = await api.get('/Rascunho/get', { params: { rascunhoId: params.rascunhoId } });
        const action = RequestMaterialsActions.setCustomer(value.loja);
        dispatch(action);
        console.log(value)
      } catch (error) {
        console.log(error);
      }
    })();
  }

  return (
    
    <div className="content content-column wrapper-digital-materials">
      <SubHeader title={ customer }/>
      <TitlePages title="Entrega de Materiais Digitais"/>

      <div className="box-info">
        Você selecionou materiais digitais e/ou sem impressão.<br/>
        Preencha os dados abaixo para envio da Arte Final.
      </div>

      <div className="box-input-data">
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="">Nome(s)</label>
              <input type="text" className="form-control" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="">Telefone(s)</label>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="">Email(s)</label>
              <input type="text" className="form-control" />
            </div>
          </div>
        </div>
      </div>

      <div className="buttons-navigation">
        <button  className="btn btn-primary">
          <span><FontAwesomeIcon icon={ faAngleLeft }/></span>
          <span>VOLTAR</span>
        </button>
        <button className="btn btn-primary">
          <span>AVANÇAR</span>
          <span><FontAwesomeIcon icon={ faAngleRight }/></span>
        </button>
      </div>
    </div>
  );
};

export default DigitalMaterials;
