import React from 'react';

import PageWithoutHeader from '../../molecules/PageWithoutHeader';
import ContentUsuarioAutoCadastro from './ContentUsuarioAutoCadastro';

const UsuarioAutoCadastro = () => {
  return (
    <PageWithoutHeader title="CADASTRO DE USUÁRIO">
      <ContentUsuarioAutoCadastro />
    </PageWithoutHeader>
  );
}

export default UsuarioAutoCadastro;
