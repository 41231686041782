import React from 'react';

import './styles.scss';

const LoggedUser = () => {
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));

  return <div className="logged-user">{/* <p>{ userAuth.nome }</p> */}</div>;
};

export default LoggedUser;
