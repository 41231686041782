import React, { useEffect, useState, useContext } from 'react';
import history from '../../../Routes/history';
import './styles.scss';
import api from '../../../api';
import TitlePages from '../../atoms/TitlePages';
import SingleSelect from '../../atoms/SelectSingle';
import { FormGroup, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationArrow,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../../Context/AuthContext';
import InputFileCustom from '../../atoms/InputFile';

const ContentCalled = (props) => {
  const { showLoader } = useContext(Context);
  const [chamado, setChamado] = useState({});
  const [list, setList] = useState([]);
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));
  const { getMotivoSelectList } = useContext(Context);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async () => {
    const formData = new FormData();

    formData.append('chamadoTipoId', chamado.chamadoTipoId);
    formData.append('mensagem', chamado.mensagem);
    formData.append('usuarioId', chamado.usuarioId);
    formData.append('arquivo', chamado.arquivo);

    if (chamado.chamadoTipoId === null) {
      setErrorMessage('Selecione o motivo do seu chamado!');
    } else if (chamado.mensagem === '') {
      setErrorMessage('Escreva sua mensagem  no campo abaixo!');
    } else {
      showLoader(true);
      const data = await api.post('/Chamado/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'WWW-Authenticate': `Bearer ${
            localStorage.getItem('midias_alpargatas') == null
              ? undefined
              : localStorage.getItem('midias_alpargatas').jwtToken
          }`,
        },
      });

      if (data.errors == null) {
        chamado.errors = null;
        history.push({ pathname: '/chamado-enviado'});
      } else {
        setErrorMessage('Não foi possível executar esta operação!');
      }
    }
    showLoader(false);
  };

  const geMotivoId = (value) => {
    setChamado({ ...chamado, label: value });
  };

  useEffect(() => {
    (async () => {
      showLoader(true);
      setList(await getMotivoSelectList());

      setChamado({
        usuarioId: userAuth.userId,
        chamadoTipoId: null,
        mensagem: '',
        errors: '',
      });

      showLoader(false);
    })();
  }, []);

  async function onChangeWithName(value, name) {
    setChamado({
      ...chamado,
      [name]: value,
    });
  }

  function onChangeInput(event) {
    const { value, name } = event.target;

    setChamado({
      ...chamado,
      [name]: value,
    });
  }
  function onChangeFile(event, name) {
    setChamado({
      ...chamado,
      [name]: event.target.files[0],
    });
  }

  return (
    <div className="wrapper-chamado">
      <TitlePages title="ABRIR CHAMADO" />
      <form className="contents">
        <div className="motivo row col-lg-8 col-md-8  ">
          <label className="">Selecione o motivo</label>
          <FormGroup className="select col-lg-5 col-md-5  ">
            <SingleSelect
              value={chamado.chamadoTipoId}
              name="chamadoTipoId"
              onChange={onChangeWithName}
              options={list}
            />
          </FormGroup>
        </div>

        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage('')}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
        <FormGroup className=" mensagem col-lg-8 col-md-8">
          <textarea
            type="input"
            name="mensagem"
            onChange={onChangeInput}
            className="col-lg-12"
            placeholder="Digite sua mensagem"
          ></textarea>
        </FormGroup>

        <div className="enviar col-lg-8 col-md-8 ">
          <FormGroup>
            <InputFileCustom
              className="input"
              name="arquivo"
              onChange={onChangeFile}
              file={chamado.arquivo}
            ></InputFileCustom>
          </FormGroup>
          <FormGroup>
            <Button
              id="submit"
              onClick={onSubmit}
              className="col-lg-3 col-md-3 col-sm-3  botao "
            >
              <FontAwesomeIcon
                className="icon"
                icon={faLocationArrow}
              ></FontAwesomeIcon>
              ENVIAR
            </Button>
          </FormGroup>
        </div>

        <FormGroup className="voltar col-lg-8 col-md-8 ">
          <Button className=" btnVoltar" href="/atendimento">
            <FontAwesomeIcon
              className="icon"
              icon={faAngleLeft}
            ></FontAwesomeIcon>
            VOLTAR
          </Button>
        </FormGroup>
      </form>
    </div>
  );
};

export default ContentCalled;
