import api from '../../api';

const etapa = () => {
  async function getEtapaSelectList() {
    const result = await api.get('/etapa/select-list');
    return result.data.value || [];
  }

  return {
    getEtapaSelectList,
  };
};

export default etapa;
