import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import history from "../../../Routes/history"

import './styles.scss';
import api from '../../../api';
import { Context } from '../../../Context/AuthContext';
import TitlePages from '../../atoms/TitlePages';
import ModalSummary from '../../molecules/ModalSummary';
import ArquivoCategoria from "./ArquivoCategoria";
import SolicitacaoArquivos from "../ContentSolicitacaoFluxoStatus/SolicitacaoArquivos";
import Historico from '../ContentSolicitacaoFluxoStatus/Historico';

const ContentSolicitacaoFluxoEnviarArte = (props) => {

  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(Number.parseInt(paramsLocation.get("solicitacaoId")) || 0)
  const [model, setModel] = useState({});
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [resultModel, setResultModel] = useState({});

  useEffect(() => {
    (async () => {
      showLoader(true);

      const { data: { value: response } } = await api.get('/SolicitacaoFluxo/get-enviar-arte?solicitacaoId=' + solicitacaoId);

      if (!response) {
        history.push('/solicitacao-fluxo/status?solicitacaoId=' + solicitacaoId)
      } else {
      setModel(response);
      }

      showLoader(false);
    })();
  }, []);

  function renderResumoButton() {
    if (!model.solicitacaoId || model.solicitacaoId === 0)
      return null;

    return (
      <div className="text-left">
        <button className="btn btn-primary" onClick={() => { setResumoSolicitacaoId(solicitacaoId) }}>Ver Solicitação</button>
      </div>
    )
  }

  function renderModalSummary() {
    if (resumoSolicitacaoId === 0)
      return null;

    return (
      <ModalSummary solicitacaoId={resumoSolicitacaoId} onClose={() => { setResumoSolicitacaoId(0) }} />
    );
  }

  function renderHistory() {
    if (!model.solicitacaoId || model.solicitacaoId === 0)
      return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    )
  }

  function renderArquivoCategorias() {
    if (!model.arquivoCategorias || model.arquivoCategorias.length === 0)
      return null;

    return (
      <>
      <hr/>
      <div className="row">
      {
        model.arquivoCategorias.map((arquivoCategoria, index) => {
          return (
            <ArquivoCategoria key={index} arquivoCategoria={arquivoCategoria} />
          )
        })
      }
      </div>
      </>
    )
  }

  function enviar() {
    showLoader(true);

    let postModel = { ...model };

    (async () => {
      const { data: response } = await api.post('/SolicitacaoFluxo/upload-files', postModel);

      //value == true => sucesso
      if (response.value) {
        setModel({});

        history.push('/solicitacao-fluxo/confirmar-envio?solicitacaoId=' + solicitacaoId)
      } 
      
      setResultModel(response);

      showLoader(false);
    })();
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length === 0)
      return null;

    return (
      <div className="alert alert-danger">
        {
          resultModel.errors.map((error, index) => {
            return (
              <span key={index}>{error}</span>
            )
          })
        }
      </div>
    )
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length)
      return null;

    return (
      <div className="alert alert-success">
        { resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    )
  }

  return (
    <div className="wrapper-content-solicitacaofluxo-enviar-arte">
      <TitlePages title={"Solicitação " + solicitacaoId + " - Enviar Arte"} />

      <div className="content-solicitacaofluxo">

        {/* {<pre>{JSON.stringify(resultModel, null, 2)}</pre>} */}

        {renderErrors()}

        {renderSuccess()}

        {renderResumoButton()}

        {renderArquivoCategorias()}

        <div className="mt-5">
          <button className="btn btn-primary" onClick={enviar}>Enviar</button>
        </div>

        <hr />

        <SolicitacaoArquivos solicitacaoId={model.solicitacaoId || 0} />

        {/* {renderSummary()} */}

        {renderHistory()}

        {renderModalSummary()}

        {/* {renderModalComment()} */}

        {/* {<pre className="text-left">{JSON.stringify(model, null, 2)}</pre>} */}

      </div>
    </div>
  );
}

export default ContentSolicitacaoFluxoEnviarArte;
