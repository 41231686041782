import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faFile,
  faFileAlt,
  faExclamationTriangle,
  faSearch,
  faImage,
  faFileImage,
  faFileExcel,
  faAt,
  faUser,
  faSignOutAlt,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';

import rascunho from '../../../assets/brf/imgs/icons/rascunho.png';
import consultar from '../../../assets/brf/imgs/icons/consultar.png';
import pendencias from '../../../assets/brf/imgs/icons/pendencias.png';
import relatorios from '../../../assets/brf/imgs/icons/relatorios.png';
import solicitarCampanha from '../../../assets/brf/imgs/icons/solicitar_campanha.png';
import solicitarMateriais from '../../../assets/brf/imgs/icons/solicitar_materiais.png';
import artesFacil from '../../../assets/brf/imgs/icons/artes_facil.png';

import { Context } from '../../../Context/AuthContext';

import './styles.scss';
import Brand from '../../atoms/Brand';
import { Link } from 'react-router-dom';
import DadosUsuario from '../../pages/DadosUsuario';

const NavigationMenu = () => {
  const { handleLogout } = useContext(Context);
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));

  return (
    <div className="menu">
      <div className="header-menu">
        <div>
          <Brand white />
          <p className="mt-3">Bem Vindo(a)</p>
          <h3>{userAuth && userAuth.nome}</h3>
        </div>
      </div>
      <div className="body-menu">
        <ul>
          <li>
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
              <span>Home</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/artes-facil">
              <FontAwesomeIcon icon={faFile} />
              <span>Solicitar Artes fácil</span>
            </Link>
          </li> */}
          {/* <li>
            <Link to="/pedido-campanha">
              <FontAwesomeIcon icon={faFile} />
              <span>Adaptação Campanha</span>
            </Link>
          </li> */}
          <li>
            <Link to="/pedido-materiais">
              <FontAwesomeIcon icon={faFile} />
              <span>Solicitar Diversos Materiais</span>
            </Link>
          </li>
          <li>
            <Link to="/rascunho">
              <FontAwesomeIcon icon={faFileAlt} />
              <span>Rascunhos</span>
            </Link>
          </li>
          <li>
            <Link to="/historico-pedidos">
              <FontAwesomeIcon icon={faFileAlt} />
              <span>Histórico de Pedidos</span>
            </Link>
          </li>
          <li>
            <Link to="/pendencias">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <span>Pendências</span>
            </Link>
          </li>
          {
            userAuth?.aprovaUsuario &&
            <li>
              <Link to="/usuario-aprovacao">
                <FontAwesomeIcon icon={faUserCheck} />
                <span>Aprovar Usuários</span>
              </Link>
            </li>
          }
          <li>
            <Link to="/consulta">
              <FontAwesomeIcon icon={faSearch} />
              <span>Consulta</span>
            </Link>
          </li>
          <li>
            <Link to="/banco-de-imagens">
              <FontAwesomeIcon icon={faImage} />
              <span>Banco de Imagens</span>
            </Link>
          </li>
          <li>
            <Link to="/banco-de-artes">
              <FontAwesomeIcon icon={faFileImage} />
              <span>Banco de Artes</span>
            </Link>
          </li>
          <li>
            <Link to="/">
              <FontAwesomeIcon icon={faFileExcel} />
              <span>Relatórios</span>
            </Link>
          </li>
          <li>
            <Link to="/atendimento">
              <FontAwesomeIcon icon={faAt} />
              <span>Atendimento</span>
            </Link>
          </li>
          <li>
            <Link to="/meus-dados">
              <FontAwesomeIcon icon={faUser} />
              <span>Meus Dados</span>
            </Link>
          </li>
        </ul>
        <div className="logout-button">
          <Button variant="primary" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span>Sair</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NavigationMenu;
