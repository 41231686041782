import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Context } from '../../../Context/AuthContext';

import './styles.scss';
import api from '../../../api';

const OpenCalled = () => {
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));
  const [chamados, setChamados] = useState([]);
  const { showLoader } = useContext(Context);

  useEffect(() => {
    (async () => {
      showLoader(true);
      const {
        data: { value },
      } = await api.get('/Chamado/get-by-usuarioid', {
        params: {
          usuarioId: userAuth.userId,
        },
      });
      setChamados(value);
      showLoader(false);
    })();
  }, []);

  let abertos = 0;
  chamados.forEach((element, index, array) => {
    if (element.dataFinalizacao == null) {
      abertos++;
    }
  });

  return (
    <div className='abrirChamado'>
      <div className="title ">Abrir Chamado</div>
      <div className="info">
        <div className="row">
          <div className="col-md-12 botoes">
            <Button
              href="/chamado"
              className=" col-lg-5 col-sm-5 query-button-called"
              variant="primary"
            >
              <small>ABRIR NOVO CHAMADO</small>
            </Button>
            <Button
              
              id="acompanhar"
              href="/acompanhar-chamado"
              className={"col-lg-5 col-sm-5  query-button-called " + (chamados.length > 0? "corbotao" : "")}
              variant = "primary"
            >
              <small>CHAMADOS ABERTOS ({abertos}) </small>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenCalled;
