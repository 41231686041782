import React from 'react';

import './styles.scss';

import HeaderApp from '../../../organisms/Header/HeaderApp';
import Bottom from '../../../organisms/Bottom';
import ContentSolicitacaoFluxoConfirmarEnvio from '../../../organisms/ContentSolicitacaoFluxoConfirmarEnvio';

const SolicitacaoFluxoConfirmarEnvio = () => {
  return (
    <>
      <HeaderApp/>
        <ContentSolicitacaoFluxoConfirmarEnvio/>
      <Bottom />
    </>
  );
};

export default SolicitacaoFluxoConfirmarEnvio;
