import React from 'react';

import HeaderLogin from '../../organisms/Header/HeaderLogin';
import ContentLogin from '../../organisms/ContentLogin';
import Bottom from '../../organisms/Bottom';

const Login = () => {
  return (
    <>
      <HeaderLogin/>
      <ContentLogin />
      <Bottom />
    </>
  );
}

export default Login;
