import React, { useEffect, useState } from 'react';
import Scroll from 'react-scrollbar';

import './styles.scss';

const TipoMaterialLista = (props) => {
  const {model} = props;
  const {entities} = props;
  const {materiais} = props;
  const {solicitacoes} = props;
  const {updateSolicitacoes} = props;
  
  const addRemoveItem = (event) => {
    if (event.target.checked) {
      const material = materiais.find(obj => obj.materialComunicacaoId === parseInt(event.target.value));
      
      const solicitacao = {
        materialComunicacaoId: material.materialComunicacaoId, 
        materialComunicacao: material
      };

      model.solicitacoes.push(solicitacao);
      updateSolicitacoes();
    } else {
      const indexItem = solicitacoes.findIndex(obj => obj.materialComunicacaoId === parseInt(event.target.value));
      model.solicitacoes.splice(indexItem, 1);
      updateSolicitacoes(solicitacoes);
    }
  };

  return (
    <div className="lista-materiais">
      <div className="header-card">
        {props.title}
      </div>
      <Scroll speed={0.5} className="body-card">
        <ul>
          {materiais.map((material, index) => (
              <div key={index}>
                <input type="checkbox" name="" id={`check-material-${material.materialComunicacaoId}`} value={material.materialComunicacaoId} 
                  onChange={addRemoveItem} 
                  checked={solicitacoes.findIndex((solicitacao) => solicitacao.materialComunicacaoId === material.materialComunicacaoId) !== -1}
                />

                <label htmlFor={`check-material-${material.materialComunicacaoId}`} >
                  <li key={index} style={{cursor:'pointer'}}>
                    {material.nome}
                  </li>
                </label>
              </div>
          )
          )}
        </ul>
      </Scroll>
    </div>
  );
};

export default TipoMaterialLista;
