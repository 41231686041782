import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import api from '../../../api';
import history from '../../../Routes/history';

import TitlePages from '../../atoms/TitlePages';
import SubHeader from '../SubHeader';
import Card from '../../molecules/Card';

import RequestMaterialsActions from '../../../store/modules/request-materials/actions';
import { useState } from 'react';

const ContentSelectMaterials = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [graphicMaterials, setGraphicMaterials] = useState([]);
  const [digitalMaterials, setDigitalMaterials] = useState([]);
  const [folderMaterials, setFolderMaterials] = useState([]);

  const { customer } = useSelector((state) => state.requestMaterials);

  const redirectPage = () => {
    history.push('/entrega-de-materiais');
  };

  const returnToPage = () => {
    history.push('/escolha-o-cliente');
  };

  if (!customer.codigo) {
    (async () => {
      try {
        const {
          data: { value },
        } = await api.get('/Rascunho/get', {
          params: { rascunhoId: params.rascunhoId },
        });
        const action = RequestMaterialsActions.setCustomer(value.loja);
        dispatch(action);
      } catch (error) {
        console.log(error);
      }

      // const customerStorange = JSON.parse(localStorage.getItem('_brf_client'));

      // if(customerStorange) {
      //   const { data: { value } } = await api.get('/Solicitacao/pesquisar-lojas', {
      //     params: { codigoCnpjRazaoSocial: customerStorange.code },
      //   });

      //   const action = RequestMaterialsActions.setCustomer(value[0]);
      //   dispatch(action);
      // }
    })();
  }

  useEffect(() => {
    (async () => {
      const {
        data: { value },
      } = await api.get('/Solicitacao/list-materiais-comunicacao');
      let graphics = [];
      let digital = [];
      let folders = [];

      // eslint-disable-next-line
      value.map((item) => {
        if (item.materialComunicacaoTipo.nome === 'MATERIAIS GRAFICOS')
          graphics.push(item);
        if (item.materialComunicacaoTipo.nome === 'MATERIAIS DIGITAIS')
          digital.push(item);
        if (item.materialComunicacaoTipo.nome === 'ENCARTES')
          folders.push(item);
      });
      setGraphicMaterials(graphics);
      setDigitalMaterials(digital);
      setFolderMaterials(folders);
    })();
  }, []);

  return (
    <div className="content content-column">
      <SubHeader title={customer} />
      <TitlePages title="Selecione os Materiais" />
      <div className="content-cards">
        <Card title="Encartes" items={folderMaterials} type="folders" />
        <Card
          title="Materiais Digitais"
          items={digitalMaterials}
          type="digitals"
        />
        <Card
          title="Materiais Gráficos (MPDV)"
          items={graphicMaterials}
          type="graphics"
        />
      </div>
      <div className="buttons-navigation">
        <button onClick={returnToPage} className="btn btn-primary">
          <span>
            <FontAwesomeIcon icon={faAngleLeft} />
          </span>
          <span>VOLTAR</span>
        </button>
        <button onClick={redirectPage} className="btn btn-primary">
          <span>AVANÇAR</span>
          <span>
            <FontAwesomeIcon icon={faAngleRight} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default ContentSelectMaterials;
