import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Row, Col, Alert, Tabs, Tab, Sonnet, FormGroup, Table } from 'react-bootstrap';
import {
  FaHome,
  FaAngleRight,
  FaSearch,
  FaPeopleCarry,
  FaUserCheck,
  FaFileUpload,
  FaChevronRight,
  FaChevronLeft,
  FaPlusCircle,
  FaCalendarCheck,
  FaFileDownload,
  FaTelegramPlane,
  FaImages
} from 'react-icons/fa';

import './styles.scss';
//import history from '../../../../../../Routes/history';
import api from '../../../../../../api';
//import TitlePages from '../../../atoms/TitlePages';
//import LoaderAction from '../../../../store/modules/loader/actions';
//import InputFileCustom from "../../../atoms/InputFile";
//import { faCaretRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from '../../../../../../Context/AuthContext';
//import SingleSelect from '../../../atoms/SelectSingle';

const ExtratoDownloadsBrasil = (props) => {
  const { showLoader } = useContext(Context);

  const regionais = props.regional[0]?.options;
  const filtro = props.filtro;

  async function downloadExcel(regionalId) {
    showLoader(true);
    var paramFiltro = {...filtro};
    paramFiltro.regionalId = regionalId;
    var result = await api.get('/ExtratoSolicitacaoCusto/get-extrato-custo-brasil-as-bytes', {
      params: paramFiltro,
    });

    if (result.data && result.data.value) {
      var today = new Date();
      var date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
      var file = _base64ToByteArray(result.data.value);
      var blob = new Blob([file], { type: "arraybuffer" });

      var fileName = "extrato_custo_" + date + ".xlsx";
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      var fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();

      showLoader(false);
    }
    else {
      showLoader(false);
    }
  }

  function _base64ToByteArray(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes;
  }

  return (
    (filtro?.ano > 0) && (filtro?.mes > 0) ?
      <div className="extrato-downloads">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Regional</th>
              <th>Mês</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {regionais?.map((item, index) => {
              if (filtro?.regionalId == null || filtro?.regionalId == '' || filtro?.regionalId == item?.value) {
                var regionalId = item?.value;
                if(regionalId == 1000){
                  regionalId = 0;
                }
                var mes = filtro?.mes;
                if(filtro?.mes < 10){
                  mes = "0" + mes;
                }
                return (
                  <tr key={index}>
                    <td>
                      {item?.label}
                    </td>
                    <td>
                      {mes}/{filtro?.ano}
                    </td>
                    <td>
                      <Button className="btn-extrato" disabled={""} onClick={() => { downloadExcel(regionalId); }}> <FaFileDownload size="20" />&nbsp; BAIXAR</Button>
                    </td>
                  </tr>
                )
              }
              else {
                return (<div></div>);
              }
            })}
          </tbody>
        </Table>
      </div>
      : <div></div>

  );

}

export default ExtratoDownloadsBrasil;
