import React, { useEffect, useState } from 'react';

import { entregaTipoNomes } from '../../../../_constants';

import RodapeEndereco from './RodapeEndereco';

const RodapeEnderecos = (props) => {
  let entregaLojasParticipantes = props.entregaLojasParticipantes;
  let entities = props.entities;
  let solicitacao = props.solicitacao;
  let solicitacaoDados = solicitacao.solicitacaoDados || {};
  let updateSolicitacaoDados = props.updateSolicitacaoDados;

  let entregaTipoFisica = (entities.tipoEntregas || []).find((tipoEntrega) => {
    return (
      tipoEntrega.entregaFisica &&
      tipoEntrega.entregaTipoId === solicitacao.entregaTipoId
    );
  });

  const getInitialSolicitacaoRodapes = () => {
    return (solicitacao.solicitacaoRodapes || []).length > 0
      ? solicitacao.solicitacaoRodapes
      : entregaTipoFisica?.nome === entregaTipoNomes.lojaLoja &&
        (entregaLojasParticipantes || []).length > 0
      ? entregaLojasParticipantes.map((lojaParticipante) => {
          return {
            CEP: lojaParticipante.CEP,
            endereco: lojaParticipante.logradouro,
            numero: lojaParticipante.numero,
            bairro: lojaParticipante.bairro,
            complemento: lojaParticipante.complemento,
            cidadeId: lojaParticipante.cidade.cidadeId,
            uf: lojaParticipante.cidade.uf,
            cidade: lojaParticipante.cidade,
            descricaoLoja: lojaParticipante.descricaoLoja,
          };
        })
      : [];
  };

  const [solicitacaoRodapes, setSolicitacaoRodapes] = useState(
    getInitialSolicitacaoRodapes(),
  );

  const updateSolicitacaoRodapes = () => {
    solicitacao.solicitacaoRodapes = [...solicitacaoRodapes];
    setSolicitacaoRodapes(solicitacao.solicitacaoRodapes);
    updateSolicitacaoDados(solicitacaoDados);
  };

  const removerSolicitacaoRodape = (index) => {
    solicitacaoRodapes.splice(index, 1);
    updateSolicitacaoRodapes();
  };

  useEffect(() => {
    solicitacao.solicitacaoRodapes = getInitialSolicitacaoRodapes();
    setSolicitacaoRodapes(solicitacao.solicitacaoRodapes);
  }, [solicitacao]);

  return (
    <div className="row">
      {solicitacaoRodapes.map((solicitacaoRodape, index) => {
        return (
          <div key={index} className="col-lg-12">
            <RodapeEndereco
              entities={entities}
              solicitacaoRodape={solicitacaoRodape}
              updateSolicitacaoRodapes={updateSolicitacaoRodapes}
              removerSolicitacaoRodape={() => removerSolicitacaoRodape(index)}
            />
          </div>
        );
      })}

      <div className="col-lg-12">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            solicitacaoRodapes.push({});
            updateSolicitacaoRodapes();
          }}
        >
          Adicionar Rodapé
        </button>

        <hr />
      </div>
    </div>
  );
};

export default RodapeEnderecos;
