import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

import { Context } from '../../../Context/AuthContext';

import { dateFormat } from '../../../helpers/Date.Helper';

import api from '../../../api';

import './styles.scss';

const ModalSolicitarNovamente = (props) => {
  const history = useHistory();
  const { showLoader } = useContext(Context);

  let solicitacaoId = props.solicitacaoId;

  const show = () => {
    return solicitacaoId > 0;
  };
  const handleClose = () => {
    props.onClose();
    solicitacaoId = 0;
  };

  const solicitar = () => {
    (async () => {
      showLoader(true);
      const response = await api.post(
        `/Solicitacao/criar-copia-solicitacao/${solicitacaoId}`,
      );

      if (response?.data?.value) {
        let rascunho = response.data.value;

        history.push(`/pedido-materiais/${rascunho.rascunhoId}`);
      }

      showLoader(false);
    })();
  };

  const verPedido = () => {
    (async () => {
      showLoader(true);
      const response = await api.get(`/Pedido/get-pedido-by-solicitacao`, {
        params: { solicitacaoId: solicitacaoId },
      });

      if (response?.data?.value) {
        let pedido = response.data.value;

        history.push(`resumo-pedido/${pedido.pedidoId}`);
      }

      showLoader(false);
    })();
  };

  return (
    <Modal
      className="modal-solicitar-novamente"
      show={show()}
      onHide={handleClose}
    >
      <Modal.Body className="text-center">
        <h4 className="m-3">Deseja solicitar novamente?</h4>

        <p className="m-3">
          Deseja solicitar novamente apenas este material <br />
          ou deseja ver o pedido completo?
        </p>

        <div className="p-3">
          <Button variant="primary" className="mb-3" onClick={solicitar}>
            SOLICITAR APENAS ESTE MATERIAL
          </Button>

          <Button variant="primary" className="mb-3" onClick={verPedido}>
            VER PEDIDO COMPLETO
          </Button>

          <Button
            variant="danger"
            onClick={handleClose}
            className="button-close"
          >
            CANCELAR
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSolicitarNovamente;
