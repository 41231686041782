import React, {useState, useContext, useEffect} from 'react';
import {useParams, useLocation, useHistory} from 'react-router-dom';

import { Context } from '../../../Context/AuthContext';

const Acesso = () => {
  const { loginWithToken } = useContext(Context);
  const history = useHistory();

  let search = new URLSearchParams(useLocation().search);
  const [token, setToken] = useState(search.get('token') ? search.get('token') : '');

  useEffect(() => {
    if (token.length)
      (async () => {
        await loginWithToken(token)
      })();
    else
      history.push("/login");
    
  }, [])

  return <></>;
}

export default Acesso;