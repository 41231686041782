import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

import './styles.scss';

const ModalDiscardCalled = (props) => {

    let chamados = props.chamados;

    const handleDiscard = async () => {
        props.onDiscard();
    }

    const handleClose = () => {
        props.onClose();
    }

    const show = () => {
        return chamados && chamados > 0;
    }

    return (
        <Modal show={show()} onHide={handleClose} className="modal-discard-called">
            <Modal.Header closeButton className="header">
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>Tem certeza que deseja encerrar este chamado?</h3>
                <p>
                   Ao clicar em encerrar, seu chamado será finalizado no sistema e se dará como resolvido. Deseja continuar?
                </p>
                <Row className="buttons">
                    <Col className="text-center">
                        <Button variant="secondary" onClick={handleClose} className="button">
                            CANCELAR
                        </Button>
                    </Col>
                    <Col className="text-center">
                        <Button variant="danger" onClick={handleDiscard} className="button">
                            FINALIZAR
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default ModalDiscardCalled;
