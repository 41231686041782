import React from 'react';

import './styles.scss';

import TitlePages from '../../atoms/TitlePages';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';



const ContentCalledSent = (props) => {
  return (
    <div className="wrapper-chamado-enviado">
      <TitlePages title="ATENDIMENTO" />
      <div className='contents '>
        <div className='enviado col-lg-8 '>
          <span>Chamado enviado com sucesso!</span>  
          <small>Nossa equipe irá analisá-lo e entrar em contato para ajudá-lo.</small>
          <Button className='btnVoltar' href='/atendimento'><FontAwesomeIcon className='icon' icon={faAngleLeft}></FontAwesomeIcon>VOLTAR</Button>
        </div>
      </div>
    </div>
  );
}

export default ContentCalledSent;
