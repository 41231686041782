import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
import history from '../../../Routes/history';
import api from '../../../api';
import { Context } from '../../../Context/AuthContext';

const ContentNewPassword = (props) => {
  const params = new URLSearchParams(useLocation().search);
  const { showLoader } = useContext(Context);

  const initialState = {
    userid: params.get('user'),
    token: params.get('token'),
    novasenha: '',
    confirmacaosenha: '',
  };

  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState([]);

  function onChange(event) {
    const { value, name } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  }

  async function onSubmit(event) {
    event.preventDefault();

    setErrors([]);
    showLoader(true);

        setErrors([]);
        showLoader(true);

        const result = await api.post('/Usuario/nova-senha', {
            userId: values["userid"],
            token: values["token"],
            novaSenha: values["novasenha"],
            confirmacaoSenha: values["confirmacaosenha"]
        });

    showLoader(false);
    if (
      result &&
      result.data &&
      result.data.errors &&
      result.data.errors.length > 0
    ) {
      setErrors(result.data.errors);
    } else {
      history.push('/senha-alterada');
    }
  }

  return (
    <>
      <p className="text-center m-0">
        Cadastre abaixo uma nova senha de acesso para a sua conta. <br></br>
        Dica: Escolha uma senha segura e de fácil memorização.<br></br>
        <br></br>
      </p>
      {errors.length > 0 ? (
        <div className="alert alert-danger">
          {errors.map((e) => {
            return <label>{e}</label>;
          })}
        </div>
      ) : (
        ''
      )}
      <FormGroup>
        <label>Nova senha</label>
        <input
          type="password"
          name="novasenha"
          className="form-control"
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup>
        <label>Confirme a nova senha</label>
        <input
          type="password"
          name="confirmacaosenha"
          className="form-control"
          onChange={onChange}
        />
      </FormGroup>
      <div className="row">
        <div className="col-6">
          <a className="btn btn-link" href="/login">
            <FontAwesomeIcon icon={faArrowLeft} size="1x" /> Voltar
          </a>
        </div>
        <div className="col-6 text-right">
          <Button onClick={onSubmit} variant="primary" type="button">
            <FontAwesomeIcon icon={faPaperPlane} size="1x" /> Enviar
          </Button>
        </div>
      </div>
    </>
  );
};

export default ContentNewPassword;
