import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import history from '../../../Routes/history';

import './styles.scss';
import api from '../../../api';
import { Context } from '../../../Context/AuthContext';
import TitlePages from '../../atoms/TitlePages';
import SolicitacaoArquivos from '../ContentSolicitacaoFluxoStatus/SolicitacaoArquivos';

const ContentSolicitacaoFluxoConfirmarEnvio = (props) => {
  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0,
  );
  const [model, setModel] = useState({
    solicitacaoId: Number.parseInt(paramsLocation.get('solicitacaoId')) || 0,
    observacao: '',
  });
  const [resultModel, setResultModel] = useState({});

  function renderForm() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <div>
        {/* <textarea className="form-control" onChange={(event) => {
          let newModel = {...model};
          newModel.observacao = event.target.value;
          setModel(newModel);
        }} value={model.observacao || ''} rows="6"></textarea> */}

        <div className="mt-5 text-center">
          <button className="btn btn-primary" onClick={confirmar}>
            Confirmar
          </button>
        </div>
      </div>
    );
  }

  function confirmar() {
    showLoader(true);

    let postModel = { ...model };

    (async () => {
      const { data: response } = await api.post(
        '/SolicitacaoFluxo/confirm-upload',
        postModel,
      );

      //value == true => alterado com sucesso
      if (response.value) {
        setModel({});
      }

      setResultModel(response);

      showLoader(false);
    })();
  }

  function renderArquivos() {
    if (!model.solicitacaoId || model.solicitacaoId === 0)
      return null;

    return (
      <SolicitacaoArquivos solicitacaoId={model.solicitacaoId} />
    )
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <span key={index}>{error}</span>;
        })}
      </div>
    );
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  return (
    <div className="wrapper-content-solicitacaofluxo-confirmar-arte">
      <TitlePages
        title={'Solicitação ' + solicitacaoId + ' - Confimar envio'}
      />

      <div className="content-solicitacaofluxo">
        {/* {<pre>{JSON.stringify(resultModel, null, 2)}</pre>} */}

        {renderErrors()}

        {renderSuccess()}

        {renderArquivos()}

        {renderForm()}

        <hr />

        <button
          className="btn btn-link"
          onClick={() => {
            history.goBack();
          }}
        >
          Voltar
        </button>

        {/* {<pre className="text-left">{JSON.stringify(model, null, 2)}</pre>} */}
      </div>
    </div>
  );
};

export default ContentSolicitacaoFluxoConfirmarEnvio;
