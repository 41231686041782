import React from 'react';

import { Context, userData } from '../../../../Context/AuthContext';
import { useParams, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFilter, faPlus, faRecycle, faTrash, faCheck, faBan } from '@fortawesome/free-solid-svg-icons';
import { FaSave, FaArrowLeft } from 'react-icons/fa';

import api from '../../../../api';
import HeaderApp from '../../../organisms/Header/HeaderApp';
import Bottom from '../../../organisms/Bottom';
import PageTitle from '../../../atoms/PageTitle';
import Select from 'react-select';

const ContentUsuarioEditor = () => {
  const params = useParams();
  const history = useHistory();

  const { showLoader, userData, setUserData } = React.useContext(Context);
  const [usuario, setUsuario] = React.useState();
  const [regionalList, setRegionalList] = React.useState([]);
  const [usuarioTiposList, setUsuarioTiposList] = React.useState([]);
  const [usuarioSuperiorList, setUsuarioSuperiorList] = React.useState([]);
  const [formData, setFormData] = React.useState({
    usuarioId: '',
    nome: '',
    cpf: '',
    telefone: '',
    email: '',
    regional: '',
    filial: '',
    usuarioTipo: '',
    superior: '',
    erros: [],
  });

  //-------------------------------------------------------------------------------------------
  // Effects
  React.useEffect(() => {
    (async () => {
      showLoader(true);

      setRegionalList(await getRegionaisSelectList());
      setUsuarioTiposList(await getUsuarioTiposSelectList());
      setUsuarioSuperiorList(await getUsuarioSuperiorSelectList());

      const usuario_ = await getUsuario(params.usuarioId);

      if (usuario_) {
        setUsuario(usuario_);
        setFormData({
          ...formData,
          usuarioId: usuario_.usuarioId,
          nome: usuario_.nome,
          cpf: usuario_.cpf,
          telefone: usuario_.telefone,
          email: usuario_.email,
        });
      }

      let teste = formData;
      showLoader(false);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (!formData.regional) {
        selecioneRegionalInicial();
      }
      if (!formData.usuarioTipo){
        selecioneTipoUsuarioInicial();
      }

      let teste = formData;
    })();
  }, [formData, regionalList, usuarioTiposList]);

  //-------------------------------------------------------------------------------------------
  // Funcoes
  const selecioneRegionalInicial = () => {
    if (usuario && !formData.regional) {
      const selectedItem = regionalList[0]?.options.find((x) => x.label === usuario?.regional?.nome)
      if (selectedItem) {
        setFormData({
          ...formData,
          regional: selectedItem,
        });
      }
    }
  }

  const selecioneTipoUsuarioInicial = () => {
    if (usuario && !formData.usuarioTipo) {
      const selectedItem = usuarioTiposList[0]?.options.find((x) => x.label === usuario?.usuarioTipo?.nome)
      if (selectedItem) {
        setFormData({
          ...formData,
          usuarioTipo: selectedItem,
        });
      }
    }
  }

  //-------------------------------------------------------------------------------------------
  // API
  const getUsuarioTiposSelectList = async () => {
    let result = await api.get('/CadastroUsuario/get-usuariotipos-selectlist');
    return result.data.value || [];
  }

  const getUsuarioSuperiorSelectList = async () => {
    let result = await api.get('/CadastroUsuario/get-usuariosuperior-selectlist');
    return result.data.value || [];
  }

  const getRegionaisSelectList = async () => {
    let result = await api.get('/CadastroUsuario/get-regionais-selectlist');
    return result.data.value || [];
  }

  const getUsuario = async (usuarioId) => {
    let result = await api.get('/Usuario/get?id=' + usuarioId);
    return result.data.value || {};
  }

  //-------------------------------------------------------------------------------------------
  // Render
  return (
    <div className="container-fluid mb-5">
      <div className="container">
        <div className="row">

          {/* Errors -------------------------------------------------------------------------- */}
          <div className="col-md-10 offset-md-1 mb-2 mt-2">
            {formData.erros && formData.erros.length !== 0 && (
              <div className="alert alert-danger">
                {formData.erros.map((value, index) => {
                  return (
                    <p className="m-0" key={index}>
                      {value}
                    </p>
                  );
                })}
              </div>
            )}
          </div>

          {/* Nome ---------------------------------------------------------------------------- */}
          <div className="col-md-10 offset-md-1 mb-3">
            <label>Nome Completo</label>
            <input
              type="text"
              className="form-control"
              value={formData.nome}
              onChange={(e) =>
                setFormData({ ...formData, nome: e.target.value })
              }
            />
          </div>

          {/* CPF ----------------------------------------------------------------------------- */}
          <div className="col-md-5 offset-md-1 mb-3">
            <label>CPF</label>
            <input
              type="text"
              className="form-control"
              value={formData.cpf}
              onChange={(e) => {
                let cpfNoMask = e.target.value.replace(/\D+/g, '');
                if (cpfNoMask.length <= 11) {
                  setFormData({
                    ...formData,
                    cpf: e.target.value.replace(/\D+/g, ''),
                  });
                }
              }}
            />
          </div>

          {/* Telefone ------------------------------------------------------------------------ */}
          <div className="col-md-5 ">
            <label>Telefone de contato</label>
            <input
              type="text"
              className="form-control"
              value={formData.telefone}
              onChange={(e) => {
                let phoneNoMask = e.target.value.replace(/\D+/g, '');
                if (phoneNoMask.length <= 11) {
                  setFormData({
                    ...formData,
                    telefone: e.target.value.replace(/\D+/g, ''),
                  });
                }
              }}
            />
          </div>

          {/* Email --------------------------------------------------------------------------- */}
          <div className="col-md-10 offset-md-1 mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>

          {/* Regional ------------------------------------------------------------------------ */}
          <div className="col-md-10 offset-md-1 mb-3">
            <label>Regional</label>
            <Select
              name="regionais"
              options={regionalList}
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item) => {

                setFormData({
                  ...formData,
                  regional: item,
                  filial: '',
                });
              }}
              value={formData.regional}
            />
          </div>

          {/* Tipo Usuario -------------------------------------------------------------------- */}
          <div className="col-md-5 offset-md-1 mb-3">
            <label>Função na empresa</label>
            <Select
              name="usuariotipos"
              options={usuarioTiposList}
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item) => {
                setFormData({
                  ...formData,
                  usuarioTipo: item,
                  superior: '',
                });
              }}
              value={formData.usuarioTipo}
            />
          </div>

          {/* Usuario Superior ---------------------------------------------------------------- */}
          <div className="col-md-5 mb-3">
            <label>Supervisor</label>
            <Select
              name="usuariossuperiores"
              options={usuarioSuperiorList}
              classNamePrefix="select"
              placeholder="Selecione..."
              isDisabled
              onChange={(item) => {
                setFormData({
                  ...formData,
                  superior: item,
                });
              }}
              value={formData.superior}
            />
          </div>

          <hr></hr>

          {/* Botao Voltar -------------------------------------------------------------------- */}
          <div className="col-5 mt-2 col btn-avancar-voltar d-flex flex-row-reverse">
            <a href='/usuario-aprovacao'>
              <button
                className={`btn btn-primary btn-lg btn-navigation btn-navigation-right pl-5 pr-5`}
              >
                <span className="mr-2">
                  <FaArrowLeft size={26} />
                </span>
                <span>VOLTAR</span>
              </button>
            </a>
          </div>

          {/* Botao Salvar -------------------------------------------------------------------- */}
          <div className="col-5 offset-1 mt-2 col btn-avancar-voltar d-flex flex-row-reverse">
            <button
              className={`btn btn-primary btn-lg btn-navigation btn-navigation-right pl-5 pr-5`}
              onClick={() => { }}
            >
              <span className="mr-2">
                <FaSave size={26} />
              </span>
              <span>SALVAR</span>
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}

const UsuarioEditor = () => {
  return (
    <>
      <HeaderApp />
      <PageTitle title="Editar Usuário Auto-Cadastrados" />
      <hr></hr>
      <ContentUsuarioEditor />
      <Bottom />
    </>
  );
}

export default UsuarioEditor;
