import React, { useState, useEffect, useContext } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import { FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faEraser,
  faPlus,
  faIgloo,
} from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../../Context/AuthContext';

import '../FilterArtes/styles.scss';
import api from '../../../api';

const FilterArtes = (props) => {
  const { showLoader } = useContext(Context);

  const [materialComunicacao, setMaterialComunicacao] = useState([]);
  const [campanhas, setCampanhas] = useState([]);

  const [materialComunicacaoSelecionadas, setMaterialComunicacaoSelecionadas] =
    useState([]);
  const [campanhasSelecionados, setCampanhasSelecionados] = useState([]);
  const [solicitacaoId, setSolicitacaoId] = useState('');
  const [codigo, setCodigo] = useState('');

  function onChangeSolicitacaoId(event) {
    const { value, name } = event.target;
    setSolicitacaoId(value);
  }

  function onChangeCodigo(event) {
    const { value, name } = event.target;
    setCodigo(value);
  }

  const onChangeMaterialComunicacao = (event) => {
    let matComunicacao = materialComunicacaoSelecionadas;

    if (event.target.checked) {
      const item = matComunicacao.find((item) => item === event.target.value);

      if (!item) {
        matComunicacao.push(event.target.value);
      }
    } else {
      const item = matComunicacao.find((item) => item === event.target.value);
      const indexItem = matComunicacao.findIndex(
        (item) => item === event.target.value,
      );

      if (item) {
        matComunicacao.splice(indexItem);
      }
    }

    setMaterialComunicacaoSelecionadas(matComunicacao);
  };

  const onChangeCampanhas = (event) => {
    let campanhas = campanhasSelecionados;

    if (event.target.checked) {
      const item = campanhas.find((item) => item === event.target.value);

      if (!item) {
        campanhas.push(event.target.value);
      }
    } else {
      const item = campanhas.find((item) => item === event.target.value);
      const indexItem = campanhas.findIndex(
        (item) => item === event.target.value,
      );

      if (item) {
        campanhas.splice(indexItem);
      }
    }

    setCampanhasSelecionados(campanhas);
  };

  useEffect(() => {
    (async () => {
      const {
        data: { value },
      } = await api.get('/Solicitacao/list-materiais-comunicacao');
      setMaterialComunicacao(value);
    })();

    (async () => {
      const {
        data: { value },
      } = await api.get('/Solicitacao/get-campanhas');
      setCampanhas(value);
    })();
  }, []);

  const filterArtes = async () => {
    await props.filtrar(
      solicitacaoId,
      materialComunicacaoSelecionadas,
      campanhasSelecionados,
      codigo,
    );
  };

  const clearArtes = async () => {
    setMaterialComunicacaoSelecionadas([]);
    setCampanhasSelecionados([]);
    setSolicitacaoId('');
    setCodigo('');

    const items = document.getElementsByTagName('INPUT');

    for (let loop = 0; loop < items.length; loop++) {
      let item = items[loop];
      if (item.type === 'checkbox' && item.checked) {
        item.checked = false;
      }
    }

    await props.filtrar('', [], [], '');
  };

  return (
    <div className="filter-artes">
      <div className="titulo">FILTROS</div>
      <div className="body">
        <div className="input">
          <label>Solicitação</label>
          <input
            type="text"
            className="form-control"
            onChange={onChangeSolicitacaoId}
            value={solicitacaoId}
          />
        </div>

        <hr className="default"></hr>

        <div className="filters">
          <label type="button" className="btn btn-block" id="itensMaterial">
            Material Comunicação{' '}
            <FontAwesomeIcon
              className="float-right"
              style={{ marginLeft: '10px' }}
              icon={faPlus}
            ></FontAwesomeIcon>
          </label>
          <UncontrolledCollapse toggler="#itensMaterial">
            <div>
              {materialComunicacao.map((material) => {
                return (
                  <div>
                    <label style={{ fontSize: '14px' }}>
                      <input
                        onChange={onChangeMaterialComunicacao}
                        type="checkbox"
                        value={material?.materialComunicacaoId}
                      />{' '}
                      {material.nome}
                    </label>
                  </div>
                );
              })}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default"></hr>

        <div className="filters">
          <label type="button" className="btn btn-block" id="itensCampanha">
            Campanha
            <FontAwesomeIcon
              className="float-right"
              icon={faPlus}
            ></FontAwesomeIcon>
          </label>
          <UncontrolledCollapse toggler="#itensCampanha">
            <div>
              {campanhas.map((campanha) => {
                return (
                  <div>
                    <input
                      onChange={onChangeCampanhas}
                      type="checkbox"
                      value={campanha?.campanhaId}
                    />{' '}
                    {campanha.nome}
                  </div>
                );
              })}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default"></hr>

        <div className="input">
          <label>Nome ou Código <br></br>do Produto</label>
          <input
            type="text"
            className="form-control"
            onChange={onChangeCodigo}
            value={codigo}
          />
        </div>

        <hr className="default"></hr>

        <div className="buttons">
          <FormGroup className="row">
            <div className=" col-lg-12">
              <Button
                onClick={filterArtes}
                className="query-button"
                variant="primary"
              >
                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon> Filtrar
              </Button>
              <Button
                onClick={clearArtes}
                className="query-button"
                variant="outline-primary"
              >
                <FontAwesomeIcon icon={faEraser}></FontAwesomeIcon> Limpar
              </Button>
            </div>
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default FilterArtes;
