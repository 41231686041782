import React, { useEffect, useState, useContext } from 'react';
import { tabs } from '../_constants';
import DatePickerCustom from '../../../atoms/DatePicker';
import { FaAngleLeft, FaAngleRight, FaFilter, FaEraser } from 'react-icons/fa';
import Select from 'react-select';

import api from '../../../../api';
import { Context } from '../../../../Context/AuthContext';
const qs = require('qs');

const Layout = (props) => {
  let { setActiveTab, model, setModel } = props;
  const { showLoader } = useContext(Context);

  const [filtro, setFiltro] = useState({
    nome: '',
    marca: '',
    categoriaProduto: '',
    line: '',
    produto: '',
    vigenciaInicio: '',
    vigenciaFim: '',
  });

  const [marca, setMarca] = useState([]);
  const [categoriaProduto, setCategoriaProduto] = useState('');
  const [familia, setFamilia] = useState('');
  const [produto, setProduto] = useState('');

  const [lista, setLista] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState('');
  const [layout, setLayout] = useState(
    model.layoutArteFacilId ? props.model.layoutArteFacilId : '',
  );

  useEffect(() => {
    (async () => {
      showLoader(true);
      const result = await api.get('/ProdutoCategoria/get-marcas-select-list');
      setMarca(result.data.value[0].options);

      const {
        data: { value: ListaCompletaInicio },
      } = await api.get('/LayoutArte/arte-facil-list-paged');
      setLista(ListaCompletaInicio);
      setListaFiltrada(ListaCompletaInicio);

      showLoader(false);
    })();
  }, []);

  const getCategoriaProduto = async (marcaId) => {
    showLoader(true);
    const result = await api.get(
      '/ProdutoCategoria/get-categorias-select-list',
      {
        params: {
          ids: marcaId,
        },
      },
    );
    setCategoriaProduto(result.data.value[0].options);
    showLoader(false);
  };

  const getFamilia = async (lineId) => {
    showLoader(true);
    const result = await api.get(
      '/ProdutoCategoria/get-categorias-select-list',
      {
        params: {
          ids: lineId,
        },
      },
    );
    setFamilia(result.data.value[0].options);
    showLoader(false);
  };

  const onClickFiltrarLayout = async () => {
    const result = await api.get('/LayoutArte/arte-facil-list-paged', {
      params: {
        Descricao: filtro.nome,
        LineIds: filtro.line?.value,
        CategoriaProdutoIds: filtro.categoriaProduto?.value,
        FamilyIds: filtro.marca?.value,
        ProdutoIds: filtro.produto?.value,
        DataInicio: filtro.vigenciaInicio,
        DataFim: filtro.vigenciaFim,
      },
    });
    setListaFiltrada(result.data.value);
  };

  const getProduto = async (categoriaProdutoId) => {
    const result = await api.get('/Produto/select-list', {
      params: {
        ids: categoriaProdutoId,
      },
    });
    setProduto(result.data.value[0].options);
  };

  const clearFilter = async () => {
    setFiltro({
      nome: '',
      marca: '',
      line: '',
      categoriaProduto: '',
      produto: '',
      familia: '',
      vigenciaInicio: '',
      vigenciaFim: '',
    });
    setCategoriaProduto('');
    setProduto('');
    setFamilia('');
    setListaFiltrada(lista);
  };

  return (
    <div className="container pt-5">
      {/* Nome -------------------------------------------------------------------------------- */}
      <div className="row mb-3 d-flex align-items-center ">
        <div className="col-lg-2 text-left">Nome</div>
        <div className="col-lg-10">
          <input
            type="text"
            className="form-control"
            value={filtro.nome}
            onChange={(event) => {
              setFiltro({
                ...filtro,
                nome: event.target.value,
              });
            }}
          />
        </div>
      </div>

      {/* Categoria --------------------------------------------------------------------------- */}
      <div className="row mb-3 d-flex align-items-center">
        <div className="col-lg-2 text-left">Categoria</div>
        <div className="col-lg-5">
          <Select
            name="colors"
            options={marca}
            classNamePrefix="select"
            placeholder="Selecione..."
            onChange={(item) => {
              getCategoriaProduto(item.value);
              setFiltro({
                ...filtro,
                marca: item,
                categoriaProduto: '',
                line: '',
                produto: '',
              });
              setProduto('');
            }}
            value={filtro.marca}
          />
        </div>
      </div>

      {/* Line -------------------------------------------------------------------------------- */}
      <div className="row mb-3 d-flex align-items-center">
        <div className="col-lg-2 text-left">Line</div>
        <div className="col-lg-5">
          <Select
            name="colors"
            options={categoriaProduto}
            classNamePrefix="select"
            placeholder="Selecione..."
            isDisabled={!categoriaProduto}
            onChange={(item) => {
              getFamilia(item.value);
              setFiltro({
                ...filtro,
                line: item,
                produto: '',
              });
            }}
            value={filtro.line}
          />
        </div>
      </div>

      {/* Family ------------------------------------------------------------------------------ */}
      <div className="row mb-3 d-flex align-items-center">
        <div className="col-lg-2 text-left">Family</div>
        <div className="col-lg-5">
          <Select
            name="colors"
            options={familia}
            classNamePrefix="select"
            placeholder="Selecione..."
            isDisabled={!familia}
            onChange={(item) => {
              getProduto(item.value);
              setFiltro({
                ...filtro,
                familia: item,
                produto: '',
              });
            }}
            value={filtro.familia}
          />
        </div>
      </div>

      {/* Produto ----------------------------------------------------------------------------- */}
      <div className="row mb-3 d-flex align-items-center">
        <div className="col-lg-2 text-left">Produto</div>
        <div className="col-lg-5">
          <Select
            name="colors"
            options={produto}
            isDisabled={!produto}
            classNamePrefix="select"
            placeholder="Selecione..."
            onChange={(item) =>
              setFiltro({
                ...filtro,
                produto: item,
              })
            }
            value={filtro.produto}
          />
        </div>
      </div>

      {/* Vigencia ---------------------------------------------------------------------------- */}
      <div className="row mb-3 d-flex align-items-center ">
        <div className="col-lg-2 text-left">Vigência</div>
        <div className="col-lg-5">
          <DatePickerCustom
            selected={filtro.vigenciaInicio}
            onChange={(item) => {
              if (filtro.vigenciaFim) {
                if (item < filtro.vigenciaFim) {
                  setFiltro({
                    ...filtro,
                    vigenciaInicio: item,
                  });
                } else {
                  setFiltro({
                    ...filtro,
                    vigenciaInicio: item,
                    vigenciaFim: '',
                  });
                }
              } else {
                setFiltro({
                  ...filtro,
                  vigenciaInicio: item,
                });
              }
            }}
          />
        </div>
        <div className="col-lg-5">
          <DatePickerCustom
            selected={filtro.vigenciaFim}
            onChange={(item) => {
              if (item > filtro.vigenciaInicio) {
                if (filtro.vigenciaInicio) {
                  setFiltro({
                    ...filtro,
                    vigenciaFim: item,
                  });
                } else {
                  setFiltro({
                    ...filtro,
                    vigenciaFim: item,
                    vigenciaInicio: item,
                  });
                }
              } else {
                setFiltro({
                  ...filtro,
                  vigenciaFim: filtro.vigenciaInicio,
                });
              }
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 offset-lg-2 d-flex justify-content-start">
          {/* Botao Filtrar ------------------------------------------------------------------- */}
          <button
            className="btn btn-primary btn-navigation mr-3"
            onClick={() => {
              onClickFiltrarLayout();
            }}
          >
            <span>
              <FaFilter size={18} />
            </span>
            <span>FILTRAR</span>
          </button>
          {/* Botao Limpar -------------------------------------------------------------------- */}
          <button
            className="btn bg-white text-primary border border-primary btn-navigation"
            onClick={() => {
              clearFilter();
            }}
          >
            <span>
              <FaEraser size={22} />
            </span>
            <span>LIMPAR</span>
          </button>
        </div>
      </div>
      <hr />

      {/* Selecao de layout com imagem -------------------------------------------------------- */}
      <div className="row mt-5">
        {listaFiltrada &&
          listaFiltrada.data.map((item) => {
            return (
              <div
                key={item.layoutArteId}
                className={`card-material ${
                  item.layoutArteId === layout ? 'selecionado' : ''
                }`}
                onClick={() => {
                  setLayout(item.layoutArteId);
                }}
              >
                <div className="lista-campanha-materiais">
                  <div className={`header-card`}>{item.descricao}</div>
                  <div className="body-card">
                    <label>
                      <img src={item.urlImagemModelo} className="img-fluid" />
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        {/* <div className="col-md-3">
              <ItemList
                value={item.layoutArteId}
                imagem={item.urlImagemModelo}
                nome={item.descricao}
              />
            </div> */}
      </div>

      {/* Botao Voltar ------------------------------------------------------------------------ */}
      <div className="row mt-5">
        <div className="col-sm-6 text-left">
          <button
            className="btn btn-primary btn-navigation"
            onClick={() => {
              // updateRascunho();
              setActiveTab(tabs.tabCliente);
            }}
          >
            <span>
              <FaAngleLeft size={26} />
            </span>
            <span>VOLTAR</span>
          </button>
        </div>

        {/* Botao Avancar --------------------------------------------------------------------- */}
        <div className="col-sm-6 d-flex flex-row-reverse">
          <button
            className={`btn btn-primary btn-navigation btn-navigation-right ${
              layout ? '' : 'btn-disabled'
            }`}
            onClick={() => {
              // updateRascunho();
              if (layout) {
                let layoutArte = lista.data.filter((value) => {
                  return value.layoutArteId === layout;
                });
                setModel({
                  layoutArteFacil: layoutArte[0],
                  layoutArteFacilId: layout,
                });
                setActiveTab(tabs.tabTamanho);
              }
            }}
          >
            <span>AVANÇAR</span>
            <span>
              <FaAngleRight size={26} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Layout;
