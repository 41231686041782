import React from 'react';

import './styles.scss';
import loaderGif from '../../../assets/brf/loader/loading_logo_color.gif';

const Loader = () => {
  return (
      <div className="loader-gif">
          <div><img src={ loaderGif } alt="loading..."/></div>
      </div>
    
  );
}

export default Loader;
