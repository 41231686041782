import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import api from '../../api';
import history from '../../Routes/history';


const motivo = () => {
  async function getMotivoSelectList() {
    const result = await api.get('/Chamado/get-tipos-select-list');
    return result.data.value || [];
  }

  return {
    getMotivoSelectList
  }
}

export default (motivo);