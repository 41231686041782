import React from 'react';

import Header from '../../organisms/Header/HeaderApp';
import ContentFollowCalled from '../../organisms/ContentFollowCalled';
import Bottom from '../../organisms/Bottom';

const FollowCalled = () => {
  return (
    <>
      <Header/>
      <ContentFollowCalled />
      <Bottom/>
    </>
  );
}

export default FollowCalled;