import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import './styles.scss';

import { tabs, solicitacaoTabs, passos, httpStatusCode } from './_constants';

import api from '../../../api';

import { Context } from '../../../Context/AuthContext';

import Cliente from './Cliente';
import Materiais from './PedidoMateriais/Materiais';
import Entrega from './Entrega';
import Solicitacoes from './Solicitacoes';
import Resumo from './Resumo';
import Sucesso from './Sucesso';

import { default as LayoutCampanha } from './PedidoCampanha/Campanha';
import { default as MateriaisCampanha } from './PedidoCampanha/Materiais';

import Entities from './entities';

const ContentPedido = (props) => {
  const params = useParams();
  const history = useHistory();
  const { showLoader } = useContext(Context);

  let baseUrl = props.baseUrl;

  const [initialLoader, setInitialLoader] = useState(false);

  const [loader, setLoader] = useState(false);

  const [entities, setEntities] = useState({});

  const [model, setModel] = useState({
    pedidoDeCampanha: props.pedidoDeCampanha,
    pedidoTipoId: props.pedidoTipo.pedidoTipoId,
  });

  const [pedidoCriado, setPedidoCriado] = useState({});

  const [activeTab, setActiveTab] = useState(params.tabPedido || tabs.tabCliente);

  const [rascunho, setRascunho] = useState({});

  const updateModel = () => {
    setModel({
      ...model,
      pedidoTipoId: props.pedidoTipo.pedidoTipoId
     });
  };

  const updateRascunho = () => {
    (async () => {
      await api.post('/Rascunho/web-update-rascunho', model);
    })();
  };

  const validatePedido = () => {
    (async () => {
      setLoader(true);
      const result = await api.post('/Pedido/web-validate', model);
      if (result?.data?.value?.length) {
        var errors = result.data.value;

        setErrors(errors);

        redirectToError();
      }
      setLoader(false);
    })();
  };

  const finalizar = () => {
    (async () => {
      setLoader(true);
      let pedidoModel = {
        pedido: model,
        rascunho: { rascunhoId: model.rascunhoId },
      };

      const response = await api.post(`/Pedido/web-add-pedido/${model.rascunhoId}`, pedidoModel);
      if (response?.data?.code) {
        let code = response.data.code;

        //pedido criado
        if (code === httpStatusCode.ok) {
          setPedidoCriado(response.data.value.pedido);
          changeTab(tabs.tabSucesso);
        }
        //pedido com erros
        else if (code === httpStatusCode.badRequest) {
          let result = response.data.value;
          if (result.errors && result.errors.length) {
            var errors = result.errors;
            setErrors(errors);
            redirectToError();
          }
        }
        //erro interno
        else {
        }
      }
      setLoader(false);
    })();
  };

  const setErrors = (errors) => {
    //erros globais (cliente, entrega)
    model.errors =
      errors.filter((error) => error.materialComunicacaoId === 0) || [];

    //erros de solicitacoes
    for (let i = 0; i < (model.solicitacoes || []).length; i++) {
      let solicitacao = model.solicitacoes[i];
      solicitacao.errors = errors.filter(
        (error) =>
          error.materialComunicacaoId === solicitacao.materialComunicacaoId &&
          error.passo !== passos.passoEntrega.nro,
      );
    }
    // let solicitacoes = model.solicitacoes.map(solicitacao => {
    //   const errors = errors.filter((error) => error.materialComunicacaoId === solicitacao.materialComunicacaoId && error.passo !== passos.passoEntrega.nro)
    //   return({
    //     ...solicitacao,
    //     errors: errors,
    //   })
    // })
    // setModel({
    //   ...model,
    //   solicitacoes: solicitacoes
    // })

    updateModel();
  };

  const redirectToError = () => {
    if (
      model.errors.findIndex(
        (error) => error.passo === passos.passoCliente.nro,
      ) !== -1
    ) {
      history.push(`${baseUrl}/${model.rascunhoId}/${tabs.tabCliente}`);
      return;
    }

    if (
      model.errors.findIndex(
        (error) => error.passo === passos.passoMateriais.nro,
      ) !== -1
    ) {
      history.push(`${baseUrl}/${model.rascunhoId}/${tabs.tabMateriais}`);
      return;
    }

    if (
      model.errors.findIndex(
        (error) => error.passo === passos.passoEntrega.nro,
      ) !== -1
    ) {
      history.push(`${baseUrl}/${model.rascunhoId}/${tabs.tabEntrega}`);
      return;
    }

    for (var i = 0; i < (model.solicitacoes || []).length; i++) {
      let solicitacao = model.solicitacoes[i];

      if ((solicitacao.errors || []).length) {
        if (
          solicitacao.errors.findIndex(
            (error) => error.passo === passos.passoLayout.nro,
          ) !== -1
        ) {
          history.push(
            `${baseUrl}/${model.rascunhoId}/${tabs.tabSolicitacoes}/${i}/${solicitacaoTabs.tabLayout}`,
          );
          return;
        }

        if (
          solicitacao.errors.findIndex(
            (error) => error.passo === passos.passoProdutos.nro,
          ) !== -1
        ) {
          history.push(
            `${baseUrl}/${model.rascunhoId}/${tabs.tabSolicitacoes}/${i}/${solicitacaoTabs.tabProdutos}`,
          );
          return;
        }

        if (
          solicitacao.errors.findIndex(
            (error) => error.passo === passos.passoDados.nro,
          ) !== -1
        ) {
          history.push(
            `${baseUrl}/${model.rascunhoId}/${tabs.tabSolicitacoes}/${i}/${solicitacaoTabs.tabDados}`,
          );
          return;
        }
      }
    }
  };

  const renderErrors = (errors) => {
    if (!errors || !errors.length) return null;

    return (
      <div className="alert alert-danger">
        {errors.map((error, index) => {
          return (
            <p key={index} className="m-0">
              {error.error}
            </p>
          );
        })}
      </div>
    );
  };

  const changeTab = (tab) => {
    updateModel();
    history.push(`${baseUrl}/${model.rascunhoId}/${tab}`);
  };

  // Redireciona para a tab de acordo com a mudança na url
  useEffect(() => {
    return history.listen((location) => {
      let tab = location.pathname.split('/')[3];
      setActiveTab(tab);
    });
  }, [history]);

  // loader
  useEffect(() => {
    if (initialLoader || loader)
      showLoader(true);
    else
      showLoader(false);
  }, [initialLoader, loader]);

  const criarRascunho = async () => {
    const {
      data: { value: rascunho },
    } = await api.post('/Rascunho/add-rascunho');
    if (rascunho) {
      setModel({
        ...model,
        rascunhoId: rascunho.rascunhoId,
      });
      history.replace(`${baseUrl}/${rascunho.rascunhoId}/${tabs.tabCliente}`);
      return rascunho;
    }
  };

  const dadosEdicaoRascunho = async () => {
    const {
      data: { value: dadosEdicao },
    } = await api.get('/Rascunho/get-pedido-from-rascunho', {
      params: { rascunhoId: params.pedidoId },
    });

    if (dadosEdicao) {
      setModel({
        ...dadosEdicao,
        rascunhoId: params.pedidoId,
      });
      return dadosEdicao;
    }
  };

  // useEffect "DidMount", primeiro carregamento da pagina
  useEffect(() => {
    (async () => {
      setInitialLoader(true);
      /*
        verifica o paramentro pedidoId da url
        - se nao existir é um novo pedido e tem que ser criado o rascunho, assim que criado
          redireciona para a url contendo o parametro pedidoId
        - se existir, busca o rascunho e seta o model de acordo com ele
      */
      if (!params.pedidoId) {
        // Criar o rascunho caso não tenha o id na url
        await criarRascunho();
      } else {
        // Adicionar os dados do pedidoId no state model
        await dadosEdicaoRascunho();
      }

      setInitialLoader(false);
    })();
  }, []);

  const renderTabs = () => {
    if (activeTab === tabs.tabCliente) {
      return (
        <Cliente
          baseUrl={baseUrl}
          setActiveTab={changeTab}
          updateRascunho={updateRascunho}
          model={model}
          setModel={setModel}
          setRascunho={setRascunho}
          entities={entities}
          renderErrors={renderErrors}
          setLoader={setLoader}
        />
      );
    }

    if (activeTab == tabs.tabLayoutCampanha) {
      if (model.pedidoDeCampanha) {
        return (
          <LayoutCampanha
            baseUrl={baseUrl}
            setActiveTab={changeTab}
            updateRascunho={updateRascunho}
            model={model}
            setModel={setModel}
            setRascunho={setRascunho}
            entities={entities}
            renderErrors={renderErrors}
          />
        );
      } else return null;
    }

    if (activeTab === tabs.tabMateriais) {
      if (model.pedidoDeCampanha) {
        return (
          <MateriaisCampanha
            baseUrl={baseUrl}
            setActiveTab={changeTab}
            updateRascunho={updateRascunho}
            model={model}
            setModel={updateModel}
            setRascunho={setRascunho}
            entities={entities}
            renderErrors={renderErrors}
          />
        );
      } else {
        return (
          <Materiais
            baseUrl={baseUrl}
            setActiveTab={changeTab}
            updateRascunho={updateRascunho}
            model={model}
            setModel={updateModel}
            setRascunho={setRascunho}
            entities={entities}
            renderErrors={renderErrors}
          />
        );
      }
    }

    if (activeTab === tabs.tabEntrega) {
      return (
        <Entrega
          baseUrl={baseUrl}
          setActiveTab={changeTab}
          updateRascunho={updateRascunho}
          model={model}
          setModel={updateModel}
          setRascunho={setRascunho}
          entities={entities}
          renderErrors={renderErrors}
        />
      );
    }

    if (activeTab === tabs.tabSolicitacoes) {
      return (
        <Solicitacoes
          baseUrl={baseUrl}
          setActiveTab={changeTab}
          updateRascunho={updateRascunho}
          model={model}
          setModel={setModel}
          setRascunho={setRascunho}
          entities={entities}
          renderErrors={renderErrors}
        />
      );
    }

    if (activeTab === tabs.tabResumo) {
      return (
        <Resumo
          baseUrl={baseUrl}
          setActiveTab={changeTab}
          updateRascunho={updateRascunho}
          model={model}
          setModel={setModel}
          setRascunho={setRascunho}
          entities={entities}
          renderErrors={renderErrors}
          finalizar={finalizar}
          pedidoCriado={pedidoCriado}
        />
      );
    }

    if (activeTab === tabs.tabSucesso) {
      return (
        <Sucesso
          baseUrl={baseUrl}
          model={model}
          entities={entities}
          pedidoCriado={pedidoCriado}
        />
      );
    }

    return null;
  };

  return (
    <>
      <Entities setEntities={setEntities} pedido={model} loader={loader} setLoader={setLoader} />

      <div className="wrapper-content-pedido mb-5">
        <button
          className="d-none"
          onClick={() => {
            console.log(model);
          }}
        >
          console.log
        </button>
        <button
          className="d-none"
          onClick={validatePedido}>
          validate
        </button>
        {renderTabs()}
      </div>
    </>
  );
};

export default ContentPedido;
