import React from 'react';
import './styles.scss';
import PageTitle from '../../atoms/PageTitle';
import HeaderLogin from '../../organisms/Header/HeaderLogoOnly';
import Bottom from '../../organisms/Bottom';

const PageWithoutHeader = (props) => {
  return (
    <div className="page-without-header">
      <HeaderLogin />
      <PageTitle title={props.title}></PageTitle>
      <hr></hr>
      <div className="wrapper-page">{props.children}</div>
      <Bottom />
    </div>
  );
};

export default PageWithoutHeader;
