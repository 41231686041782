import React from 'react';

import PageWithoutHeader from '../../../molecules/PageWithoutHeader';
import ContentRecoveryEmailSent from '../../../organisms/ContentRecoveryEmailSent';

const RecoverPassword = () => {
  return (
    <PageWithoutHeader>
      <ContentRecoveryEmailSent></ContentRecoveryEmailSent>
    </PageWithoutHeader>
  );
}

export default RecoverPassword;