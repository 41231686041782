import React from 'react';

import HeaderApp from '../../organisms/Header/HeaderApp';
import ContentQuery from '../../organisms/ContentQuery';
import Bottom from '../../organisms/Bottom';
import PageTitle from '../../atoms/PageTitle';

const App = () => {
  return (
    <>
      <HeaderApp />
      <PageTitle title="Consulta"/>
      <hr></hr>
      <ContentQuery />
      <Bottom />
    </>
  );
}

export default App;