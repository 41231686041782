import React from 'react';

import HeaderApp from '../../organisms/Header/HeaderApp';
import ContentHistoricoPedidos from '../../organisms/ContentHistoricoPedidos';
import Bottom from '../../organisms/Bottom';
import PageTitle from '../../atoms/PageTitle';

const App = () => {
  return (
    <>
      <HeaderApp />
      <PageTitle title="Histórico de pedidos"/>
      <hr></hr>
      <ContentHistoricoPedidos />
      <Bottom />
    </>
  );
}

export default App;