import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './themes/brf/styles.scss';
import App from './App';

import storeConfig from './store/storeConfig';
const store = storeConfig();

ReactDOM.render(
  <Provider store={ store }>
    <App />
  </Provider>
  ,
  document.getElementById('root')
);

//https://xd.adobe.com/view/07456257-ec5d-411e-a0c5-9d0603870bd2-5216/
