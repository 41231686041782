import React, { useEffect, useState } from 'react';
import { FaSearch, FaAngleRight, FaTrash } from 'react-icons/fa'
import { Modal, Button } from 'react-bootstrap';

//import './styles.scss';
import api from "../../../../../../api";

import { getCampoObrigatorioClassName } from "../../../_constants";

import InputTelefone from '../../../../../atoms/InputTelefone';

const LojaLoja = (props) => {
  let model = props.model;

  const [entregaLojasParticipantes, setEntregaLojasParticipantes] = useState(model.entregaLojasParticipantes || []);
  const [resultadoBusca, setResultadoBusca] = useState([]);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);

  const updateEntregaLojasParticipantes = () => {
    model.entregaLojasParticipantes = [...model.entregaLojasParticipantes];
    setEntregaLojasParticipantes(model.entregaLojasParticipantes);
  }

  useEffect(() => {
    model.entregaLojasParticipantes = model.entregaLojasParticipantes || [];
    if (!model.entregaLojasParticipantes.length) {
      model.entregaLojasParticipantes.push({ ...model.loja })
      setEntregaLojasParticipantes(model.entregaLojasParticipantes);
    }
  }, [model])

  const hideModal = () => {
    setShowModal(false);
  }

  const buscarLojas = async () => {
    const { data: { value } } = await api.get('/Solicitacao/pesquisar-lojas', {
      params: { codigoCnpjRazaoSocial: search },
    });

    var lojas = value || [];
    lojas.forEach((loja) => {
      loja.selected = entregaLojasParticipantes.findIndex((lp) => loja.lojaId === lp.lojaId) !== -1;
    });

    setResultadoBusca(lojas);
    setShowModal(true);
  };

  const salvar = () => {
    let lojasSelecionadas = resultadoBusca.filter((loja) => loja.selected);
    lojasSelecionadas = lojasSelecionadas.filter((loja) => entregaLojasParticipantes.findIndex((lp) => lp.lojaId === loja.lojaId) === -1);

    model.entregaLojasParticipantes = [...entregaLojasParticipantes, ...lojasSelecionadas]
    setEntregaLojasParticipantes(model.entregaLojasParticipantes);

    hideModal();
  }

  const renderLojasParticipantes = () => {
    if (!entregaLojasParticipantes.length)
      return (
        <div>
          <h4>Nenhum cliente selecionado</h4>
        </div>
      )

    return (
      <div className="row dados p-0">
        {
          entregaLojasParticipantes.map((loja, index) => {
            return (
              <div key={index} className="col-lg-4 loja">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    {'Cód. ' + loja?.codigo}

                    <FaTrash className='float-right' style={{ margin: '7px 7px 10px 10px', cursor: 'pointer' }} size={22} onClick={() => {
                      model.entregaLojasParticipantes.splice(index, 1);
                      updateEntregaLojasParticipantes();
                    }} />
                  </div>
                  <div className="panel-body d-flex align-items-start flex-column w-100">
                    <table className="mb-auto w-100">
                      <tbody>
                        <tr>
                          <th>Razão Social</th>
                          <td>{loja.razaoSocial}</td>
                        </tr>
                        <tr>
                          <th>CNPJ</th>
                          <td>{loja.cnpj}</td>
                        </tr>
                        <tr>
                          <th>IE</th>
                          <td>{loja.ie}</td>
                        </tr>
                        <tr>
                          <th>CEP</th>
                          <td>{loja.cep}</td>
                        </tr>
                        <tr>
                          <th>Endereço</th>
                          <td>{loja.logradouro}</td>
                        </tr>
                        <tr>
                          <th>Número</th>
                          <td>{loja.numero}</td>
                        </tr>
                        <tr>
                          <th>Bairro</th>
                          <td>{loja.bairro}</td>
                        </tr>
                        <tr>
                          <th>Cidade</th>
                          <td>{loja.cidade.nome} - {loja.cidade.uf}</td>
                        </tr>
                      </tbody>
                    </table>

                    <hr />

                    <div className="w-100">
                      <h5>Dados do Responsável</h5>

                      <div>
                        <label>Nome</label>
                        <input type="text"
                          className={`form-control ${getCampoObrigatorioClassName(loja.responsavelNome, true)}`}
                          value={loja.responsavelNome || ""}
                          onChange={(event) => {
                            loja.responsavelNome = event.target.value;
                            updateEntregaLojasParticipantes();
                          }}
                        />
                      </div>

                      <div>
                        <label>Telefone</label>
                        <InputTelefone
                          className={`form-control ${getCampoObrigatorioClassName(loja.responsavelTelefone, true)}`}
                          value={loja.responsavelTelefone || ""} onChange={(value) => {
                            loja.responsavelTelefone = value;
                            updateEntregaLojasParticipantes();
                          }} />
                      </div>

                      <div>
                        <label>E-mail</label>
                        <input type="text"
                          className={`form-control ${getCampoObrigatorioClassName(loja.responsavelEmail, true)}`}
                          value={loja.responsavelEmail || ""}
                          onChange={(event) => {
                            loja.responsavelEmail = event.target.value;
                            updateEntregaLojasParticipantes();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  };

  return (
    <div className="entrega-fisica-panel">
      <div className="row">
        <div className="col-lg-12">
          <label>Insira o Código ou CNPJ do Cliente</label>
          <div className="input-with-icon">
            <input type="text" onChange={(event) => setSearch(event.target.value)} />
            <div className="icon" onClick={buscarLojas}>
              <FaSearch size={22} />
            </div>
          </div>
        </div>
      </div>

      <hr />

      {renderLojasParticipantes()}

      <Modal show={showModal} onHide={hideModal} className="modal-busca-loja">
        <Modal.Header closeButton className="header">
          <Modal.Title>Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Codigo</th>
                <th>CNPJ</th>
                <th>Razão Social</th>
                <th>Endereço</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                resultadoBusca.map((loja, index) => {
                  return (
                    <tr key={index}
                    // className={clientSelected === index ? "tr-selecao-loja tr-selecionado" : "tr-selecao-loja"} 
                    // onClick={selectClient(index)}
                    // style={{
                    //   backgroundColor: clientSelected === index
                    //     ? 'rgba(40, 109, 184,.5)'
                    //     : 'inherit'
                    // }}
                    >
                      <td>{loja.codigo}</td>
                      <td>{loja.cnpj}</td>
                      <td>{loja.razaoSocial}</td>
                      <td>{`${loja.logradouro}, ${loja.numero} - ${loja.cidade.nome}-${loja.cidade.uf}`}</td>
                      <td>
                        <button type="button" className={`btn btn-${loja.selected ? "success" : "primary"}`}
                          onClick={() => {
                            loja.selected = !loja.selected;
                            setResultadoBusca([...resultadoBusca]);
                          }}
                        >
                          {loja.selected ? "Loja Selecionada" : "Adicionar Loja"}
                        </button>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button variant="primary" onClick={salvar} className="button-close">
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default LojaLoja;
