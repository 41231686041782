import React from 'react';

import Header from '../../organisms/Header/HeaderApp';
import ContentBancoArtes from '../../organisms/ContentBancoArtes';
import Bottom from '../../organisms/Bottom';

const BancoArtes = () => {
  return (
    <>
      <Header/>
      <ContentBancoArtes style={{minHeight:'100%'}} />
      <Bottom style={{bottom:'0', position: 'absolute'}}/>
    </>
  );
}

export default BancoArtes;