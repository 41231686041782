import axios from 'axios';
import actionLoader from './store/modules/loader/actions';
import { useDispatch } from 'react-redux';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});

api.interceptors.request.use(function (config) {
  const bearerToken = localStorage.getItem('token')
    ? `Bearer ${localStorage.getItem('token')}`
    : '';

  config.headers.Authorization = bearerToken;

  return config;
});

api.interceptors.response.use(
  function (response) {
    if (response.data?.code === 500) {
      //excessão tratada no servidor
      return undefined; // podemos redirecionar para uma tela de ocorreu um erro e tentar nomanete, cmo na funciton error abaixo
    }

    return response;
  },
  function (error) {
    console.log(error);

    if (401 === error.response?.status) {
      localStorage.removeItem('midias_alpargatas');
      window.location = '/login';
    } else if (500 === error.response?.status) {
      // mostrar tela de um erro inesperado na tela
      window.location = '/error';
    } else if (403 === error.response?.status) {
      // mostrar uma tela de voce nao tem permissao pra finalizar essa opecação
      window.location = '/sem-permissao';
    } else {
      return Promise.reject(error);
    }
    const dispatch = useDispatch();
    dispatch(actionLoader.showLoader(false));
  },
);

export default api;
