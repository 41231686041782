import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from 'react-bootstrap';

import { Context } from '../../../Context/AuthContext';
import '../../molecules/CardImage/styles.scss';
import actions from '../../../store/modules/bancoImagens/actions';
import CardItem from './CardItem';

const CardImage = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.bancoImagens);
  const { showLoader } = useContext(Context);

  const [produtos, setProdutos] = useState([]);
  let itemsPagination = [];

  const selecionarProduto = (produto) => {
    let items = state.produtosSelecionados;

    const item = items.find((item) => item === produto.bancoImagemId);
    const index = items.findIndex((item) => item === produto.bancoImagemId);

    if (item) {
      items.splice(index, 1);
      const action = actions.setProdutosSelecionados(items);
      dispatch(action);
    } else {
      items.push(produto.bancoImagemId);
      const action = actions.setProdutosSelecionados(items);
      dispatch(action);
    }

    const action = actions.mostrarBotaoDownload(
      state.produtosSelecionados.length > 0 ? true : false,
    );
    dispatch(action);
  };

  useEffect(() => {
    if (props.data) {
      setProdutos(props.data);
    }
  }, [props.data]);
  // const BancoImagensState = useSelector(state => state.bancoImagens);

  const Paginacao = (totalPaginas, numeroPagina, mudarPagina) => {
    let totalItemPaginacao = [];
    // botão para pagina anterior
    totalItemPaginacao.push(
      <>
        <Pagination.First
          disabled={numeroPagina === 1}
          onClick={() => {
            mudarPagina(1);
          }}
        />
        <Pagination.Prev
          disabled={numeroPagina === 1}
          onClick={() => {
            mudarPagina(numeroPagina - 1);
          }}
        >
          Anterior
        </Pagination.Prev>
      </>,
    );

    for (let number = 1; number <= totalPaginas; number++) {
      // To do
      // arrumar essa validação
      if (
        number === numeroPagina ||
        number === numeroPagina + 1 ||
        number === numeroPagina + 2 ||
        number === numeroPagina - 1 ||
        number === numeroPagina - 2
      ) {
        totalItemPaginacao.push(
          <Pagination.Item
            key={number}
            active={number === numeroPagina}
            onClick={() => {
              mudarPagina(number);
            }}
          >
            {number}
          </Pagination.Item>,
        );
      }
    }

    // Botão prox pagina e ultima pagina
    totalItemPaginacao.push(
      <>
        <Pagination.Next
          disabled={numeroPagina === totalPaginas}
          onClick={() => {
            mudarPagina(numeroPagina + 1);
          }}
        >
          Próxima
        </Pagination.Next>
        <Pagination.Last
          disabled={numeroPagina === totalPaginas}
          onClick={() => {
            mudarPagina(totalPaginas);
          }}
        />
      </>,
    );

    return <Pagination>{totalItemPaginacao}</Pagination>;
  };

  return (
    <>
      <div className="card-deck">
        {produtos.map((produto, index) => (
          <CardItem
            key={`banco-imagens-${index}`}
            selecionado={state.produtosSelecionados.includes(
              produto.bancoImagemId,
            )}
            produto={produto}
            handler={selecionarProduto}
          />
        ))}
      </div>
      <div>
        {props.totalPages > 1 ? (
          <div className="col-md-12 mt-3 d-flex align-items-center flex-column">
            {Paginacao(props.totalPages, props.pageNumber, props.changePage)}
            <div>
              <p>
                Página {props.pageNumber} de {props.totalPages}{' '}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CardImage;
