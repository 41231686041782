import React from 'react';

import PageWithoutHeader from '../../../molecules/PageWithoutHeader';
import ContentNewPassword from '../../../organisms/ContentNewPassword';

const NewPassword = () => {
  return (
    <PageWithoutHeader title="REDEFINIR SENHA">
      <ContentNewPassword></ContentNewPassword>
    </PageWithoutHeader>
  );
}

export default NewPassword;