import React, { useEffect, useState, useContext } from 'react';
import { FaFilter, FaEraser } from 'react-icons/fa';

import './styles.scss';
import api from '../../../api';
import TitlePages from '../../atoms/TitlePages';
import SingleSelect from '../../atoms/SelectSingle';
import { Button } from 'react-bootstrap';
import { Context } from '../../../Context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { dateFormat } from '../../../helpers/Date.Helper';
import history from '../../../Routes/history';

const ContentFollowCalled = (props) => {
  const userAuth = JSON.parse(localStorage.getItem('midias_alpargatas'));
  const [chamados, setChamados] = useState([]);
  const [chamadosFiltrado, setChamadosFiltrado] = useState('');
  const [filtros, setFiltros] = useState({
    numeroAtendimento: '',
    status: ''
  })
  const { showLoader } = useContext(Context);

  useEffect(() => {
    (async () => {
      showLoader(true);
      const {
        data: { value },
      } = await api.get('/Chamado/get-by-usuarioid', {
        params: {
          usuarioId: userAuth.userId,
        },
      });
      console.log(value)

      setChamados(value);
      showLoader(false);
    })();
  }, []);

  const visualizarClick = async (id) => {
    history.push('/chat?id=' + id);
  };
  const renderizarLista = (value) => {
    return (
      <>
        {value.length > 0 && value.map((chamados) => (
          <tr key={chamados.chamadoId}>
            <td
              style={{
                color: chamados.dataFinalizacao !== null ? 'red' : null,
              }}
            >
              {chamados.chamadoId}
            </td>
            <td
              style={{
                color: chamados.dataFinalizacao !== null ? 'red' : null,
              }}
            >
              {dateFormat(chamados.dataCriacao, true)}
            </td>
            <td
              style={{
                color: chamados.dataFinalizacao !== null ? 'red' : null,
              }}
            >
              {chamados.usuario.nome}
            </td>
            <td
              style={{
                color: chamados.dataFinalizacao !== null ? 'red' : null,
              }}
            >
              {chamados.chamadoTipo.nome}
            </td>
            <td
              style={{
                color: chamados.dataFinalizacao !== null ? 'red' : null,
              }}
            >
              {chamados.dataFinalizacao !== null
                ? 'Finalizado'
                : chamados.chamadoRespostas[
                  chamados.chamadoRespostas.length - 1
                ].usuario.usuarioId === chamados.usuarioId
                  ? 'Chamado enviado'
                  : 'Chamado respondido'}
            </td>
            <td
              style={{
                color: chamados.dataFinalizacao !== null ? 'red' : null,
              }}
            >
              {chamados.chamadoRespostas != null
                ? dateFormat(
                  chamados.chamadoRespostas[
                    chamados.chamadoRespostas.length - 1
                  ].data,
                  true,
                )
                : dateFormat(chamados.dataCriacao, true)}
            </td>
            <td className="text-center">
              <Button
                style={{
                  backgroundColor:
                    chamados.dataFinalizacao !== null
                      ? 'red'
                      : chamados.chamadoRespostas[
                        chamados.chamadoRespostas.length - 1
                      ].usuario.usuarioId === chamados.usuarioId
                        ? null
                        : '#28a745 ',
                  borderColor:
                    chamados.dataFinalizacao !== null
                      ? 'red'
                      : chamados.chamadoRespostas[
                        chamados.chamadoRespostas.length - 1
                      ].usuario.usuarioId === chamados.usuarioId
                        ? null
                        : '#28a745 ',
                }}
                className="table-button"
                onClick={() => visualizarClick(chamados.chamadoId)}
              >
                Visualizar
              </Button>
            </td>
          </tr>
        ))}
      </>
    )
  }

  return (
    <div className="wrapper-acompanhar-chamados">
      <TitlePages title="ACOMPANHAR CHAMADOS" />
      <div className="table-query ">

        <div className="row">
          <div className="offset-2 col-8 pesquisa">

            {/* Filtro Codigo Atendimento */}
            <div className="row mb-3">
              <label className="col-lg-3 col-form-label  " htmlFor="">
                Número do atendimento
              </label>
              <div className="col-lg-9 ">
                <input
                  type="text"
                  className="form-control "
                  value={filtros.numeroAtendimento}
                  onChange={(e) => setFiltros({
                    ...filtros,
                    numeroAtendimento: e.target.value.replace(/\D/, '')
                  })}
                />
              </div>
            </div>

            {/* Filtro Status ----------------------------------------------------------------- */}
            <div className="row mb-3">
              <label className="col-lg-3 col-form-label numero" htmlFor="">
                Status
              </label>
              <div className="col-lg-9 ">
                <SingleSelect
                  isClearable={true}
                  onChange={(value) => setFiltros({
                    ...filtros,
                    status: value
                  })}
                  options={[
                    { label: 'Chamado respondido', value: 'Chamado respondido' },
                    { label: 'Chamado enviado', value: 'Chamado enviado' },
                    { label: 'Finalizado', value: 'Finalizado' },
                  ]}
                  value={filtros.status}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center mb-3">

              {/* Botao Filtrar --------------------------------------------------------------- */}
              <button
                className="btn btn-primary btn-navigation mr-3"
                onClick={() => {
                  const listaFiltradaAtual = chamados.filter(chamado => {
                    const statusAtual = chamado.dataFinalizacao
                      ? 'Finalizado'
                      : chamado.chamadoRespostas[
                        chamado.chamadoRespostas.length - 1
                      ].usuario.usuarioId === chamado.usuarioId
                        ? 'Chamado enviado'
                        : 'Chamado respondido'
                    if (filtros.numeroAtendimento && filtros.status) {
                      return parseInt(filtros.numeroAtendimento) === chamado.chamadoId && filtros.status === statusAtual
                    } else if (!filtros.numeroAtendimento && !filtros.status) {
                      return chamado;
                    } else {
                      return parseInt(filtros.numeroAtendimento) === chamado.chamadoId || filtros.status === statusAtual
                    }
                  })
                  setChamadosFiltrado(listaFiltradaAtual)
                }}
                type='submit'
              >
                <span>
                  <FaFilter size={18} />
                </span>
                <span>&nbsp;FILTRAR</span>
              </button>

              {/* Botao Limpar ---------------------------------------------------------------- */}
              <button
                className="btn bg-white text-primary border border-primary btn-navigation"
                onClick={() => {
                  setFiltros({
                    numeroAtendimento: '',
                    status: '',
                  })
                  setChamadosFiltrado('')
                }}
                type='reset'
              >
                <span>
                  <FaEraser size={22} />
                </span>
                <span>&nbsp;LIMPAR</span>
              </button>
            </div>
          </div>
        </div>


        <div className="table-responsive">
          <table className="table table-striped ">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Criado em</th>
                <th>Criador</th>
                <th>Título</th>
                <th>Status</th>
                <th>Modificado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {chamadosFiltrado ? renderizarLista(chamadosFiltrado) : renderizarLista(chamados)}
            </tbody>
          </table>
        </div>
        <div className="voltar ">
          <Button className="btnVoltar" href="/atendimento">
            <FontAwesomeIcon
              className="icon"
              icon={faAngleLeft}
            />
            &nbsp;VOLTAR
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContentFollowCalled;
