import React from 'react';

import HeaderApp from '../../organisms/Header/HeaderApp';
import ContentHome from '../../organisms/ContentHome';
import Bottom from '../../organisms/Bottom';

const App = () => {
  return (
    <>
      <HeaderApp />
      <ContentHome />
      <Bottom />
    </>
  );
}

export default App;
