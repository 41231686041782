
import userTypesActions from './user/type-actions';
import userState from './user/initial-state';

import RequestMaterialsState from './request-materials/initial-state';
import RequestMaterialsTypesActions from './request-materials/type-actions';

import LoaderState from './loader/initial-state';
import LoaderTypesActions from './loader/type-actions';

import BancoImagensState from './bancoImagens/initial-state';
import BancoImagensTypeActions from './bancoImagens/type-actions';


const reducers = {
  user: function(state = userState, action) {
    switch(action.type) {
      case userTypesActions.SET_USER_NAME:
        return { ...state, name: action.payload }
      default:
        return state;
    }
  },
  requestMaterials: function(state = RequestMaterialsState, action) {
    switch(action.type) {
      case RequestMaterialsTypesActions.SET_CUSTOMER: 
        return { ...state, customer: action.payload }
      default:
        return state;
    }
  },
  loader: function(state = LoaderState, action) {
    switch(action.type) {
      case LoaderTypesActions.SHOW_LOADER: 
        return { ...state, loading: action.payload }
      default:
        return state;
    }
  },
  bancoImagens: function(state = BancoImagensState, action) {
    switch(action.type) {
      case BancoImagensTypeActions.SET_CATEGORIAS_IMAGENS:
        return { ...state, categoriasImagens: action.payload }

      case BancoImagensTypeActions.SET_MARCAS:
        return { ...state, marcas: action.payload }

      case BancoImagensTypeActions.SET_CATEGORIAS_PRODUTOS:
          return { ...state, categoriasProdutos: action.payload }

      case BancoImagensTypeActions.SET_MOSTRAR_BOTAO_DOWNLOAD:
        return { ...state, mostrarBotaoDownload: action.payload }
      
      case BancoImagensTypeActions.SET_PRODUTOS_SELECIONADOS:
        return { ...state, produtosSelecionados: action.payload }
      
      case BancoImagensTypeActions.SET_MARCAS_SELCIONADAS:
        return { ...state, marcasSelecionadas: action.payload }

      case BancoImagensTypeActions.SET_CATEGORIAS_IMAGENS_SELECIOANDAS:
        return { ...state, categoriasImagensSelecionadas: action.payload }
          
      default:
        return state;
    }
  },
};

export default reducers;